import React,{ReactNode} from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import {isGoodPassword} from "../utils/formValidation";

import ButtonWithLoading from "../ButtonWithLoading";
const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
export interface FormData  {
  passwordConfirm: string;
  password: string;
};
interface Props {
  onSubmitForm: (data: FormData) => void;
  isFetching: boolean;
  buttonText?: string;
  children: ReactNode;
  
}
function RecoveryPasswordForm(props: Props) { 
  const classes = useStyles();
  const { register,watch, handleSubmit,  errors,formState  } = useForm<FormData>({ mode: "onChange" });
  const { onSubmitForm, isFetching,  buttonText,children } = props;
  const validatePasswords = (value: string) => value === watch("password");
  return (
    
          <form className={classes.form} onSubmit={handleSubmit(onSubmitForm)} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                inputRef={register({ required: true, pattern: isGoodPassword() })}
                error={errors.password ? true : false}
                helperText={errors.password && errors.password.message}
              />
            
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="passwordConfirm"
                label="Confirmar password"
                type="password"
                id="passwordConfirm"
                autoComplete="current-password"
                inputRef={register({ required: true, validate: validatePasswords })}
                error={errors.passwordConfirm ? true : false}
                helperText={errors.passwordConfirm && "Las contraseñas deben ser iguales"}
              />
            
            
              <ButtonWithLoading
                color="primary"
                disabled={isFetching || !formState.isValid}
                loading={isFetching}
                text={buttonText || "Ingresar"}
              />
              {children}
            
          </form>
  );
}

export default RecoveryPasswordForm;
