import React,{useEffect} from "react";
import {useHistory} from "react-router-dom";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import RecoveryPasswordForm from "../../components/Forms/RecoveryPasswordForm";

import { passwordRecoverySetNewPassword } from "../../redux/actions/userRecoveryPassword.action";
import { connect } from "react-redux";
import { PasswordRecoveryUserState } from "../../redux/reducers/userRecoveryPassword.reducer";


interface PasswordRecoveryData {
    password: string;
    passwordConfirm: string;
};

interface Props {
    passwordRecoverySetNewPassword: (email: string,code: string,password: string) => void;
    userData: PasswordRecoveryUserState;
    
}

function InputNewPassword(props: Props) {
    
    const { passwordRecoverySetNewPassword, userData } = props;
    const history = useHistory();
    console.log("userData",userData);
    useEffect(
        ()=>{
            if(userData.isFetch)history.push("/login");
        },[userData.isFetch,history]);

    return (
        <RecoveryPasswordForm onSubmitForm={(data: PasswordRecoveryData) => {if(userData.user.code)passwordRecoverySetNewPassword(userData.user.email,userData.user.code,data.password);}} isFetching={userData.isFetching} buttonText="Modificar Contraseña" >
            <Grid item xs>
                <Link to={"/login"} style={{ textDecoration: "none", display: "flex-start" }}>
                Tengo usuario y contraseña
                </Link>
            </Grid>
        </RecoveryPasswordForm>
    );
}

const mapStateToProps = (state: any) => {
    return {
        userData: state.userDataPasswordRecovery
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        passwordRecoverySetNewPassword: (email: string,code: string,password: string) => {
            return dispatch(passwordRecoverySetNewPassword(email,code,password));
        }
    };
};



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InputNewPassword);
