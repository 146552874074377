import React from "react";
import { BrowserRouter} from "react-router-dom";
import RoutesContainer from "./containers/RoutesContainer/index";


function App() { 
  return (
    <BrowserRouter>
      <RoutesContainer />
    </BrowserRouter>
  );
}

export default App;
