import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Copyright from "../components/Copyrigth";
import CancelIcon from "@material-ui/icons/Cancel";

import { connect } from "react-redux";
import { updateTitle } from "../redux/actions/navigation.action";

const pageTitle = "404 Not Found";


const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",

    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  body: {
    marginTop: theme.spacing(3),
    textAlign:"center"
  },
  icon: {
      fontSize: "150px",
      color:"#e40404",
      margin: "0 auto",
      display: "flex",
      justifyContent: "flex-end",
  },
  title:{
    color: "#f65521",
    fontWeight: "bold"
  }
}));

interface Props {

    updateTitlePage: (title: string) => void;
  }

function Page404(props: Props) {
  const classes = useStyles();
  const { updateTitlePage } = props;
  React.useEffect(
    () => {
      updateTitlePage(pageTitle);
      
    });
  return (
    <React.Fragment>
      <CssBaseline />

      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography className={classes.title} component="h1" variant="h4" align="center">
            404 Not Found
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.body}> 
            La página que intenta acceder no existe
          </Typography>
          <div>
            <CancelIcon className={classes.icon}></CancelIcon>
          </div>
          
          <div className={classes.buttons}>
            
        </div>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}


  const mapDispatchToProps = (dispatch: any) => {
    return {
      updateTitlePage: (title: string) => {
  
        dispatch(updateTitle(title));
      }
    };
  };
  
  
  
  
  export default connect(
    null,
    mapDispatchToProps
  )(Page404);

