import { combineReducers } from "redux";
import userReducer, { UserState } from "./user.reducer";
import userRecoveryPasswordReducer, {
  PasswordRecoveryUserState
} from "./userRecoveryPassword.reducer";
import navigationReducer, { NavigationState } from "./navigation.reducer";
import voucherReducer, { VoucherState } from "./voucher.reducer";
import salesReducer, { SalesState } from "./sales.reducer";
import shopsReducer, { ShopsState } from "./shops.reducer";
import shopFetchReducer, { ShopFetchState } from "./shopFetch.reducer";
import categoriesReducer, { CategoriesState } from "./categories.reducer";
import giftTypeReducer, { GiftTypeState } from "./giftType.reducer";
import gitfTypeFetchReducer, {
  GiftTypeFetchState
} from "./giftTypeFetch.reducer";
import categoriesFetchData, {
  CategoryFetchState
} from "./categoryFetch.reducer";
import gitfCardFetchReducer, {
  GiftCardFetchState
} from "./giftCardFetch.reducer";

export interface State {
  userData: UserState;
  navigation: NavigationState;
  voucherData: VoucherState;
  sales: SalesState;
  userDataPasswordRecovery: PasswordRecoveryUserState;
  shopsState: ShopsState;
  shopsFetchState: ShopFetchState;
  categoriesState: CategoriesState;
  categoriesFetchState: CategoryFetchState;
  giftTypeState: GiftTypeState;
  GiftTypeFetchState: GiftTypeFetchState;
  GiftCardFetchState: GiftCardFetchState;
}
export default combineReducers({
  userData: userReducer,
  navigation: navigationReducer,
  voucherData: voucherReducer,
  sales: salesReducer,
  userDataPasswordRecovery: userRecoveryPasswordReducer,
  shopsData: shopsReducer,
  shopsFetchData: shopFetchReducer,
  categoriesData: categoriesReducer,
  categoriesFetchData: categoriesFetchData,
  giftTypeData: giftTypeReducer,
  giftTypeFetchData: gitfTypeFetchReducer,
  giftCardFetchData: gitfCardFetchReducer
});
