import React, { useEffect } from "react";
import Listado from "../../../components/Listado/index";
import { ListadoColumnas,Filter } from "../../../components/Listado/interfaces";

import { connect } from "react-redux";
import { ShopFetchState } from "../../../redux/reducers/shopFetch.reducer";
import { fetchShops,deleteShop,pausedShop } from "../../../redux/actions/shops.action";
import { RequestOptions } from "../../../redux/interfaces";
import { updateTitle } from "../../../redux/actions/navigation.action";
import { UserState } from "../../../redux/reducers/user.reducer";

const pageTitle = "Listado Comercios";

interface Props {
    shops: ShopFetchState;
    fetchShops: (options?: RequestOptions) => void;
    updateTitlePage: (title: string) => void;
    deleteShop: (id: string,cb: any) => void;
    pausedShop: (id: string,paused: boolean,cb: any) => void;
    userData: UserState;
}

function ShopsContainer(props: Props) {
    const { shops, fetchShops, updateTitlePage,deleteShop,pausedShop,userData } = props;

    const deleteProduct = (id: string)=>{
        console.log("DELETE");
        deleteShop(id,fetchShops);
    };
    const paused = (id: string,paused: boolean)=>{
        console.log("PAUSE");
        pausedShop(id,paused,fetchShops);
    };
    
    const filters: Filter[] = [
        { name: "name", label: "Nombre", type: "text" },
        { name: "inHome", label: "En Home", type: "select", options: [{ value: "true", label: "SI" },{ value: "false", label: "NO" }]},
        { name: "featured", label: "Destacado", type: "select", options: [{ value: "true", label: "SI" },{ value: "false", label: "NO" }]},
        { name: "paused", label: "Pausado", type: "select", options: [{ value: "true", label: "SI" },{ value: "false", label: "NO" }]},
    ];
    
    const columns: ListadoColumnas = {
        actions:{
            add:{ name: "add", label: "Nuevo", minWidth: 10,link:"/shop/" },
            edit:{ name: "edit", label: "Editar", minWidth: 10,link:"/shop/" },
            delete:{ name: "delete", label: "Eliminar", minWidth: 10,click: deleteProduct },
            paused:{ name: "paused", label: "Pausar", minWidth: 10,click: paused },
            
        },
        columns:[
            { name: "thumb", label: "Imagen", minWidth: 40,type:"image" },
            { name: "name", label: "Nombre", minWidth: 90 },    
            { name: "inHome", label: "En Home", minWidth: 90 },
            { name: "featured", label: "Destacado", minWidth: 90 },
            { name: "order", label: "Orden", minWidth: 90 },
            { name: "phone", label: "Teléfono", minWidth: 90 },
    
        ]
    };
    if(["admin"].indexOf(userData.user.rol)!==-1){
        columns.actions={
            ...columns.actions,
            boomerGenerate:{ name: "edit", label: "Generar boomer", minWidth: 10,link:"/boomer-generate/" },
        };
    }
        
    useEffect(() => {
        !shops.isLoaded && !shops.isFetching && fetchShops();
        updateTitlePage(pageTitle); 
    });
    
    return (
        <>
            {shops.isLoaded &&
                <Listado listadoColumnas={columns} rows={shops.shops} pagination={shops.pagination} filters={filters} fetch={fetchShops}/>
            }
        </>
        
    );
}

const mapStateToProps = (state: any) => {
    console.log();
    return {
        shops: state.shopsFetchData,
        userData:state.userData
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateTitlePage: (title: string) => {

            dispatch(updateTitle(title));
        },
        fetchShops: (options?: RequestOptions) => {
            dispatch(fetchShops(options));
        },
        deleteShop:(id: string,cb: any)=>{
            dispatch(deleteShop(id,cb));
        },
        pausedShop:(id: string,paused: boolean,cb: any)=>{
            dispatch(pausedShop(id,paused,cb));
        }
    };
};



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShopsContainer);
