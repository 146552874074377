import {

  PASSWORD_RECOVERY,
  PASSWORD_RECOVERY_SUCCESS,
  PASSWORD_RECOVERY_FAIL,
  PASSWORD_RECOVERY_INPUT_CODE,
  PASSWORD_RECOVERY_INPUT_CODE_SUCCESS,
  PASSWORD_RECOVERY_INPUT_CODE_FAIL,
  PASSWORD_RECOVERY_NEW_PASSWORD,
  PASSWORD_RECOVERY_NEW_PASSWORD_SUCCESS,
  PASSWORD_RECOVERY_NEW_PASSWORD_FAIL,
  INICIALIZE
} from "../constants";
//import { IUser } from "../interfaces";
import {passwordRecoveryInputEmail,passwordRecoveryInputCode,passwordRecoveryChangePassword} from "../../services/user.service";



/**
 * POST - Recuperar Password
 */
export const passwordRecovery = (email: string) => {
  return (dispatch: any) => {

    dispatch({ type: PASSWORD_RECOVERY });

    passwordRecoveryInputEmail(email)
      .then((user) => {
        if (user.data.error) {
          dispatch({ type: PASSWORD_RECOVERY_FAIL, data: user.data });
        } else {
          dispatch({ type: PASSWORD_RECOVERY_SUCCESS, data: user.data });
        }

      }).catch(error => {

        dispatch({ type: PASSWORD_RECOVERY_FAIL, error });
      });

  };
};
/**
 * POST - Password Recovery Valid Code
 */
export const passwordRecoveryValidCode = (email: string,code: string) => {
  return (dispatch: any) => {

    dispatch({ type: PASSWORD_RECOVERY_INPUT_CODE });
    console.log("passwordRecoveryInputCode");
    passwordRecoveryInputCode(email,code)
      .then((user) => {
        if (user.data.error) {
          dispatch({ type: PASSWORD_RECOVERY_INPUT_CODE_FAIL, data: user.data });
        } else {
          dispatch({ type: PASSWORD_RECOVERY_INPUT_CODE_SUCCESS, data: user.data });
        }

      }).catch(error => {

        dispatch({ type: PASSWORD_RECOVERY_INPUT_CODE_FAIL, error });
      });

  };
};
export const inicializeFetch = () => {
  return (dispatch: any) => {

    dispatch({ type: INICIALIZE });

    

  };
};
/**
 * POST - Password Recovery set new password
 */
export const passwordRecoverySetNewPassword = (email: string,code: string,password: string) => {
  return (dispatch: any) => {

    dispatch({ type: PASSWORD_RECOVERY_NEW_PASSWORD });

    passwordRecoveryChangePassword(email,code,password)
      .then((user) => {
        if (user.data.error) {
          dispatch({ type: PASSWORD_RECOVERY_NEW_PASSWORD_FAIL, data: user.data });
        } else {
          dispatch({ type: PASSWORD_RECOVERY_NEW_PASSWORD_SUCCESS, data: user.data });
        }

      }).catch(error => {

        dispatch({ type: PASSWORD_RECOVERY_NEW_PASSWORD_FAIL, error });
      });

  };
};