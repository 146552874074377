import {
    
    PASSWORD_RECOVERY,
    PASSWORD_RECOVERY_SUCCESS,
    PASSWORD_RECOVERY_FAIL,
    PASSWORD_RECOVERY_INPUT_CODE,
    PASSWORD_RECOVERY_INPUT_CODE_SUCCESS,
    PASSWORD_RECOVERY_INPUT_CODE_FAIL,
    PASSWORD_RECOVERY_NEW_PASSWORD,
    PASSWORD_RECOVERY_NEW_PASSWORD_SUCCESS,
    PASSWORD_RECOVERY_NEW_PASSWORD_FAIL,
    INICIALIZE
  } from "../constants";
  import { IAction, IUserPasswordRecovery } from "../interfaces";
  
  export interface PasswordRecoveryUserState {
    user: IUserPasswordRecovery;
    isFetching: boolean;
    error: boolean;
    isFetch: boolean;
    activeStep?: number;
  }
  
  const emptyUser: IUserPasswordRecovery = {
    email:""
  };
  
const initialState: PasswordRecoveryUserState = {
    user: emptyUser,
    isFetching: false,
    error: false,
    isFetch:false
  };
  
  export default function userRecoveryPasswordReducer(
    state = initialState,
    action: IAction
  ): PasswordRecoveryUserState {
    switch (action.type) {
      case INICIALIZE:
        return {
          ...state,
          isFetching: false,
          error: false,
          isFetch: false,
          activeStep:1
        };
      
      case PASSWORD_RECOVERY:
        return {
          ...state,
          isFetching: true,
          error: false,
          isFetch: false
        };
      case PASSWORD_RECOVERY_SUCCESS:
        return {
          ...state,
          user:{
            email:action.data.email
          },
          isFetching: false,
          error: false,
          isFetch: false,
          activeStep:1
        };
      case PASSWORD_RECOVERY_FAIL:
        return {
          ...state,
          user: emptyUser,
          isFetching: false,
          error: true,
          isFetch:false
        };
      case PASSWORD_RECOVERY_INPUT_CODE:
        return {
          ...state,
          isFetching: true,
          error: false,
          isFetch: false
        };
      case PASSWORD_RECOVERY_INPUT_CODE_SUCCESS:
        return {
          ...state,
          user:{
            email:action.data.email,
            code:action.data.code,
            _id:action.data._id
          },
          isFetching: false,
          error: false,
          isFetch: false,
          activeStep:2
        };
      case PASSWORD_RECOVERY_INPUT_CODE_FAIL:
        return {
          ...state,
          isFetching: false,
          error: true,
          isFetch:false
        };
      case PASSWORD_RECOVERY_NEW_PASSWORD:
        return {
          ...state,
          isFetching: true,
          error: false,
          isFetch: false
        };
      case PASSWORD_RECOVERY_NEW_PASSWORD_SUCCESS:
        return {
          ...state,
          user:emptyUser,
          isFetching: false,
          error: false,
          isFetch: true
        };
      case PASSWORD_RECOVERY_NEW_PASSWORD_FAIL:
        return {
          ...state,
          isFetching: false,
          error: true,
          isFetch:false
        };
      default:
        return state;
    }
  }
  