import React from "react";
import Button from "@material-ui/core/Button";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  circularProgress: {
    marginRight: 10
  }
}));

interface Props {
  disabled: boolean;
  color: "inherit" | "default" | "primary" | "secondary";
  loading: boolean;
  text: string;
  type?: "button" | "submit";
  onClick? : (e: any) => void;
}
function ButtonWithLoading(props: Props) {
  const classes = useStyles();
  const { disabled, color, loading, text, type, onClick } = props;

  return (
    <Button
      type={type || "submit"}
      fullWidth
      variant="contained"
      color={color}
      className={classes.submit}
      disabled={disabled}
      onClick={onClick}
    >
      {loading && (
        <CircularProgress className={classes.circularProgress} size={20} />
      )}
      {text}
    </Button>
  );
}

export default ButtonWithLoading;
