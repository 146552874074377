import {
    FETCHING_SALES,
    FETCHING_SALES_SUCCESS,
    FETCHING_SALES_FAIL,
    LOGOUT_USER
} from "../constants";
import { IAction, Sales, Pagination } from "../interfaces";

export interface SalesState {
    sales: Sales[];
    pagination?: Pagination;
    isFetching: boolean;
    isLoaded: boolean;
    error: boolean;
}

const initialState: SalesState = {
    sales: [],
    isFetching: false,
    isLoaded:false,
    error: false
};



export default function voucherReducer(
    state = initialState,
    action: IAction
): SalesState {
    switch (action.type) {
        case LOGOUT_USER:
            return initialState;
        case FETCHING_SALES:

            return {
                ...state,
                isFetching: true
            };
        case FETCHING_SALES_SUCCESS:

            return {
                ...state,
                sales: action.data.data,
                pagination: action.data.pagination,
                isFetching: false,
                isLoaded:true,
                error: false
            };
        case FETCHING_SALES_FAIL:
            return {
                ...state,
                sales: [],
                isFetching: false,
                isLoaded: true,
                error: true
            };
        default:
            return state;
    }
}
