import React, { useEffect } from "react";
import Listado from "../../../components/Listado/index";
import {
  ListadoColumnas,
  Filter
} from "../../../components/Listado/interfaces";

import { connect } from "react-redux";
import { CategoriesState } from "../../../redux/reducers/categories.reducer";
import { fetch, deleted } from "../../../redux/actions/categories.action";
import { RequestOptions } from "../../../redux/interfaces";
import { updateTitle } from "../../../redux/actions/navigation.action";

const pageTitle = "Listado Categorias";

interface Props {
  categoriesState: CategoriesState;
  fetch: (options?: RequestOptions) => void;
  updateTitlePage: (title: string) => void;
  deleted: (id: string, cb: any) => void;
}

function CategoriesContainer(props: Props) {
  const { categoriesState, fetch, updateTitlePage, deleted } = props;

  const deleteElement = (id: string) => {
    deleted(id, fetch);
  };

  const filters: Filter[] = [
    { name: "name", label: "Nombre", type: "text" },
    {
      name: "inHome",
      label: "En Home",
      type: "select",
      options: [{ value: "true", label: "SI" }, { value: "false", label: "NO" }]
    }
  ];
  const columns: ListadoColumnas = {
    actions: {
      add: { name: "add", label: "Nuevo", minWidth: 10, link: "/category/" },
      edit: {
        name: "edit",
        label: "Editar",
        minWidth: 10,
        link: "/category/"
      },
      delete: {
        name: "delete",
        label: "Eliminar",
        minWidth: 10,
        click: deleteElement
      }
    },
    columns: [
      { name: "name", label: "Nombre", minWidth: 90 },
      { name: "inHome", label: "En Home", minWidth: 90 },
      { name: "icon", label: "Icono", minWidth: 90 }
    ]
  };

  useEffect(() => {
    !categoriesState.isLoaded && !categoriesState.isFetching && fetch();
    updateTitlePage(pageTitle);
  });

  return (
    <>
      {categoriesState.isLoaded && (
        <Listado
          listadoColumnas={columns}
          rows={categoriesState.categories}
          pagination={categoriesState.pagination}
          filters={filters}
          fetch={fetch}
        />
      )}
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    categoriesState: state.categoriesFetchData
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTitlePage: (title: string) => {
      dispatch(updateTitle(title));
    },
    fetch: (options?: RequestOptions) => {
      dispatch(fetch(options));
    },
    deleted: (id: string, cb: any) => {
      dispatch(deleted(id, cb));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesContainer);
