import React from "react";
import { useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

import { connect } from "react-redux";
import { closeUserAlert } from "../redux/actions/navigation.action";
import { UserAlert } from "../redux/interfaces";

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface Props {
    closeUserAlert: () => void;
    userAlert: UserAlert;
}
function SnackbarCustom(props: Props) {
    
    const history = useHistory();
    const { closeUserAlert, userAlert } = props;

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        closeUserAlert();
        if (userAlert.redirect){
            history.push("/change");
        }
    };
    return (
        <>
            {userAlert.open &&
                <Snackbar open={userAlert.open} autoHideDuration={userAlert.duration || 1000} onClose={handleClose} >
                    <Alert severity={(userAlert.color ? userAlert.color : undefined)}>
                        {userAlert.message}
                    </Alert>
                </Snackbar>
            }
        </>
    );
}
const mapStateToProps = (state: any) => {
   return {
        userAlert: state.navigation.userAlert
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeUserAlert: () => {
            return dispatch(closeUserAlert());
        }
    };
};



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SnackbarCustom);