import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { FormShopAddress } from "../../../redux/interfaces";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import NewShopFormAddress from "./NewShopFormAddress";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,

      padding: theme.spacing(2, 4, 3)
    },
    title: {
      color: "#f65521",
      fontWeight: "bold"
    }
  })
);
interface Prop {
  handleAddAddress: (address: FormShopAddress) => void;
}
function ShopFormAddresses(props: Prop) {
  const classes = useStyles();
  const { handleAddAddress } = props;
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addAddress = (address: FormShopAddress) => {
    setOpen(false);
    handleAddAddress(address);
  }

  const body = (
    <Paper className={classes.paper} style={modalStyle}>
      <Typography
        className={classes.title}
        component="h1"
        variant="h4"
        align="center"
      >
        Nueva Dirección
      </Typography>
      <NewShopFormAddress handleAddAddress={addAddress} />
    </Paper>
  );

  return (
    <div>
      <Button
        type="button"
        onClick={handleOpen}
        fullWidth
        variant="contained"
        color="primary"
      >
        Agregar Dirección
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

export default ShopFormAddresses;
