import {
    FETCH_GIFT_TYPE_LISTADO,
    FETCH_GIFT_TYPE_LISTADO_INITIAL,
    FETCH_GIFT_TYPE_LISTADO_SUCCESS,
    FETCH_GIFT_TYPE_LISTADO_FAIL,
  LOGOUT_USER
} from "../constants";
import { IAction, IGiftType, Pagination } from "../interfaces";

export interface GiftTypeFetchState {
    giftTypes: IGiftType[];
    pagination?: Pagination;
    isFetching: boolean;
    isLoaded: boolean;
    error: boolean;
}

const initialState: GiftTypeFetchState = {
    giftTypes: [],
    isFetching: false,
    isLoaded:false,
    error: false
};



export default function gitfTypeFetchReducer(
    state = initialState,
    action: IAction
): GiftTypeFetchState {
    switch (action.type) {
        case LOGOUT_USER:
            return initialState;
        case FETCH_GIFT_TYPE_LISTADO_INITIAL:
            return initialState;
        case FETCH_GIFT_TYPE_LISTADO:

            return {
                ...state,
                isFetching: true
            };
        case FETCH_GIFT_TYPE_LISTADO_SUCCESS:

            return {
                ...state,
                giftTypes: action.data.data,
                pagination: action.data.pagination,
                isFetching: false,
                isLoaded:true,
                error: false
            };
        case FETCH_GIFT_TYPE_LISTADO_FAIL:
            return {
                ...state,
                giftTypes: [],
                isFetching: false,
                isLoaded: true,
                error: true
            };
        default:
            return state;
    }
}
