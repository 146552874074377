import React, { ReactNode } from "react";
import TextField from "@material-ui/core/TextField";

import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import {emailValidate,isGoodPassword} from "../utils/formValidation";

import {FormLoginData} from "../../redux/interfaces";
import ButtonWithLoading from "../ButtonWithLoading";
const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

interface LoginFormProps {
  onSubmitForm: (data: FormLoginData) => void;
  isFetching: boolean;
  passwordField: boolean;
  children: ReactNode;
  buttonText?: string;
  
}
function LoginForm(props: LoginFormProps) { 
  const classes = useStyles();
  const { register, handleSubmit,  errors,formState  } = useForm<FormLoginData>({ mode: "onChange" });
  const { onSubmitForm, isFetching, passwordField, children, buttonText } = props;

  return (
    
          <form className={classes.form} onSubmit={handleSubmit(onSubmitForm)} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              inputRef={register({  required:true,pattern:emailValidate() })}
              error={errors.email?true:false}
              helperText={errors.email && errors.email.message}
            />
            {passwordField &&
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                inputRef={register({ required: true, pattern: isGoodPassword() })}
                error={errors.password ? true : false}
                helperText={errors.password && errors.password.message}
              />
            }
            
      <ButtonWithLoading
        color="primary"
        disabled={isFetching || !formState.isValid}
        loading={isFetching}
        text={buttonText || "Ingresar"}
      />
                  {children}
            
          </form>
  );
}

export default LoginForm;
