/* eslint-disable quotes */
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ListadoColumnas,Column } from "./interfaces";
interface Props {
    listadoColumnas: ListadoColumnas;
}

export default function ListadoHeader(props: Props) {
    const { listadoColumnas } = props;
    const columns = listadoColumnas.columns;
    

    return (    
            <TableHead>
                <TableRow>
                    {listadoColumnas.actions && (listadoColumnas.actions.edit || listadoColumnas.actions.delete) &&
                        <TableCell
                            key={'actions'}
                            style={{ minWidth: '20px' }}
                        >
                            Acciones
                        </TableCell>
                    }
                    {columns.map((column: Column) => (
                        <TableCell
                            key={column.name}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                        >
                            {column.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
    );
}