import { IUser } from "../redux/interfaces";
import * as axios from "axios";
import instance from "../axios";

export const all = (email: string,password: string): Promise<axios.AxiosResponse<IUser>> => {
    return instance.post<IUser>("panel/signIn",{
        email:email,
        password:password
    });
};
export const updateTermsService = (): Promise<axios.AxiosResponse<IUser>> => {
    return instance.put<IUser>("panel/terms-condition/", { termsAccepted: true});
};
export const passwordRecoveryInputEmail = (email: string): Promise<axios.AxiosResponse<IUser>> => {
    return instance.post<IUser>("panel/password-recovery/input-email", { email: email });
};
export const passwordRecoveryInputCode = (email: string,code: string): Promise<axios.AxiosResponse<IUser>> => {
    return instance.post<IUser>("panel/password-recovery/input-code", { email: email,code:code });
};
export const passwordRecoveryChangePassword = (email: string,code: string,password: string): Promise<axios.AxiosResponse<IUser>> => {
    return instance.post<IUser>("panel/password-recovery/new-password", { email:email,code:code, password: password });
};
export const passwordUserModify = (password: string): Promise<axios.AxiosResponse<IUser>> => {
    return instance.post<IUser>("panel/password-recovery/modify", { password: password });
};