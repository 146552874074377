import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import ImageDialog from "./ImageDialog";
import ImageSorting from "./ImageSorting";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: "30px",
    borderTop: "1px solid gray",
    borderBottom: "1px solid gray",
    minHeight: "100px"
  },
  addButton: {
    marginTop: "-23px"
  },
  title: {
    textAlign: "center",
    fontWeight: "bold"
  }
}));

interface Props {
  onChangeImages: (images: any[]) => void;
  title: string;
  maxFiles: number;
  sizeLimit: number;
  ratio: number;
  imagesLoaded?: any;
}

export default function ImageUploader(props: Props) {
  const classes = useStyles();
  const { onChangeImages, title, maxFiles, sizeLimit, ratio, imagesLoaded } = props;
  const [modal, setModal] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if(imagesLoaded && images.length === 0) {
      setImages(imagesLoaded);
    }
    /* eslint-disable  */
  }, [imagesLoaded]);

  const openModal = () => {
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const blobUrlToBase64 = (blobUrl: any, callback: (base64: any) => void) => {
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function() {
      var recoveredBlob = xhr.response;
      var reader = new FileReader();
      reader.onload = function() {
        callback(reader.result);
      };
      reader.readAsDataURL(recoveredBlob);
    };
    xhr.open("GET", blobUrl);
    xhr.send();
  };

  const handleSaveModal = (croppedUrls: any[]) => {
    if (croppedUrls && croppedUrls.length > 0) {
      const imgs: any = [...images];
      let imagesProcesed = 0;
      croppedUrls.forEach((croppedUrl) => {
        const image: any = {
          inline: true,
          url: croppedUrl
        };
        blobUrlToBase64(croppedUrl, (base64: any) => {
          image.base64 = base64;
          imgs.push(image);
          imagesProcesed++;
          if(imagesProcesed === croppedUrls.length) {
            setModal(false);
            onChangeImages(imgs);
            setImages(imgs);
          }
        });
      });
    }
  };

  const onSetImagesSort = (images: any) => {
    setImages(images);
    onChangeImages(images);
  };

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.title}>{title}</div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={openModal}
            className={classes.addButton}
            disabled={images.length >= maxFiles}
            size="small"
          >
            Agregar
          </Button>
        </div>
        <ImageDialog
          open={modal}
          handleClose={handleCloseModal}
          handleSave={handleSaveModal}
          imagesSize={images.length}
          maxFiles={maxFiles}
          sizeLimit={sizeLimit}
          ratio={ratio}
          title={title}
        />
        {(images || []).length === 0 && (
          <div style={{ textAlign: "center" }}>
            No hay imagenes para mostrar
          </div>
        )}
        {(images || []).length > 0 && (
          <ImageSorting images={images} onSetImagesSort={onSetImagesSort} ratio={ratio}/>
        )}
      </div>
    </div>
  );
}
