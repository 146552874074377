import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useForm } from "react-hook-form";
import { emailValidate } from "../../utils/formValidation";

import { connect } from "react-redux";
import { FormShop, FormShopAddress, Shop } from "../../../redux/interfaces";
import ButtonWithLoading from "../../ButtonWithLoading";
import { CategoriesState } from "../../../redux/reducers/categories.reducer";
import { GiftTypeState } from "../../../redux/reducers/giftType.reducer";

import FormSelect from "../../FormsElements/FormSelect";
import Input from "../../FormsElements/Input";

import ShopAddresses from "./ShopAddresses";
import ImageUploader from "../../ImageUploader/ImageUploader";
const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

interface Props {
  onSubmitForm: (data: FormShop) => void;
  isFetching: boolean;
  buttonText?: string;
  categoriesData: CategoriesState;
  giftTypeData: GiftTypeState;
  shop?: Shop;
}
function NewShopForm(props: Props) {
  const classes = useStyles();
  const [addresses, setAddresses] = useState();
  const [inHome, setInHome] = useState(false);
  const [featured, setFeatured] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    errors,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger
  } = useForm<FormShop>({ mode: "onChange" });

  const {
    onSubmitForm,
    isFetching,
    buttonText,
    categoriesData,
    giftTypeData,
    shop
  } = props;

  const transformImages = (images: string[]) => {
    return (
      images &&
      images.map((image: string) => {
        return {
          uploaded: true,
          url: image
        };
      })
    );
  };

  const categoriesOpciones = categoriesData.categories.map(category => {
    return {
      value: category._id,
      label: category.name,
      default:
        shop &&
        (shop as any).categories &&
        (shop as any).categories.includes(category._id)
    };
  });
  const giftTypeOpciones = giftTypeData.giftType.map(giftType => {
    return {
      value: giftType._id,
      label: giftType.name,
      default:
        shop &&
        (shop as any).giftTypes &&
        (shop as any).giftTypes.includes(giftType._id)
    };
  });

  useEffect(() => {
    if (shop && setValue) {
      const formShop: FormShop = shop as any;

      setValue("id", (formShop as any)._id);
      setValue("name", formShop.name);
      setValue("description", formShop.description);
      setValue("email", formShop.email);
      setValue("phone", formShop.phone);
      setValue("order", formShop.order);
      setInHome(formShop.inHome);
      setValue("inHome", formShop.inHome);
      setFeatured(formShop.featured);
      setValue("featured", formShop.featured);
      setValue("images", transformImages(formShop.images) as any);
      setValue(
        "thumb",
        formShop.thumb && (transformImages([formShop.thumb]) as any)
      );
      setValue("userEmail", formShop.userEmail);
      setValue("categories", formShop.categories);
      setValue("giftTypes", formShop.giftTypes);
      setValue("addresses", formShop.addresses);
      setAddresses(formShop.addresses);
      trigger();
    }
  /* eslint-disable */
  }, [shop, setValue]);

  const onChangeImages = (images: any[]) => {
    setValue("images", images);
  };

  const onChangeLogo = (logos: any[]) => {
    setValue("thumb", logos && logos.length > 0 ? logos : undefined);
  };

  const handleAddAddress = (newAddress: FormShopAddress) => {
    const newAddresses = [...(addresses || [])];
    newAddresses.push(newAddress);
    setAddresses(newAddresses);
    setValue("addresses", newAddresses);
  };

  const handleRemoveAddress = (index: number) => {
    const newAddresses = addresses.filter(
      (a: FormShopAddress, i: number) => i !== index
    );
    setAddresses(newAddresses);
    setValue("addresses", newAddresses);
  };

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(onSubmitForm)}
      noValidate
    >
      <Input
        name="name"
        label="Nombre"
        inputRef={register({ required: true })}
        error={errors.name ? true : false}
        helperText={errors.name && errors.name.message}
        fullWidth={true}
      />

      <Input
        name="description"
        label="Descripción"
        inputRef={register({ required: true })}
        error={errors.description ? true : false}
        helperText={errors.description && errors.description.message}
        fullWidth={true}
      />

      <Input
        name="email"
        label="Email"
        inputRef={register({ required: true, pattern: emailValidate() })}
        error={errors.email ? true : false}
        helperText={errors.email && errors.email.message}
        fullWidth={true}
      />
      <Input
        name="userEmail"
        label="Email Usuario"
        inputRef={register({ required: true, pattern: emailValidate() })}
        error={errors.userEmail ? true : false}
        helperText={errors.userEmail && errors.userEmail.message}
        fullWidth={true}
      />
      <Input
        name="phone"
        label="Teléfono"
        inputRef={register({ required: true })}
        error={errors.phone ? true : false}
        helperText={errors.phone && errors.phone.message}
        fullWidth={true}
      />
      <Input
        name="order"
        type="number"
        label="Orden"
        inputRef={register({ required: true })}
        error={errors.order ? true : false}
        helperText={errors.order && errors.order.message}
        fullWidth={true}
      />

      <FormSelect
        select={{
          name: "categories",
          label: "Categorías",
          multiple: true,
          options: categoriesOpciones,
          defaultOption: false,
          fullWidth: true
        }}
        controller={{
          control: control,
          errors: errors,
          setValue: setValue,
          setError: setError,
          clearErrors: clearErrors,
          errorsRule: {
            type: "required",
            message: "Debe seleccionar al menos una categoria"
          }
        }}
      />
      <FormSelect
        select={{
          name: "giftTypes",
          label: "Tipos",
          multiple: true,
          options: giftTypeOpciones,
          defaultOption: false,
          fullWidth: true
        }}
        controller={{
          control: control,
          errors: errors,
          setValue: setValue,
          setError: setError,
          clearErrors: clearErrors,
          errorsRule: {
            type: "required",
            message: "Debe seleccionar al menos un tipo"
          }
        }}
      />

      <FormControlLabel
        control={
          <Switch
            inputRef={register}
            name="inHome"
            checked={inHome}
            onChange={() => setInHome(!inHome)}
          />
        }
        label="Se visualiza en Home"
      />

      <FormControlLabel
        control={
          <Switch
            inputRef={register}
            name="featured"
            checked={featured}
            onChange={() => setFeatured(!featured)}
          />
        }
        label="Destacada"
      />

      <ShopAddresses
        handleAddAddress={handleAddAddress}
        rows={addresses}
        handleRemoveAddress={handleRemoveAddress}
      />

      <ImageUploader
        onChangeImages={onChangeLogo}
        title="Logo"
        maxFiles={1}
        sizeLimit={262144}
        ratio={1}
        imagesLoaded={getValues("thumb")}
      />

      <ImageUploader
        onChangeImages={onChangeImages}
        title="Imágenes"
        maxFiles={10}
        sizeLimit={1048576}
        ratio={10 / 6}
        imagesLoaded={getValues("images")}
      />

      <ButtonWithLoading
        color="primary"
        disabled={isFetching || !formState.isValid}
        loading={isFetching}
        text={buttonText || "Ingresar"}
      />
    </form>
  );
}

const mapStateToProps = (state: any) => {
  return {
    categoriesData: state.categoriesData,
    giftTypeData: state.giftTypeData
  };
};

export default connect(mapStateToProps)(NewShopForm);
