import {
  UPDATE_TITLE,
  USER_ALERT_CLOSE
} from "../constants";



const updateTitleSuccess = (data: { title: string }) => {
  return { type: UPDATE_TITLE, data };
};

export const updateTitle = (title: string) => {
  return (dispatch: any) => {
    
    const route: { title: string} = {
      title
    };
    document.title="Boomer | "+title;
    dispatch(updateTitleSuccess(route));
  };
};
export const closeUserAlert = () => {
  return (dispatch: any) => {
    dispatch({ type: USER_ALERT_CLOSE, data:{} });
  };
};
