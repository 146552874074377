import {
    FETCHING_GIFT_TYPE,
    FETCHING_GIFT_TYPE_SUCCESS,
    FETCHING_GIFT_TYPE_FAIL,
    LOGOUT_USER
} from "../constants";
import { IAction, IGiftType, Pagination } from "../interfaces";

export interface GiftTypeState {
    giftType: IGiftType[];
    pagination?: Pagination;
    isFetching: boolean;
    isLoaded: boolean;
    error: boolean;
}

const initialState: GiftTypeState = {
    giftType: [],
    isFetching: false,
    isLoaded:false,
    error: false
};



export default function giftTypeReducer(
    state = initialState,
    action: IAction
): GiftTypeState {
    switch (action.type) {
        case LOGOUT_USER:
            return initialState;
        case FETCHING_GIFT_TYPE:

            return {
                ...state,
                isFetching: true
            };
        case FETCHING_GIFT_TYPE_SUCCESS:

            return {
                ...state,
                giftType: action.data,
                isFetching: false,
                isLoaded:true,
                error: false
            };
        case FETCHING_GIFT_TYPE_FAIL:
            return {
                ...state,
                giftType: [],
                isFetching: false,
                isLoaded: true,
                error: true
            };
        default:
            return state;
    }
}
