/* eslint-disable quotes */
import React from "react";
import { makeStyles,Theme,createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { ListadoColumnas,  RequestOptionsListado,Filter } from "./interfaces";
import ListadoHeader from "./ListadoHeader"; 
import ListadoBody from "./ListadoBody"; 
import ListadoFilters from "./ListadoFilters"; 
import {Link} from "react-router-dom";
import Button from '@material-ui/core/Button';
const useStyles = makeStyles(
    (theme: Theme) =>
  createStyles({
    root: {
        width: "100%",
    },
    container: {
        /*maxHeight: 440,*/
        
        [theme.breakpoints.down("xs")]: {
            maxWidth:400
        }
    },
    addButton:{
        marginLeft:"10px",
        marginBottom:"10px"
    }
  })
    
    
);

interface Props {
    listadoColumnas: ListadoColumnas;
    rows: any[];
    pagination?: any;
    fetch?: (options?: RequestOptionsListado) => void;
    filters?: Filter[];
}


export default function Listado(props: Props) {
    
    const classes = useStyles();
    const { listadoColumnas, rows, pagination, fetch, filters } = props;

    const [page, setPage] = React.useState((pagination ? (pagination.page-1) : 0));
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [filtersS, setFiltersS] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    
    const pageCount = (pagination ? pagination.perPage * pagination.totalPages : -1);

    React.useEffect(() => {
        setLoading(false);
    }, [props.rows]);
    const handleChangePage = (event: unknown, newPage: number) => {
        setLoading(true);
        const options: RequestOptionsListado = {
            page: (newPage+1),
            rowsPerPage: rowsPerPage,
            ...filtersS
        };
        if (fetch) {
            setPage(newPage);
            fetch(options);
            
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(true);
        const options: RequestOptionsListado = {
            page: 1,
            rowsPerPage: +event.target.value,
            ...filtersS
        };
        if (fetch) {
            setRowsPerPage(+event.target.value);
            setPage(0);
            fetch(options);
        }
    };
    const onHandleChangeFilter = (key: string, value: string | number) => {
   
        const options: RequestOptionsListado = {
            page: 1,
            rowsPerPage: rowsPerPage || 10
        };
        if (fetch) {
            const filter = {
                ...filtersS,
                [key]: value
            };
            setPage(0);
            setFiltersS(filter);
            fetch({ ...options, ...filter });
        } 
    };

    return (
        <Paper className={classes.root}>
            {filters && <ListadoFilters filters={filters} onHandleChange={onHandleChangeFilter} />}
            {
                listadoColumnas.actions && listadoColumnas.actions.add &&
                <Link to={listadoColumnas.actions.add.link}>
                    
                    <Button variant="contained" color="primary" className={classes.addButton}>
                        
                        Nuevo
                    </Button>
                    
                </Link>
            }
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <ListadoHeader listadoColumnas={listadoColumnas} />
                    <ListadoBody listadoColumnas={listadoColumnas} rows={rows} loading={loading} />
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={pageCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Resultados por página"
                labelDisplayedRows={({ from, to, count }) => from.toString() + (count !== -1 ? " de "+count.toString() : " más de "+ to.toString())}
            />
        </Paper>
    );
}