export const paymentStatusOptions = [
    { value: "approved", label: "Aprobado" },
    { value: "cancelled", label: "Cancelado" },
    { value: "rejected", label: "Rechazado" },
];

export const voucherStatusOptions = [
    { value: "create", label: "Disponible" },
    { value: "changed", label: "Canjeado" },
    { value: "canceled", label: "Cancelado" },
    { value: "expired", label: "Vencido" },
];
export const voucherStatusFilterOptions = [
    { value: "changed", label: "Canjeado" },
    { value: "canceled", label: "Cancelado" },
    { value: "expired", label: "Vencido" },
];
export const paymentStatusFilterAdminOptions = [
    { value: "generate", label: "Generado" },
    { value: "pending", label: "Pendiente de pago" },
    { value: "approved", label: "Aprobado" },
    { value: "cancelled", label: "Cancelado" },
    { value: "rejected", label: "Rechazado" },
];
export const voucherStatusFilterAdminOptions = [
    { value: "create", label: "Creado" },
    { value: "changed", label: "Canjeado" },
    { value: "canceled", label: "Cancelado" },
    { value: "expired", label: "Vencido" },
];
export const shippingStatusFilterAdminOptions = [
    { value: "generate", label: "Generado" },
    { value: "pending", label: "Pendiente de envio" },
    { value: "send", label: "Enviado" },
    { value: "cancelled", label: "Cancelado" },
    { value: "rejected", label: "Rechazado" },
];
export const paymentMethodFilterOptions = [
    { value: "free", label: "Gratuito" },
    { value: "mercadopago", label: "Mercado Pago" }
];