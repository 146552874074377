import React, { useEffect } from "react";
import {useHistory} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Copyright from "../../../components/Copyrigth";
import NewGiftTypeForm from "../../../components/Forms/NewGiftType/NewGiftTypeForm";
import { connect } from "react-redux";
import { updateTitle } from "../../../redux/actions/navigation.action";
import { post } from "../../../redux/actions/giftType.action";
import { IGiftType } from "../../../redux/interfaces";
import { useParams } from "react-router-dom";
import { fetchById } from "../../../services/giftType.service";
import { CircularProgress } from "@material-ui/core";

const pageTitle = "Editar Tipo Gift Card";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  layout: {
    width: "auto",

    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "70%",
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  title: {
    color: "#f65521",
    fontWeight: "bold"
  }
}));


interface Props {
  updateTitlePage: (title: string) => void;
  post: (data: IGiftType,cb: any) => void;
}

function EditGiftTypeContainer(props: Props) {
  const { id } = useParams();
  const { updateTitlePage, post } = props;
  const classes = useStyles();
  const [giftType, setGiftType] = React.useState();
  const history = useHistory();

  useEffect(() => {
    updateTitlePage(pageTitle);
  });

  useEffect(() => {
    if (!giftType) {
      fetchById(id).then(response => {
        if (response && response.data) {
          setGiftType(response.data);
        }
      });
    }
  }, [giftType, id]);

  const postForm = (data: IGiftType) => {
    post(data,()=>{history.push("/gift-types");});
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography
            className={classes.title}
            component="h1"
            variant="h4"
            align="center"
          >
            Editar Tipo GiftCard
          </Typography>
          {!giftType && <CircularProgress />}
          {giftType && (
            <NewGiftTypeForm
              onSubmitForm={postForm}
              isFetching={false}
              buttonText={"Guardar"}
              shop={giftType}
            />
          )}
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTitlePage: (title: string) => {
      dispatch(updateTitle(title));
    },
    post(data: IGiftType,cb: any) {
      dispatch(post(data,cb));
    },
    fetchById(id: string) {
      dispatch(fetchById(id));
    }
  };
};

export default connect(
  undefined,
  mapDispatchToProps
)(EditGiftTypeContainer);
