import {
  FETCHING_CATEGORIES,
  FETCHING_CATEGORIES_SUCCESS,
  FETCHING_CATEGORIES_FAIL,
  FETCHING_CATEGORIES_LISTADO_INITIAL,
  FETCHING_CATEGORIES_LISTADO,
  FETCHING_CATEGORIES_LISTADO_SUCCESS,
  FETCHING_CATEGORIES_LISTADO_FAIL,
  POST_CATEGORY,
  POST_CATEGORY_SUCCESS,
  POST_CATEGORY_FAIL,
  DELETE_CATEGORY_LISTADO,
  DELETE_CATEGORY_LISTADO_SUCCESS,
  DELETE_CATEGORY_LISTADO_FAIL,
  
} from "../constants";
import { RequestOptions } from "../interfaces";
import * as categoriesService from "../../services/categories.service";


export const fetchCategories = (options?: RequestOptions) => {
  return (dispatch: any) => {
    dispatch({ type: FETCHING_CATEGORIES });

    categoriesService
      .allWithoutPagination(options)
      .then(response => {
        if (response.data.length > 0) {
          dispatch({ type: FETCHING_CATEGORIES_SUCCESS, data: response.data });
        } else {
          dispatch({ type: FETCHING_CATEGORIES_FAIL, data: {} });
        }
      })
      .catch(error => {
        console.log("fetchCategories Error");
        dispatch({ type: FETCHING_CATEGORIES_FAIL, error });
      });
  };
};
/**
 * GET - All
 */
export const fetch = (options?: RequestOptions) => {
  return (dispatch: any) => {
    dispatch({ type: FETCHING_CATEGORIES_LISTADO });
    categoriesService
      .allAdmin(options)
      .then(response => {
        if (response.data.data.length >= 0) {
          dispatch({
            type: FETCHING_CATEGORIES_LISTADO_SUCCESS,
            data: response.data
          });
        } else {
          dispatch({ type: FETCHING_CATEGORIES_LISTADO_FAIL, data: response.data });
        }
      })
      .catch(error => {
        dispatch({ type: FETCHING_CATEGORIES_LISTADO_FAIL, error });
      });
  };
};
/**
 * POST - Crear CATEGORY
 */
export const post = (shopData: any, cb: any) => {
  return (dispatch: any) => {
    dispatch({ type: POST_CATEGORY });
    categoriesService
      .create(shopData)
      .then(shop => {
        if (shop.data.error) {
          dispatch({ type: POST_CATEGORY_FAIL, data: shop.data });
        } else {
          dispatch({ type: POST_CATEGORY_SUCCESS, data: shop.data });
          dispatch({ type: FETCHING_CATEGORIES_LISTADO_INITIAL });
          cb();
        }
      })
      .catch(error => {
        dispatch({ type: POST_CATEGORY_FAIL, error });
      });
  };
};
/**
 * DELETE - ELIMINAR CATEGORY
 */
export const deleted = (id: string, cb: any) => {
  return (dispatch: any) => {
    dispatch({ type: DELETE_CATEGORY_LISTADO });
    categoriesService
      .deleted(id)
      .then(shop => {
        if (shop.data.error) {
          dispatch({ type: DELETE_CATEGORY_LISTADO_FAIL, data: shop.data });
        } else {
          dispatch({ type: DELETE_CATEGORY_LISTADO_SUCCESS, data: shop.data });
          cb();
        }
      })
      .catch(error => {
        dispatch({ type: DELETE_CATEGORY_LISTADO_FAIL, error });
      });
  };
};
