import React from "react";
import TextField from "@material-ui/core/TextField";

interface Props {
  name: string;
  label: string;
  inputRef: any;
  error: boolean;
  helperText: string | undefined;
  fullWidth?: boolean;
  type?: string;
  disabled?: boolean;
  onChange?: (e: any) => void;
  tabIndex?: number;
  placeholder?: string;
  required?: boolean;
}

export default function Input(props: Props) {
  const {
    name,
    label,
    inputRef,
    error,
    helperText,
    fullWidth,
    type,
    disabled,
    onChange,
    tabIndex,
    placeholder,
    required
  } = props;

  return (
    <TextField
      type={type || "text"}
      variant="outlined"
      margin="normal"
      required={(typeof required!=="undefined"?required:true)}
      fullWidth={typeof fullWidth !== "undefined" ? fullWidth : false}
      id={name}
      label={label}
      name={name}
      autoComplete={name}
      autoFocus
      inputRef={inputRef}
      error={error}
      helperText={helperText}
      disabled={disabled}
      onChange={onChange}
      tabIndex={tabIndex}
      placeholder={placeholder}
    />
  );
}
