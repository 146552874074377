import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Copyright from "../../components/Copyrigth";

import { connect } from "react-redux";
import { updateTitle } from "../../redux/actions/navigation.action";
import BoomerButton from "../../components/BoomerButton";
import { isMobile } from "../../utils/utils";

const pageTitle = "Boomer";

const useStyles = makeStyles(theme => ({
  layout: {
    width: "auto",

    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  titleContainer: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "30px"
  },
  logo: {
    width: "30%"
  },
  title: {
    fontSize: "20px"
  },
  boomerText: {
    color: "#ff3453",
    fontWeight: "bold"
  },
  centerText: {
    textAlign: "center"
  },
  goToShopContainer: {
    width: "200px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10px",
    marginBottom: "40px"
  },
  googlePlayLogo: {
    height: "80px",
    minHeight: "80px",
    cursor: "pointer"
  },
  appstoreLogo: {
    height: "54px",
    minHeight: "54px",
    cursor: "pointer"
  },
  storesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    flexFlow: "row wrap"
  }
}));

interface Props {
  updateTitlePage: (title: string) => void;
}
function ShareContainer(props: Props) {
  const classes = useStyles();
  const { updateTitlePage } = props;
  React.useEffect(() => {
    updateTitlePage(pageTitle);
  });
  const isMobileDevice = isMobile();
  const params: any = new URLSearchParams(window.location.search);
  const shopName = params.get("name");
  const shopId = params.get("id");
  const goToShop = () => {
    window.location.href = "boomer:///comercio?shopId=" + shopId;
  };
  const goToPlaystore = () => {
    if (isMobileDevice) {
      window.location.href = "market://details?id=com.boomer.boomervoucher";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.boomer.boomervoucher";
    }
  };
  const goToAppstore = () => {
    window.location.href = "https://apps.apple.com/ar/app/boomer/id1535342701";
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <div className={classes.titleContainer}>
            <img src="/ICONO.png" className={classes.logo} alt="" />
            <div className={classes.title}>
              <b>{shopName}</b> está en{" "}
              <span className={classes.boomerText}>Boomer</span>
            </div>
          </div>
          <div className={classes.centerText}>
            Si ya tenés la aplicación instalada, podes visitarla desde acá
          </div>
          <div className={classes.goToShopContainer}>
            <BoomerButton text="Visitar Comercio" onClick={goToShop} />
          </div>
          <div className={classes.centerText}>
            Si todavía no tenés nuestra aplicación, descargala de forma gratuita
          </div>
          <div className={classes.storesContainer}>
            <img
              className={classes.googlePlayLogo}
              src="/images/google-play-badge.png"
              onClick={goToPlaystore}
              alt=""
            />
            <img
              className={classes.appstoreLogo}
              src="/images/app-store-badge.svg"
              onClick={goToAppstore}
              alt=""
            />
          </div>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTitlePage: (title: string) => {
      dispatch(updateTitle(title));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ShareContainer);
