import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { FormShopAddress } from "../../../redux/interfaces";
import Paper from "@material-ui/core/Paper";
import DeleteSharpIcon from "@material-ui/icons/DeleteSharp";
import { IconButton } from "@material-ui/core";
const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});
interface Prop {
  rows: FormShopAddress[];
  handleRemoveAddress: (index: number) => void;
}
function ListAddresses(props: Prop) {
  const classes = useStyles();
  const { rows, handleRemoveAddress } = props;

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Calle</TableCell>
              <TableCell>Número</TableCell>
              <TableCell>Detalle</TableCell>
              <TableCell>Ciudad</TableCell>
              <TableCell>Provincia</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <IconButton onClick={() => handleRemoveAddress(index)}>
                    <DeleteSharpIcon color="error" />
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.street}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.number}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.detail}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.city}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.state}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ListAddresses;
