 import {
    FETCH_GIFT_CARD_LISTADO_INITIAL,
    FETCH_GIFT_CARD_LISTADO,
    FETCH_GIFT_CARD_LISTADO_SUCCESS,
    FETCH_GIFT_CARD_LISTADO_FAIL,
    POST_GIFT_CARD,
    POST_GIFT_CARD_SUCCESS,
    POST_GIFT_CARD_FAIL,
    DELETE_GIFT_CARD_LISTADO,
    DELETE_GIFT_CARD_LISTADO_SUCCESS,
    DELETE_GIFT_CARD_LISTADO_FAIL
} from "../constants";
import { RequestOptions } from "../interfaces";
import * as giftCardService from "../../services/giftCard.service";

/**
 * GET - All
 */
export const fetch = (options?: RequestOptions) => {
    return (dispatch: any) => {
  
      dispatch({ type: FETCH_GIFT_CARD_LISTADO });
      giftCardService
      .allAdmin(options)
        .then((response) => {
          if ( response.data.data.length>=0) {
                      
            dispatch({ type: FETCH_GIFT_CARD_LISTADO_SUCCESS, data: response.data });
            
        } else {
            dispatch({ type: FETCH_GIFT_CARD_LISTADO_FAIL, data: response.data });
        }
  
  
        }).catch(error => {
  
          dispatch({ type: FETCH_GIFT_CARD_LISTADO_FAIL, error });
        });
  
    };
  };
/**
 * POST - Crear giftType
 */
export const post = (shopData: any,cb: any) => {
  return (dispatch: any) => {

    dispatch({ type: POST_GIFT_CARD });
    giftCardService
    .create(shopData)
      .then((shop) => {
        if (shop.data.error) {
          dispatch({ type: POST_GIFT_CARD_FAIL, data: shop.data });
        } else {
          dispatch({ type: POST_GIFT_CARD_SUCCESS, data: shop.data });
          dispatch({ type: FETCH_GIFT_CARD_LISTADO_INITIAL });
          cb();
        }

      }).catch(error => {

        dispatch({ type: POST_GIFT_CARD_FAIL, error });
      });

  };
};
  /**
 * DELETE - ELIMINAR GIFTTYPE
 */
export const deleted = (id: string,cb: any) => {
    return (dispatch: any) => {
  
      dispatch({ type: DELETE_GIFT_CARD_LISTADO });
      giftCardService
      .deleted(id)
        .then((shop) => {
          if (shop.data.error) {
            dispatch({ type: DELETE_GIFT_CARD_LISTADO_FAIL, data: shop.data });
          } else {
            dispatch({ type: DELETE_GIFT_CARD_LISTADO_SUCCESS, data: shop.data });
            cb();
          }
  
        }).catch(error => {
  
          dispatch({ type: DELETE_GIFT_CARD_LISTADO_FAIL, error });
        });
  
    };
  };