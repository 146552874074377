import {
  FETCH_SHOPS_LISTADO,
  FETCH_SHOPS_LISTADO_SUCCESS,
  FETCH_SHOPS_LISTADO_FAIL,
  LOGOUT_USER
} from "../constants";
import { IAction, Shop, Pagination } from "../interfaces";

export interface ShopFetchState {
    shops: Shop[];
    pagination?: Pagination;
    isFetching: boolean;
    isLoaded: boolean;
    error: boolean;
}

const initialState: ShopFetchState = {
    shops: [],
    isFetching: false,
    isLoaded:false,
    error: false
};



export default function shopFetchReducer(
    state = initialState,
    action: IAction
): ShopFetchState {
    switch (action.type) {
        case LOGOUT_USER:
            return initialState;
        case FETCH_SHOPS_LISTADO:

            return {
                ...state,
                isFetching: true
            };
        case FETCH_SHOPS_LISTADO_SUCCESS:

            return {
                ...state,
                shops: action.data.data,
                pagination: action.data.pagination,
                isFetching: false,
                isLoaded:true,
                error: false
            };
        case FETCH_SHOPS_LISTADO_FAIL:
            return {
                ...state,
                shops: [],
                isFetching: false,
                isLoaded: true,
                error: true
            };
        default:
            return state;
    }
}
