import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Copyright from "../../components/Copyrigth";

import { connect } from "react-redux";
import { updateTitle } from "../../redux/actions/navigation.action";

const pageTitle = "Políticas de privacidad";

const useStyles = makeStyles(theme => ({
  layout: {
    width: "auto",

    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  }
}));

interface Props {
  updateTitlePage: (title: string) => void;
}
function PrivacyContainer(props: Props) {
  const classes = useStyles();
  const { updateTitlePage } = props;
  React.useEffect(() => {
    updateTitlePage(pageTitle);
  });

  return (
    <React.Fragment>
      <CssBaseline />

      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <h3>POLÍTICA DE PRIVACIDAD ARGENTINA</h3>
          <p>
            <b>
              Para mejorar su experiencia en el uso de nuestro sitio,
              colectaremos y procesaremos información, que servirá para corregir
              o mejorar la accesibilidad o las prestaciones de las herramientas
              que ponemos a su disposición.
            </b>
          </p>
          <p>
            La información que usaremos es sobre el tipo de computadora que usa,
            sus visitas y uso de este sitio web incluyendo su dirección IP,
            ubicación geográfica, tipo de navegador, fuente de referencia al
            sitio, duración de las visitas y número de vistas por
            página.Información del registro en nuestro sitio web incluyendo
            email, nombre, apellido, lugar y país de residencia, dirección,
            teléfono, avatar y contraseña de ingreso al sitio.
          </p>
          <p>
            Los datos personales ingresados en este sitio web serán utilizados
            para los propósitos especificados en estas políticas de privacidad o
            en las secciones relevantes del sitio, para gestionar el sitio web,
            habilitarle el uso de los servicios disponibles. Enviarle
            comunicados, notificaciones, newlester, etc. Proveer a terceros
            información estadística sobre los usuarios, esta información no será
            utilizada para identificar a ningún individuo particular. Gestionar
            consultas y quejas relacionadas con el sitio web.
          </p>
          <p>
            No brindaremos sin su expreso consentimiento su información personal
            a cualquier tercero con el propósito de marketing directo.
          </p>
          <p>
            Si se realiza un pedido con pago online, Boomer no almacena los
            datos de la tarjeta de crédito; esta tarea la realizan los
            proveedores de pagos online.
          </p>
          <h4>Visibilidad del Usuario</h4>
          <p>
            Si Boomer habilitara la posibilidad al usuario de hacer comentarios,
            calificaciones o menciones sobre el comercio u otros, el nombre de
            usuario y avatar será visible en el sitio.
          </p>
          <h4>Boomer usa Cookies</h4>
          <p>
            Usaremos las cookies de sesión para estar al tanto de usted mientras
            navega por el sitio. Usaremos cookies persistentes para habilitar a
            nuestro sitio web que lo reconozca a usted cuando visita nuestro
            sitio. Las cookies de sesión serán eliminadas de su computadora
            cuando usted cierra el navegador web. Las cookies persistentes se
            mantendrán almacenadas en su computadora hasta que sean eliminadas,
            o hasta que lleguen a una fecha de expiración especificada.
          </p>
          <p>
            Utilizamos Google Analytics para analizar el uso de este sitio web.
            Google Analytics genera información estadística y de otro tipo sobre
            el uso de sitios web mediante cookies, las cuales son almacenadas en
            la computadora del usuario. La información generada en relación con
            nuestro sitio web es utilizada para crear reportes sobre el uso de
            nuestro sitio. Google almacenará esta información.
          </p>
          <p>
            Las políticas de privacidad de Google se encuentran disponibles en:
            http://www.google.com/privacypolicy.html
          </p>
          <p>
            La mayoría de los navegadores web lo autorizan a usted a rechazar la
            aceptación de cookies.
          </p>
          <h4>Modificaciones a nuestra Política de Privacidad</h4>
          <p>
            Podremos modificar nuestra política de privacidad en cualquier
            momento.
          </p>
          <h4>Notificación de rechazo</h4>
          <p>
            Usted nos podrá notificar que no acepta que sus datos personales
            sean procesados por motivos de marketing por email en cualquier
            momento.
          </p>
          <h4>Sitios web de terceros</h4>
          <p>
            El sitio web contiene vínculos a otros sitios web. Nosotros no nos
            hacemos responsables por las políticas de privacidad o prácticas de
            otros sitios web.
          </p>

          <h4>Actualización de la información</h4>
          <p>
            Si su información ha cambiado, usted es responsable de modificarla
            en el sitio web. Esto permitirá el correcto funcionamiento del
            sitio.
          </p>

          <h4>Contacto</h4>
          <p>
            Si usted tiene cualquier pregunta sobre estas políticas de
            privacidad o nuestro trato con sus datos personales, por favor
            escribanos un email a{" "}
            <a href="mailto:info@boomer.com.ar">info@boomer.com.ar</a>
          </p>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTitlePage: (title: string) => {
      dispatch(updateTitle(title));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(PrivacyContainer);
