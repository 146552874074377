import { createStore,applyMiddleware } from "redux";
import Reducers from "./reducers";
import thunk from "redux-thunk";
export default function configureStore() {
  const persistedState = localStorage.getItem("reduxState") 
                       ? JSON.parse(localStorage.getItem("reduxState")|| "{}")
                       : {};

  const store = createStore(Reducers, persistedState, applyMiddleware(thunk));

  store.subscribe(()=>{
    const state = JSON.parse(JSON.stringify(store.getState()));
    delete state["voucherData"];
    delete state["sales"];
    delete state["userDataPasswordRecovery"];
    delete state["shopsData"];
    delete state["shopsFetchData"];
    delete state["giftTypeFetchData"];
    delete state["giftCardFetchData"];
    //delete state.navigation.userAlert;
    localStorage.setItem("reduxState", JSON.stringify(state));
  });
  return store;
};