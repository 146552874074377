import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { ICategories, Shop } from "../../../redux/interfaces";
import ButtonWithLoading from "../../ButtonWithLoading";
import Input from "../../FormsElements/Input";
import InputColorPicker from "../../FormsElements/InputColorPicker";
import "material-ui-rc-color-picker/assets/index.css";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

interface Props {
  onSubmitForm: (data: ICategories) => void;
  isFetching: boolean;
  buttonText?: string;
  shop?: Shop;
}
function CategoryForm(props: Props) {
  const classes = useStyles();
  const [inHome, setInHome] = useState(false);
  // const [backgroundColor, setBackgroundColor] = useState();
  const {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    trigger,
    getValues
  } = useForm<ICategories>({ mode: "onChange" });

  const { onSubmitForm, isFetching, buttonText, shop } = props;

  useEffect(() => {
    if (shop && setValue) {
      const formShop: ICategories = shop as any;
      setValue("id", (formShop as any)._id);
      setValue("name", formShop.name);
      setValue("icon", formShop.icon);
      setInHome(formShop.inHome);
      setValue("inHome", formShop.inHome);
      setValue("shortName", formShop.shortName);
      setValue("backgroundColor", formShop.backgroundColor);
      setValue("iconColor", formShop.iconColor);
      trigger();
    }
    if (!shop) {
      setValue("backgroundColor", "#44aa00");
      setValue("iconColor", "#ffffff");
      trigger("backgroundColor");
      trigger("iconColor");
    }
    /* eslint-disable */
  }, [shop, setValue]);

  const onChangeBackgroundColor = (color: string) => {
    setValue("backgroundColor", color);
  };

  const onChangeIconColor = (color: string) => {
    setValue("iconColor", color);
  };

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(onSubmitForm)}
      noValidate
    >
      <Input
        name="name"
        label="Nombre"
        inputRef={register({ required: true })}
        error={errors.name ? true : false}
        helperText={errors.name && errors.name.message}
        fullWidth={true}
      />
      <Input
        name="shortName"
        label="Nombre Abreviado"
        inputRef={register({ required: true })}
        error={errors.shortName ? true : false}
        helperText={errors.shortName && errors.shortName.message}
        fullWidth={true}
      />
      <InputColorPicker
        name="backgroundColor"
        label="Fondo Icono"
        inputRef={register({ required: true })}
        errors={errors}
        defaultValue={getValues("backgroundColor")}
        onChange={onChangeBackgroundColor}
      />
      <InputColorPicker
        name="iconColor"
        label="Color Icono"
        inputRef={register({ required: true })}
        errors={errors}
        defaultValue={getValues("iconColor")}
        onChange={onChangeIconColor}
      />
      <Input
        name="icon"
        label="Icono"
        inputRef={register({ required: true })}
        error={errors.icon ? true : false}
        helperText={errors.icon && errors.icon.message}
        fullWidth={true}
      />
      <div style={{ fontSize: "12px" }}>
        Elegir un icono de{" "}
        <a
          href="https://oblador.github.io/react-native-vector-icons/"
          target="_blank"
        >
          aquí
        </a>
        . El formato debe ser Paquete|Icono. Ejemplo: FontAwesome5|guitar
      </div>
      <FormControlLabel
        control={
          <Switch
            inputRef={register}
            name="inHome"
            checked={inHome}
            onChange={() => setInHome(!inHome)}
          />
        }
        label="Se visualiza en Home"
      />
      <ButtonWithLoading
        color="primary"
        disabled={isFetching || !formState.isValid}
        loading={isFetching}
        text={buttonText || "Ingresar"}
      />
    </form>
  );
}

const mapStateToProps = (state: any) => {
  return null;
};

export default connect(mapStateToProps)(CategoryForm);
