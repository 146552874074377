/* eslint-disable quotes */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Filter } from "./interfaces";
import Grid from '@material-ui/core/Grid';
import ListadoFilter from "./ListadoFilter";
const useStyles = makeStyles({
    
    container: {
        padding:20
    },
});

interface Props {
    filters: Filter[];
    onHandleChange: (key: string,value: string | number) => void;
}

export default function ListadoFilters(props: Props) {
    const classes = useStyles();
    const { filters, onHandleChange } = props;

    return (
        <Grid className={classes.container}>
            {filters && 
                filters.map(filter => <ListadoFilter filter={filter} key={filter.name} onHandleChange={onHandleChange} />)
            }            
        </Grid>
    );
}