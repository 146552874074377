import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import VoucherInput from "./VoucherInputContainer";
import Review from "./ReviewContainer";



import Copyright from "../../components/Copyrigth";

import { connect } from "react-redux";
import { VoucherState } from "../../redux/reducers/voucher.reducer";
import { fetchVoucher } from "../../redux/actions/voucher.action";
import { UserState } from "../../redux/reducers/user.reducer";

import { updateTitle } from "../../redux/actions/navigation.action";




const pageTitle = "Canjear Boomer";


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",

    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  title:{
    color: "#f65521",
    fontWeight: "bold"
  }
}));

interface VoucherChangeProps {
  updateTitlePage: (title: string) => void;
  fetchVoucher: (code: string) => void;
  userData: UserState;
  voucherData: VoucherState;

}

function VoucherChangeContainer(props: VoucherChangeProps) {

  const steps = ["Consultar Boomer", "Confirmar Canje"];
  const { updateTitlePage } = props;
  const classes = useStyles();


  const activeStep = props.voucherData.activeStep;
  useEffect(
    () => {
      updateTitlePage(pageTitle);
      /*if(!props.userData.isLogged){
          history.push("/login")
      }*/
    });


  function getStepContent(step: number) {

    switch (step) {
      case 0:
        return <VoucherInput />;
      case 1:
        return <Review />;
      default:
      //throw new Error('Unknown step');
    }
  }

  return (

    <React.Fragment>
      <CssBaseline />

      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography className={classes.title} component="h1" variant="h4" align="center">
            Canjear Boomer
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {getStepContent(activeStep)}
          </React.Fragment>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>

  );
}






const mapStateToProps = (state: any) => {

  return {
    voucherData: state.voucherData,
    userData: state.userData
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTitlePage: (title: string) => {

      dispatch(updateTitle(title));
    },
    fetchVoucher: (code: string) => {

      dispatch(fetchVoucher(code));
    }
  };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VoucherChangeContainer);
