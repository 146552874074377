import React from "react";
import Button from "@material-ui/core/Button";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  boomerButton: {
    background:
      "linear-gradient(30deg, rgba(255,90,25,1) 0%, rgba(255,30,115,1) 100%)",
      color: "white"
  },
  disabled: {
    background: "gray"
  }
}));

interface Props {
  disabled?: boolean;
  text: string;
  onClick?: () => void;
}
function BoomerButton(props: Props) {
  const classes = useStyles();
  const { disabled, text, onClick } = props;

  return (
    <Button
      onClick={onClick}
      type="button"
      fullWidth
      variant="contained"
      disabled={disabled}
      className={disabled ? classes.disabled : classes.boomerButton}
    >
      {text}
    </Button>
  );
}

export default BoomerButton;
