import React from "react";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import StoreIcon from "@material-ui/icons/Store";
export default function AdminShopMenuItems() {
  return (
    <>
      <ListItem button component={Link} to="/shops">
        <ListItemIcon>
          <StoreIcon></StoreIcon>
        </ListItemIcon>
        <ListItemText primary="Listado de comercios" />
      </ListItem>
      
      <ListItem button component={Link} to="/logout">
        <ListItemIcon>
          <ExitToAppIcon></ExitToAppIcon>
        </ListItemIcon>
        <ListItemText primary="Salir" />
      </ListItem>
    </>
  );
}
