import React, { useEffect } from "react";
import Listado from "../../../components/Listado/index";
import { ListadoColumnas,Filter } from "../../../components/Listado/interfaces";

import { connect } from "react-redux";
import { GiftCardFetchState } from "../../../redux/reducers/giftCardFetch.reducer";
import { fetch,deleted } from "../../../redux/actions/giftCard.action";
import { RequestOptions } from "../../../redux/interfaces";
import { updateTitle } from "../../../redux/actions/navigation.action";

const pageTitle = "Listado Boomers";




interface Props {
    giftCards: GiftCardFetchState;
    fetch: (options?: RequestOptions) => void;
    updateTitlePage: (title: string) => void;
    deleted: (id: string,cb: any) => void;
    
}

function GiftCardContainer(props: Props) {
    const { giftCards, fetch, updateTitlePage,deleted } = props;

    const deleteElement = (id: string)=>{
        
        deleted(id,fetch);
    };
    
    const filters: Filter[] = [
        { name: "inHome", label: "En Home", type: "select", options: [{ value: "true", label: "SI" },{ value: "false", label: "NO" }]},
    ];
    const columns: ListadoColumnas = {
        actions:{
            add:{ name: "add", label: "Nuevo", minWidth: 10,link:"/gift-card/" },
            edit:{ name: "edit", label: "Editar", minWidth: 10,link:"/gift-card/" },
            delete:{ name: "delete", label: "Eliminar", minWidth: 10,click: deleteElement },
        },
        columns:[
            { name: "amount", label: "Monto", minWidth: 90, format: (number) => "$ " + parseFloat(number.toString()).toFixed(2)  },    
            { name: "inHome", label: "En Home", minWidth: 90 },
    
        ]
    };
        
    useEffect(() => {
        !giftCards.isLoaded && !giftCards.isFetching && fetch();
        updateTitlePage(pageTitle); 
    });
    
    return (
        <>
            {giftCards.isLoaded &&
                <Listado listadoColumnas={columns} rows={giftCards.giftCards} pagination={giftCards.pagination} filters={filters} fetch={fetch}/>
            }
        </>
        
    );
}

const mapStateToProps = (state: any) => {
    console.log();
    return {
        giftCards: state.giftCardFetchData
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateTitlePage: (title: string) => {

            dispatch(updateTitle(title));
        },
        fetch: (options?: RequestOptions) => {
            dispatch(fetch(options));
        },
        deleted:(id: string,cb: any)=>{
            dispatch(deleted(id,cb));
        }
    };
};



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GiftCardContainer);
