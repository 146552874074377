import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { FormShopAddress } from "../../../redux/interfaces";
import ButtonWithLoading from "../../ButtonWithLoading";
import Input from "../../FormsElements/Input";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

interface Props {
  handleAddAddress: (address: FormShopAddress) => void;
}
function NewShopFormAddress(props: Props) {
  const classes = useStyles();
  const { register, errors, formState, getValues } = useForm<FormShopAddress>({
    mode: "onChange"
  });

  const { handleAddAddress } = props;

  const addAddress = () => {
    const street = getValues("street");
    const number = getValues("number");
    const detail = getValues("detail");
    const city = getValues("city");
    const state = getValues("state");
    const address = {
      street,
      number,
      detail,
      city,
      state
    }
    handleAddAddress(address);
  }

  return (
    <form className={classes.form} noValidate>
      <Input
        name="street"
        label="Calle"
        inputRef={register({ required: true })}
        error={errors.street ? true : false}
        helperText={errors.street && errors.street.message}
        fullWidth={true}
      />
      <Input
        name="number"
        type="number"
        label="Número"
        inputRef={register()}
        error={errors.number ? true : false}
        helperText={errors.number && errors.number.message}
        fullWidth={true}
      />
      <Input
        name="detail"
        label="Detalle"
        inputRef={register()}
        error={errors.detail ? true : false}
        helperText={errors.detail && errors.detail.message}
        fullWidth={true}
      />
      <Input
        name="city"
        label="Ciudad"
        inputRef={register({ required: true })}
        error={errors.city ? true : false}
        helperText={errors.city && errors.city.message}
        fullWidth={true}
      />
      <Input
        name="state"
        label="Provincia"
        inputRef={register({ required: true })}
        error={errors.state ? true : false}
        helperText={errors.state && errors.state.message}
        fullWidth={true}
      />

      <ButtonWithLoading
        color="primary"
        disabled={false || !formState.isValid}
        loading={false}
        type="button"
        text={"Agregar Dirección"}
        onClick={addAddress}
      />
    </form>
  );
}

export default NewShopFormAddress;
