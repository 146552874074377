import React,{ReactNode} from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import CssBaseline from "@material-ui/core/CssBaseline";
import DrawerLeftAppBar from "../../components/Menu/DrawerLeftAppBar";



import { connect } from "react-redux";
import { UserState } from "../../redux/reducers/user.reducer";
import { NavigationState } from "../../redux/reducers/navigation.reducer";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(3)
      },
      padding: theme.spacing(1)
    },
  }),
);

interface Props {
    children: ReactNode;
  userData: UserState;
  navigation: NavigationState;
}
function MainContainer(props: Props) {
  const classes = useStyles();


  return (
    <div className={classes.root}>
      <CssBaseline />
      <DrawerLeftAppBar userData={props.userData} navigation={props.navigation}/>
      <main className={classes.content}>
        <div className={classes.toolbar} />
            {props.children}
        <div/>
      </main>
      
    </div>
  );
}



const mapStateToProps = (state: any) => {
  return {
    userData: state.userData,
    navigation:state.navigation  
  };
};
export default connect(
  mapStateToProps
)(MainContainer);