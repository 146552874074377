import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import BoomerCard from "../../components/BoomerCard";
import { connect } from "react-redux";
import { VoucherState } from "../../redux/reducers/voucher.reducer";
import { handleStepBack, changeVoucher } from "../../redux/actions/voucher.action";
import ConfirmDialog from "../../components/ConfirmDialog";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { voucherStatusOptions } from "../../constants/status";
const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  }
}));

interface Props {
  voucherData: VoucherState;
  handleStepBack: () => void;
  changeVoucher: (id: string, code: string) => void;
}

function ReviewContainer(props: Props) {
  const classes = useStyles();
  const { voucherData, handleStepBack, changeVoucher } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogAgree = () => {
    changeVoucher(voucherData.voucher._id, voucherData.voucher.code);
    setOpenDialog(false);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const voucherStatus = (status: string) => {
    const option = voucherStatusOptions.filter(options => options.value === status);
    return option[0].label;
  };
  return (
    <React.Fragment>
      

        <Snackbar open={voucherData.voucher.status !== "create"} autoHideDuration={2000}  >
          <Alert severity="error">
          El voucher se encuentra {voucherStatus(voucherData.voucher.status)}
          </Alert>
        </Snackbar>
      
      <Grid container spacing={2}>
        <Grid item container direction="column" xs={12} sm={12}>
          <BoomerCard voucherData={voucherData} />
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        <ConfirmDialog open={openDialog} title="Confirmar Canje" message="¿Esta seguro que desea realizar el canje?" handleAgree={handleDialogAgree} handleClose={handleClose} />
        <Button onClick={handleStepBack} className={classes.button}>
          Volver
          </Button>
        {voucherData.voucher.status === "create" &&
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleOpenDialog}
          >
            Confirmar
          </Button>
        }
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => {

  return {
    voucherData: state.voucherData
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    changeVoucher: (id: string, code: string) => {
      dispatch(changeVoucher(id, code));
    },
    handleStepBack: () => {
      dispatch(handleStepBack());
    }
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewContainer);

