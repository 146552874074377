import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { Controller } from "react-hook-form";
import ComboSelect from "./ComboSelect";
import { FormHelperText } from "@material-ui/core";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 150
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    chips: {
      display: "flex",
      flexWrap: "wrap"
    },
    chip: {
      margin: 2
    }
  })
);
interface Props {
  select: {
    name: string;
    label: string;

    multiple?: boolean;
    defaultOption?: boolean;
    fullWidth?: boolean;
    options?: {
      value: string;
      label: string;
      default?: boolean;
    }[];
    callback?: (value: string | number) => void;
    defaultValue?: string;
  };
  controller?: {
    control: any;
    errors: any;
    setValue: any;
    setError: any;
    clearErrors: any;
    errorsRule: {
      type: string;
      message: string;
    };
  };
  onHandleChange?: (key: any, value: any) => void;
}

export default function FormSelect(props: Props) {
  const classes = useStyles();
  const { select, controller, onHandleChange } = props;

  const handleChange = onHandleChange
    ? onHandleChange
    : (key: any, value: any) => {
        if (controller) {
          controller.setValue(select.name, value);
          if (value.length === 0) {
            controller.setError(select.name, controller.errorsRule);
          } else {
            controller.clearErrors(select.name);
          }
        }
      };
  return (
    <FormControl
      className={classes.formControl}
      fullWidth={
        typeof select.fullWidth !== "undefined" ? select.fullWidth : false
      }
    >
      <InputLabel id="controller-select-{select.name}">
        {select.label}
      </InputLabel>
      {!controller && (
        <ComboSelect select={select} onHandleChange={handleChange} />
      )}
      {controller && (
        <Controller
          render={(props: any) => (
            <>
              <ComboSelect select={select} onHandleChange={handleChange} />
              <FormHelperText style={{ color: "#ff0303" }}>
                {controller.errors[select.name] &&
                  controller.errorsRule &&
                  controller.errorsRule.message}
              </FormHelperText>
            </>
          )}
          name={select.name}
          control={controller.control}
          defaultValue=""
        />
      )}
    </FormControl>
  );
}
