import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Copyright from "../components/Copyrigth";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";

import { getAccount,postMarketPlaceAuthorization } from "../redux/actions/shops.action";
import { passwordModify } from "../redux/actions/user.action";
import { updateTitle } from "../redux/actions/navigation.action";
import { ShopsState } from "../redux/reducers/shops.reducer";
import { UserState } from "../redux/reducers/user.reducer";
import AccountData from "../components/Account/AccountData";
import AccountMercadoPago from "../components/Account/AccountMercadoPago";
import RecoveryPasswordForm from "../components/Forms/RecoveryPasswordForm";

const pageTitle = "Mi Perfil";


const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",

    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  body: {
    marginTop: theme.spacing(3)
  },
  title:{
    color: "#f65521",
    fontWeight: "bold"
  },
  titleModificarPassword:{
    color: "#f65521",
    fontWeight: "bold",
    marginTop:10
  }
}));
interface PasswordRecoveryData {
  password: string;
  passwordConfirm: string;
};
interface VoucherChangeProps {
  shopsData: ShopsState;
  userData: UserState;
  updateTitlePage: (title: string) => void;
  getAccount: () => void;
  postMarketPlaceAuthorization: () => void;
  passwordModify: (password: string) => void;
}

function AccountContainer(props: VoucherChangeProps) {
  const { shopsData,userData,updateTitlePage, getAccount,postMarketPlaceAuthorization,passwordModify } = props;
  const account = shopsData.ShopAccount;

  const [openDialog, setOpenDialog] = React.useState(false);

  const classes = useStyles();
  //getAccount();
  useEffect(
    () => {

      updateTitlePage(pageTitle);
      if(!shopsData.isFetch && !shopsData.isFetching){
        getAccount();
      }
      
    });

  useEffect(
    () => {
      if(shopsData.ShopAccount.endPointMPAuth){
        window.open(`${shopsData.ShopAccount.endPointMPAuth}`,"_self");
      }      
    }, [shopsData.ShopAccount.endPointMPAuth]);
    
  const handleDialogAgree = () => {
    postMarketPlaceAuthorization();
    setOpenDialog(false);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  

  return (

    <React.Fragment>
      <CssBaseline />

      <main className={classes.layout}>
        <Paper className={classes.paper}>
        <Grid item container direction="column" xs={12} sm={12}>
          
          <Typography className={classes.title} component="h1" variant="h4" align="center">
            Mi Perfil
          </Typography>

            <AccountData account={account}/>
            <Typography className={classes.titleModificarPassword} component="h1" variant="h5" align="center">
                Modificar contraseña
            </Typography> 
            {
              userData.user.firstLogin &&
              <Typography variant="body1" gutterBottom className={classes.body}> 
                Debe modificar su contraseña en su primer login para continuar
              </Typography>   
            }
            
            <RecoveryPasswordForm onSubmitForm={(data: PasswordRecoveryData) => {passwordModify(data.password);}} isFetching={userData.isFetching} buttonText="Modificar Contraseña" >
                
            </RecoveryPasswordForm>
            <AccountMercadoPago account={account} handleOpenDialog={handleOpenDialog} openDialog={openDialog} handleDialogAgree={handleDialogAgree} handleClose={handleClose} />
          
          </Grid>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>

  );
}


const mapStateToProps = (state: any) => {

  return {
    shopsData: state.shopsData,
    userData:state.userData
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTitlePage: (title: string) => {

      dispatch(updateTitle(title));
    },
    getAccount: () => {

      dispatch(getAccount());
    },
    postMarketPlaceAuthorization: () => {

        dispatch(postMarketPlaceAuthorization());
    },
    passwordModify:(password: string)=>{
      dispatch(passwordModify(password));
    }
    
  };
};




export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountContainer);
