import { IResponsePagination ,RequestOptions} from "../redux/interfaces";
import * as axios from "axios";
import instance from "../axios";


export const allAdmin = (options?: RequestOptions): Promise<axios.AxiosResponse<IResponsePagination>> => {
    const params = Object.entries(options || {}).map(e => e.join("=")).join("&");
    return instance.get("giftCards/admin?" +params);
};

export const deleted  = (id: string): Promise<axios.AxiosResponse> => {
    return instance.delete("giftCards/"+id);
};

export const create = (data: any): Promise<axios.AxiosResponse> => {
    return instance.post("giftCards", data);
};

export const fetchById = (id: string): Promise<axios.AxiosResponse> => {
    return instance.get("giftCards/admin/" +id);
};
export const fetchAll = (): Promise<axios.AxiosResponse> => {
    return instance.get("giftCards/");
};