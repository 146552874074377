import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dropzone from "react-dropzone";
import ImageCrop from "./ImageCrop";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  dragdrop: {
    border: "2px solid #d4d4d4",
    color: "#5f5f5f",
    cursor: "pointer",
    height: "70px",
    marginTop: "30px",
    lineHeight: "58px",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: "5px",
    backgroundColor: "#f4f4f4",
    width: "100%",
    fontSize: "16px",
    maxWidth: "500px",
    minWidth: "360px"
  },
  dragEnter: {
    backgroundColor: "#9dbf9d"
  },
  detail: {
    marginTop: "-25px",
    fontSize: "12px",
    color: "#5f5f5f"
  }
}));

interface Props {
  aspectRatio: number;
  setCroppedURLs: (cropperdURLs: any[]) => void;
  imagesSize: number;
  croppedURLs: any[];
  sizeLimit: number;
  maxFiles: number;
}

export default function ImageCropContainer(props: Props) {
  const {
    aspectRatio,
    setCroppedURLs,
    imagesSize,
    croppedURLs,
    maxFiles,
    sizeLimit
  } = props;
  const classes = useStyles();
  const [dragEnter, setDragEnter] = useState(false);
  const [files, setFiles] = useState();
  const numberAllowed = maxFiles - imagesSize;

  const onSelectFile = (selectedFiles: any) => {
    setDragEnter(false);
    const newFiles = [...(files || []), ...(selectedFiles || [])];
    setFiles(newFiles.splice(0, numberAllowed));
  };

  const handleCroppedUrlChanged = (index: number) => {
    return (blobUrl: any) => {
      const urls: any = [...croppedURLs];
      urls[index] = blobUrl;
      setCroppedURLs(urls);
    };
  };

  const handleRemoveFile = (index: number) => {
    return () => {
      setFiles(files.filter((f: any, i: number) => i !== index));
      setCroppedURLs(croppedURLs.filter((u: any, i: number) => i !== index));
    };
  };

  const kBytes = sizeLimit / 1024;
  const mBytes = sizeLimit / (1024 * 1024);

  return (
    <div>
      <div>
        <Dropzone
          onDrop={acceptedFiles => onSelectFile(acceptedFiles)}
          accept="image/*"
          multiple={true}
          maxSize={sizeLimit}
          onDragEnter={() => setDragEnter(true)}
          onDragLeave={() => setDragEnter(false)}
        >
          {({ getRootProps, getInputProps }) => (
            <section style={{ textAlign: "center" }}>
              <div {...getRootProps()} style={{outline: 0}}>
                <input {...getInputProps()} />
                <div
                  className={`${classes.dragdrop} ${
                    dragEnter ? classes.dragEnter : ""
                  }`}
                >
                  Arrastra las imágenes o selecciona desde <b>aquí</b>
                </div>
                <div className={classes.detail}>
                  (Máximo {numberAllowed} imágenes de {kBytes  < 1024? kBytes + "KB" : mBytes + "MB"} cada una)
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
      {files &&
        files.length > 0 &&
        files.map((file: any, index: number) => {
          return (
            <div style={{ borderBottom: "1px solid #cccccc" }}>
              <ImageCrop
                key={index}
                file={file}
                aspectRatio={aspectRatio}
                onCroppedUrlChanged={handleCroppedUrlChanged(index)}
                index={index}
                croppedURLs={croppedURLs}
              ></ImageCrop>
              <Button
                type="button"
                size="small"
                color="primary"
                onClick={handleRemoveFile(index)}
              >
                Eliminar
              </Button>
            </div>
          );
        })}
    </div>
  );
}
