import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Copyright from "../../components/Copyrigth";
import CancelIcon from "@material-ui/icons/Cancel";

import { connect } from "react-redux";
import { updateTitle } from "../../redux/actions/navigation.action";
import BoomerButton from "../../components/BoomerButton";

const pageTitle = "Ha ocurrido un error";

const useStyles = makeStyles(theme => ({
  layout: {
    width: "auto",

    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  body: {
    marginTop: theme.spacing(3)
  },
  icon: {
    fontSize: "150px",
    color: "#e40404",
    margin: "0 auto",
    display: "flex",
    justifyContent: "flex-end"
  },
  title: {
    color: "#f65521",
    fontWeight: "bold"
  },
  buttonContainer: {
    margin: "60px"
  }
}));
interface Props {
  updateTitlePage: (title: string) => void;
}
function ThankyoupageError(props: Props) {
  const classes = useStyles();
  const { updateTitlePage } = props;
  React.useEffect(() => {
    updateTitlePage(pageTitle);
  });
  const goToApp = () => {
    window.location.href = "boomer:///";
  };
  const isInSale = window.location.href.indexOf("/sales/") > -1;
  return (
    <React.Fragment>
      <CssBaseline />

      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography
            className={classes.title}
            component="h1"
            variant="h4"
            align="center"
          >
            Ha ocurrido un error
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.body}>
            Ha ocurrido un error, por favor intentá realizar la operación
            nuevamente
          </Typography>
          <div>
            <CancelIcon className={classes.icon}></CancelIcon>
          </div>
          {isInSale && (
            <div className={classes.buttonContainer}>
              <BoomerButton text="Volver a Boomer" onClick={goToApp} />
            </div>
          )}
          <div className={classes.buttons}></div>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTitlePage: (title: string) => {
      dispatch(updateTitle(title));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ThankyoupageError);
