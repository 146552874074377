import React from "react";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import StoreIcon from "@material-ui/icons/Store";
import PaymentIcon from "@material-ui/icons/Payment";
import CategoryIcon from "@material-ui/icons/Category";
import ListIcon from "@material-ui/icons/List";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
export default function AdminMenuItems() {
  return (
    <>
      <ListItem button component={Link} to="/sales">
        <ListItemIcon>
          <ListIcon></ListIcon>
        </ListItemIcon>
        <ListItemText primary="Listado de ventas" />
      </ListItem>
      <ListItem button component={Link} to="/shops">
        <ListItemIcon>
          <StoreIcon></StoreIcon>
        </ListItemIcon>
        <ListItemText primary="Listado de comercios" />
      </ListItem>
      <ListItem button component={Link} to="/gift-types">
        <ListItemIcon>
          <PaymentIcon></PaymentIcon>
        </ListItemIcon>
        <ListItemText primary="Listado de Tipos de Boomer" />
      </ListItem>
      <ListItem button component={Link} to="/categories">
        <ListItemIcon>
          <CategoryIcon></CategoryIcon>
        </ListItemIcon>
        <ListItemText primary="Listado de Categorias" />
      </ListItem>
      <ListItem button component={Link} to="/gift-cards">
        <ListItemIcon>
          <CardGiftcardIcon></CardGiftcardIcon>
        </ListItemIcon>
        <ListItemText primary="Listado de Boomer" />
      </ListItem>
      <ListItem button component={Link} to="/logout">
        <ListItemIcon>
          <ExitToAppIcon></ExitToAppIcon>
        </ListItemIcon>
        <ListItemText primary="Salir" />
      </ListItem>
    </>
  );
}
