import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Route,Switch,Redirect} from "react-router-dom";
import ShopRouteContainer from "./ShopRouteContainer";
import AdminRouteContainer from "./AdminRouteContainer";
import AdminShopRouteContainer from "./AdminShopRouteContainer";

import SnackbarCustom from "../../components/SnackbarCustom";
import { connect } from "react-redux";
import { UserState } from "../../redux/reducers/user.reducer";

import ShareContainer from "../ShareContainer/ShareContainer";
import PrivacyContainer from "../PrivacyContainer/PrivacyContainer";
import SupportContainer from "../SupportContainer/SupportContainer";
import ThankyoupageOK from "../Thankyoupage/ThankyoupageOK";
import ThankyoupageError from "../Thankyoupage/ThankyoupageError";
import Login from "../LoginContainer/LoginContainer";
import PasswordRecovery from "../PasswordRecoveryContainer";
import LoadMasterData from "../LoadMasterData";
import DownloadContainer from "../DownloadContainer/DownloadContainer";
import RegisterValidate from "../RegisterValidate/RegisterValidate";
import UserPasswordRecovery from "../UserPasswordRecovery/UserPasswordRecovery";
import VoucherContainer from "../VoucherContainer/VoucherContainer";
interface MenuScreenProps {
    userData: UserState;
}
interface Props {
    Component: React.FC<RouteComponentProps>;
    path: string;
	exact?: boolean;
    // any other props that come into the component
}
function RoutesContainer(props: MenuScreenProps) {
    
    const stateUser = props.userData;
    
    const LoginRoute = ({ Component, ...rest }: Props) => (
        <Route
            {...rest} render={props => (
            !stateUser.isLogged ? (
                <Component {...props}/>
            ) :
            (
                <Redirect to={{
                    pathname: "/main"
                }} />
            )
        )} />
    );
    
  return (
    <>  
        <LoadMasterData />
        <Switch>
            <Redirect from="/" to="/login" exact />
            <LoginRoute Component={Login} path={"/login"} exact />
            <LoginRoute Component={PasswordRecovery} path={"/password-recovery"} exact />
            <Route component={ThankyoupageOK} path={"/sales/thankyoupage"} exact />
            <Route component={ThankyoupageError} path={"/sales/thankyoupage-error"} exact />
            <Route component={ShareContainer} path={"/share"} exact />
            <Route component={DownloadContainer} path={"/descarga"} exact />
            <Route component={VoucherContainer} path={"/voucher"} exact />
            <Route component={SupportContainer} path={"/support"} exact />
            <Route component={PrivacyContainer} path={"/privacy"} exact />
            <Route component={RegisterValidate} path={"/registro/validar"} exact />
            <Route component={UserPasswordRecovery} path={"/recuperar-password"} exact />
            {
                stateUser.user.rol==="shop" &&
                <ShopRouteContainer />
            }
            {
                ["admin"].indexOf(stateUser.user.rol)!==-1  &&
                <AdminRouteContainer />
            }
            {
                ["adminShop"].indexOf(stateUser.user.rol)!==-1  &&
                <AdminShopRouteContainer />
            }
            
              {/* <LoginRoute Component={PasswordRecovery} path={"/password-recovery"} exact />
              <Route component={ThankyoupageOK} path={"/sales/thankyoupage"} exact />
              <Route component={ThankyoupageError} path={"/sales/thankyoupage-error"} exact />
              <Route component={ShareContainer} path={"/share"} exact />
              <Route component={DownloadContainer} path={"/descarga"} exact />
              <Route component={SupportContainer} path={"/support"} exact />
              <Route component={PrivacyContainer} path={"/privacy"} exact />
            <Main >
                <Switch>
                    <PrivateRoute exact Component={Sales} path={"/sales"}  />
                    <PrivateRoute Component={VoucherChange} path={"/change"} exact />
                    <Route component={ThankyoupageOK} path={"/thankyoupage"} exact />
                    <Route component={ThankyoupageError} path={"/thankyoupage-error"} exact/>
                    <AccountRoute Component={AccountContainer} path={"/account"} exact />
                    <LogOutRoute path={"/logout"} exact />
                    <Route component={TermsContainer} path={"/terms-conditions"} exact />
                    <Route component={Page404}   />
                </Switch>
            </Main> */}

            
        </Switch>
        
        <SnackbarCustom />
    </>
    
  );
}
const mapStateToProps = (state: any) => {
    return {
        userData: state.userData
    };
};
export default connect(
    mapStateToProps
)(RoutesContainer);
