 import {
    FETCHING_GIFT_TYPE,
    FETCHING_GIFT_TYPE_SUCCESS,
    FETCHING_GIFT_TYPE_FAIL,
    FETCH_GIFT_TYPE_LISTADO_INITIAL,
    FETCH_GIFT_TYPE_LISTADO,
    FETCH_GIFT_TYPE_LISTADO_SUCCESS,
    FETCH_GIFT_TYPE_LISTADO_FAIL,
    POST_GIFT_TYPE,
    POST_GIFT_TYPE_SUCCESS,
    POST_GIFT_TYPE_FAIL,
    DELETE_GIFT_TYPE_LISTADO,
    DELETE_GIFT_TYPE_LISTADO_SUCCESS,
    DELETE_GIFT_TYPE_LISTADO_FAIL
} from "../constants";
import { RequestOptions } from "../interfaces";
import * as giftTypeService from "../../services/giftType.service";

export const fetchGiftType = (options?: RequestOptions) => {

    return (dispatch: any) => {

        dispatch({ type: FETCHING_GIFT_TYPE });
        
        giftTypeService
            .allWithoutPagination(options)
            .then(response => {

                if (response.data.length>0) {
                    
                    dispatch({ type: FETCHING_GIFT_TYPE_SUCCESS, data: response.data });
                    
                } else {
                    dispatch({ type: FETCHING_GIFT_TYPE_FAIL, data: {} });
                }

            })
            .catch(error => {
                console.log("fetchGiftType Error");
                dispatch({ type: FETCHING_GIFT_TYPE_FAIL, error });
            });
             

    };
};
/**
 * GET - All
 */
export const fetch = (options?: RequestOptions) => {
    return (dispatch: any) => {
  
      dispatch({ type: FETCH_GIFT_TYPE_LISTADO });
      giftTypeService
      .allAdmin(options)
        .then((response) => {
          if ( response.data.data.length>=0) {
                      
            dispatch({ type: FETCH_GIFT_TYPE_LISTADO_SUCCESS, data: response.data });
            
        } else {
            dispatch({ type: FETCH_GIFT_TYPE_LISTADO_FAIL, data: response.data });
        }
  
  
        }).catch(error => {
  
          dispatch({ type: FETCH_GIFT_TYPE_LISTADO_FAIL, error });
        });
  
    };
  };
/**
 * POST - Crear giftType
 */
export const post = (shopData: any,cb: any) => {
  return (dispatch: any) => {

    dispatch({ type: POST_GIFT_TYPE });
    giftTypeService
    .create(shopData)
      .then((shop) => {
        if (shop.data.error) {
          dispatch({ type: POST_GIFT_TYPE_FAIL, data: shop.data });
        } else {
          dispatch({ type: POST_GIFT_TYPE_SUCCESS, data: shop.data });
          dispatch({ type: FETCH_GIFT_TYPE_LISTADO_INITIAL });
          cb();
        }

      }).catch(error => {

        dispatch({ type: POST_GIFT_TYPE_FAIL, error });
      });

  };
};
  /**
 * DELETE - ELIMINAR GIFTTYPE
 */
export const deleted = (id: string,cb: any) => {
    return (dispatch: any) => {
  
      dispatch({ type: DELETE_GIFT_TYPE_LISTADO });
      giftTypeService
      .deleted(id)
        .then((shop) => {
          if (shop.data.error) {
            dispatch({ type: DELETE_GIFT_TYPE_LISTADO_FAIL, data: shop.data });
          } else {
            dispatch({ type: DELETE_GIFT_TYPE_LISTADO_SUCCESS, data: shop.data });
            cb();
          }
  
        }).catch(error => {
  
          dispatch({ type: DELETE_GIFT_TYPE_LISTADO_FAIL, error });
        });
  
    };
  };