/* eslint-disable quotes */
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { Column } from "./interfaces";
import {Link} from "react-router-dom";


interface Props {
    column: Column;
    row: any;
}


export default function ListadoBodyCell(props: Props) {
    
    const { column, row } = props;

    
    const getComponent = (column: Column,row: any)=>{
        const value = row[column.name];

        switch(column.type){
            case "actions":
                if(column.actions)return Object.keys(column.actions).map((key: any)=>{
                    if(column.actions){
                        const col = column.actions[key];
                        if(key==="edit"){
                            return <Link to={col.link+row["_id"] || ""}>Editar</Link>;
                        }
                        if(key==="delete"){
                            return "Eliminar";
                        }
                        return "";
                    }
                    return "";
                });
                break;
                
            case "image":
                return <img alt="Imagen" style={{width:'40px'}} src={value}></img>;
            default:
                return column.format && typeof value === "number" ? column.format(value) :value;
        }
    };
    return (
        <TableCell key={column.name} align={column.align}>
            {getComponent(column,row)}
        </TableCell>               
    );
}