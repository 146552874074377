/* eslint-disable @typescript-eslint/interface-name-prefix */
interface IConfig {
  URL: string;
  apiEndpoint: string;
  environment: string;
}

const localserver: IConfig = {
  URL: "http://localhost:3001",
  apiEndpoint: "http://localhost:3000",
  environment: "localhost"
};

const production: IConfig = {
  URL: "https://www.boomer.com.ar",
  apiEndpoint: "https://api.boomer.com.ar",
  environment: "production"
};

const development: IConfig = {
  URL: "https://test.panel.boomer.com.ar",
  apiEndpoint: "https://test.boomer.com.ar",
  environment: "development"
};

export default function getConfig() {
  const config: any = {
    production,
    localserver,
    development
  };
  const server = process.env.REACT_APP_ENVIRONMENT || "localserver";
  //const server = "localserver";
  return config[server];
}
