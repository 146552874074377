import React from "react";
import ShopFormAddresses from "./ShopFormAddresses";
import ListAddresses from "./ListAddresses";
import { FormShopAddress } from "../../../redux/interfaces";

interface Prop {
  handleAddAddress: (address: FormShopAddress) => void;
  rows: FormShopAddress[];
  handleRemoveAddress: (index: number) => void;
}
function ShopAddresses(props: Prop) {
  const { handleAddAddress, rows, handleRemoveAddress } = props;

  return (
    <>
      <ShopFormAddresses handleAddAddress={handleAddAddress} />
      {rows && (
        <ListAddresses rows={rows} handleRemoveAddress={handleRemoveAddress} />
      )}
    </>
  );
}

export default ShopAddresses;
