import {
    POST_SALES,
    POST_SALES_SUCCESS,
    POST_SALES_FAIL
} from "../constants";
import { RequestOptions } from "../interfaces";
import * as voucherService from "../../services/voucher.service";



/*export const fetchSales = (options?: RequestOptions) => {
    return (dispatch: any) => {
        
        dispatch({ type: FETCHING_SALES });
        
            
        
        voucherService
            .all(options)
            .then(response => {
                
                if ( response.data.data.length>=0) {
                    
                    dispatch({ type: FETCHING_SALES_SUCCESS, data: response.data });
                    
                } else {
                    dispatch({ type: FETCHING_SALES_FAIL, data: response.data });
                }

            })
            .catch(error => {
                dispatch({ type: FETCHING_SALES_FAIL, error });
            });
             

    };
};*/
export const fetchSales = async (setFetching: any,options?: RequestOptions) => {
    setFetching(true);
    return await voucherService.all(options);
};
/**
 * POST - Crear giftType
 */
export const post = (saleData: any,cb: any) => {
    return (dispatch: any) => {
  
      dispatch({ type: POST_SALES });
      voucherService
      .create(saleData)
        .then((sale) => {
          if (sale.data.error) {
            dispatch({ type: POST_SALES_FAIL, data: sale.data });
          } else {
            dispatch({ type: POST_SALES_SUCCESS, data: sale.data });
            cb();
          }
  
        }).catch(error => {
  
          dispatch({ type: POST_SALES_FAIL, error });
        });
  
    };
  };