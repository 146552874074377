import { ICategories,IResponsePagination ,RequestOptions} from "../redux/interfaces";
import * as axios from "axios";
import instance from "../axios";

export const all = (options?: RequestOptions): Promise<axios.AxiosResponse<IResponsePagination>> => {
    const params = Object.entries(options || {}).map(e => e.join("=")).join("&");
    
    return instance.get("categories?" +params);
};
export const allWithoutPagination = (options?: RequestOptions): Promise<axios.AxiosResponse<ICategories[]>> => {
    const params = Object.entries(options || {}).map(e => e.join("=")).join("&");
    
    return instance.get("giftTypes?" +params);
};
export const allAdmin = (options?: RequestOptions): Promise<axios.AxiosResponse<IResponsePagination>> => {
    const params = Object.entries(options || {}).map(e => e.join("=")).join("&");
    return instance.get("giftTypes/admin?" +params);
};

export const deleted  = (id: string): Promise<axios.AxiosResponse> => {
    return instance.delete("giftTypes/"+id);
};

export const create = (data: any): Promise<axios.AxiosResponse> => {
    return instance.post("giftTypes", data);
};

export const fetchById = (id: string): Promise<axios.AxiosResponse> => {
    return instance.get("giftTypes/admin/" +id);
};