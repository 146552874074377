/* eslint-disable @typescript-eslint/camelcase */
export const isAlphaNumericOnly = (input: string) => {
  const letterNumberRegex = /^[0-9a-zA-Z]+$/;
  if (input.match(letterNumberRegex)) {
    return true;
  }
  return false;
};
export const isGoodPassword = () => {
  return {
    value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,12}/,
    message:
      "La contraseña debe contener al menos 1 numero, 1 minuscula, 1 mayuscula y 6 caracteres"
  };
};

/** Formato numero telefono */
export const phoneValidate = () => {
  

  return {
    value: /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/,
    message: "El formato del telefono es incorrecto"
  };
};
/** Formato email */
export const emailValidate = () => {
  return {
    value: /^([\w-.]+@([\w-]+.)+[\w-]{2,4})?$/,
    message: "El formato del email es incorrecto"
  };
};

export function validatePassword(password: string) {
  const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,12}/;
  return re.test(password);
}