import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Route, Switch, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { UserState } from "../../redux/reducers/user.reducer";
import ShopsContainers from "../Admin/ShopABMContainer/ShopsContainers";
import NewShopContainer from "../Admin/ShopABMContainer/NewShopContainer";

import Main from "./MainContainer";
import Logout from "../LoginContainer/LogoutContainer";
import Page404 from "../Page404";
import EditShopContainers from "../Admin/ShopABMContainer/EditShopContainers";
interface MenuScreenProps {
  userData: UserState;
}
interface Props {
  Component: React.FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
  // any other props that come into the component
}
function AdminShopRouteContainer(props: MenuScreenProps) {
  const stateUser = props.userData;

  const PrivateRoute = ({ Component, ...rest }: Props) => (
    <Route
      {...rest}
      render={props =>
        !stateUser.isLogged ? (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        ) : !stateUser.user.firstLogin || true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/account",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );

  const LogOutRoute = ({ ...rest }: { path: string; exact?: boolean }) => (
    <Route
      {...rest}
      render={props =>
        !stateUser.isLogged ? (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        ) : (
          <Route component={Logout} path={"/logout"} exact={true} />
        )
      }
    />
  );

  return (
    <>
      <Main>
        <Switch>

          <PrivateRoute exact Component={ShopsContainers} path={"/main"} />
          <PrivateRoute exact Component={ShopsContainers} path={"/shops"} />
          <PrivateRoute exact Component={NewShopContainer} path={"/shop"} />
          <PrivateRoute
            exact
            Component={EditShopContainers}
            path={"/shop/:id"}
          />
          
          <LogOutRoute path={"/logout"} exact />
          <Route component={Page404} />
        </Switch>
      </Main>
    </>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userData: state.userData
  };
};
export default connect(mapStateToProps)(AdminShopRouteContainer);
