import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {IShopAccount } from "../../redux/interfaces";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import ConfirmDialog from "../ConfirmDialog";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",

    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  body: {
    marginTop: theme.spacing(3)
  },
  title:{
    color: "#f65521",
    fontWeight: "bold"
  }
}));

interface Props {
    account: IShopAccount;
    openDialog: boolean;
    handleOpenDialog: () => void;
    handleDialogAgree: () => void;
    handleClose: () => void;

}

function AccountMercadoPago(props: Props) {
  const { account,openDialog,handleOpenDialog,handleDialogAgree,handleClose } = props;

  const classes = useStyles();
  //getAccount();
  

  return (

    <React.Fragment>
            {!account.mercadopago && 
            <>
                <Typography variant="body1" gutterBottom className={classes.body}> 
                    Debe asociar  "Boomer" a su cuenta de Mercado Pago para poder comenzar a operar con nuestra plataforma 
                </Typography>
                <Typography variant="body1" gutterBottom className={classes.body} style={{fontWeight:"bold"}}> 
                    Se ya acepto a Boomer como plataforma por favor salga e ingrese nuevamente
                </Typography>
                <div className={classes.buttons}>
                
                    <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleOpenDialog}
                    >
                    Asociar Boomer a Mercado Pago
                    </Button>
                    
                    <ConfirmDialog open={openDialog} title="Asociar Boomer a Mercado Pago" message="¿Esta seguro que deseas asociar Boomer a tu cuenta de Mercado Pago?" handleAgree={handleDialogAgree} handleClose={handleClose} />
                        
                </div>
            </>
            }
    </React.Fragment>

  );
}


export default AccountMercadoPago;
