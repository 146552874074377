import { IVoucher, IResponsePagination, RequestOptions } from "../redux/interfaces";
import * as axios from "axios";
import instance from "../axios";

export const all = (options?: RequestOptions): Promise<axios.AxiosResponse<IResponsePagination>> => {
    //const params = new URLSearchParams(options || {}).toString();
    const params = Object.entries(options || {}).map(e => e.join("=")).join("&");
    
    return instance.get("sales/shop/?" +params);
};
export const ByCode = (code: string): Promise<axios.AxiosResponse<IVoucher>> => {

    return instance.get(`sales/voucher/${code}`);
};
export const change = (id: string, code: string): Promise<axios.AxiosResponse<IVoucher>> => {
    return instance.put("sales/voucher/", { id, code});
};
export const create = (data: any): Promise<axios.AxiosResponse> => {
    return instance.post("salesbyadmin", data);
};