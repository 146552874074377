import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: "150px",
    height: "90px",
    margin: "5px",
    WebkitBoxShadow: "3px 3px 5px 0px rgba(0,0,0,0.28)",
    MozBoxShadow: "3px 3px 5px 0px rgba(0,0,0,0.28)",
    boxShadow: "3px 3px 5px 0px rgba(0,0,0,0.28)"
  },
  removeButton: {
    position: "absolute",
    right: "0",
    top: "0",
    backgroundColor: "white",
    borderRadius: "50%",
    border: "1px solid red",
    height: "24px",
    width: "24px",
    cursor: "pointer"
  },
  removeIcon: {
    color: "gray",
    fontSize: "22px"
  }
}));

interface Props {
  images: any[];
  onSetImagesSort: (images: any[]) => void;
  ratio: number;
}

export default function ImageSorting(props: Props) {
  const classes = useStyles();
  const { images, onSetImagesSort, ratio } = props;

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const newArray = [...arrayMove(images, oldIndex, newIndex)];
    onSetImagesSort(newArray);
  };

  const removeImage = (image: any) => {
    return () => {
      const newImages = images.filter((img: any) => {
        return img.url !== image.url;
      });
      onSetImagesSort(newImages);
    };
  };

  const SortableItem = SortableElement(({ image, index }: any) => (
    <div style={{ position: "relative", display: "inline-block" }}>
      <div className={classes.removeButton} onClick={removeImage(image)}>
        <DeleteIcon className={classes.removeIcon} />
      </div>
      <img
        className={classes.image}
        alt="Crop"
        src={image.url}
        style={{ width: Math.trunc(90 * ratio) + "px" }}
      />
    </div>
  ));

  const SortableList = SortableContainer(({ images }: any) => {
    return (
      <ul>
        {images.map((image: any, index: number) => (
          <SortableItem key={`item-${index}`} index={index} image={image} />
        ))}
      </ul>
    );
  });

  return (
    <SortableList
      images={images}
      onSortEnd={onSortEnd}
      axis="xy"
      distance={2}
    />
  );
}
