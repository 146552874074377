import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Copyright from "../../components/Copyrigth";
import { connect } from "react-redux";
import { updateTitle } from "../../redux/actions/navigation.action";
import { isMobile } from "../../utils/utils";

const pageTitle = "Ayuda y soporte";

const useStyles = makeStyles(theme => ({
  layout: {
    width: "auto",

    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  title: {
    textAlign: "center",
    fontSize: "16pt",
    fontWeight: "bold",
    marginBottom: "30px"
  },
  p: {
    textAlign: "justify",
    fontSize: "9pt",
    marginBottom: "10px",
    lineHeight: "10pt"
  },
  logo: {
    width: "200px",
    marginLeft: "auto",
    marginRight: "auto",
    display: "inherit",
    marginBottom: "20px"
  },
  question: {
    fontSize: "11pt",
    marginBottom: "10px",
    fontWeight: "bold"
  },
  response: {
    fontSize: "10pt",
    marginBottom: "20px"
  },
  link: {
    cursor: "pointer",
    color: "#ff3452"
  }
}));

interface Props {
  updateTitlePage: (title: string) => void;
}
function PrivacyContainer(props: Props) {
  const classes = useStyles();
  const { updateTitlePage } = props;
  React.useEffect(() => {
    updateTitlePage(pageTitle);
  });
  const isMobileDevice = isMobile();

  const goToPlaystore = () => {
    if (isMobileDevice) {
      window.location.href = "market://details?id=com.boomer.boomervoucher";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.boomer.boomervoucher";
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <div className={classes.title}>Ayuda y soporte</div>
          <img src="/ICONO.png" className={classes.logo} alt="" />
          <div className={classes.question}>¿Qué es un Boomer?</div>
          <div className={classes.response}>
            Un Boomer es un regalo en forma de voucher electrónico. El mismo
            tiene un valor específico, el cual puede ser canjeado en un comercio
            adherido previamente seleccionado por el comprador.
          </div>
          <div className={classes.question}>¿Cómo funciona?</div>
          <div className={classes.response}>
            Es muy simple, bajá nuestra app desde{" "}
            <span className={classes.link} onClick={goToPlaystore}>
              Play Store
            </span>{" "}
            o <span className={classes.link}>App Store</span>, selecciona un comercio, el valor del voucher y a
            quien se lo queres regalar.
          </div>
          <div className={classes.question}>¿Cómo se envía?</div>
          <div className={classes.response}>
            El Boomer le llega al beneficiario a traves de Whatsapp. El
            beneficiario podrá ver quien hizo el regalo en caso de que la compra
            no sea anónima.
          </div>
          <div className={classes.question}>¿Cómo pago? ¿Es seguro?</div>
          <div className={classes.response}>
            El pago se realiza a traves de MercadoPago, es totalmente seguro.
            <br />
            Boomer no almacena ninguna informacion de sus tarjetas.
          </div>
          <div className={classes.question}>
            Soy beneficiario y ya tengo mi voucher, ¿cómo lo utilizo?
          </div>
          <div className={classes.response}>
            Una vez que recibiste tu Boomer, tenes 20 dias para canjearlo. El
            canje se realiza en forma <b>presencial</b> en la o las direcciones
            que aparecen en el voucher. Se puede canjear mostrando el código de
            barras del voucher o con el código de canje.
          </div>
          <div className={classes.question}>
            Soy un comercio y quiero estar en Boomer
          </div>
          <div className={classes.response}>
            Si sos un comercio y queres aparecer en la Aplicacion de Boomer,
            enviá un mail a info@boomer.com.ar y nos pondremos en contacto a la
            brevedad.
          </div>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTitlePage: (title: string) => {
      dispatch(updateTitle(title));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(PrivacyContainer);
