import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {IShopAccount } from "../../redux/interfaces";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",

    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  body: {
    marginTop: theme.spacing(3)
  },
  title:{
    color: "#f65521",
    fontWeight: "bold"
  }
}));

interface Props {
    account: IShopAccount;
}

function AccountData(props: Props) {
  const { account } = props;

  const classes = useStyles();
  //getAccount();
  

  return (

    <React.Fragment>
            <Typography className={classes.title} component="h1" variant="h5" align="center">
                Mis datos
            </Typography>   
            <TextField
                id="standard-read-only-input"
                label="Nombre"
                value={account.name}
                InputProps={{
                    readOnly: true,
                    }}
                />
            <br/>
            <TextField
            id="standard-read-only-input"
            label="Teléfono"
            value={account.phone}
            InputProps={{
                readOnly: true,
                }}
            />
    </React.Fragment>

  );
}


export default AccountData;
