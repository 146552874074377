import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box"; 
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import getConfig from "../../config";

import InputEmail from "./InputEmailContainer";
import InputCode from "./InputCodeContainer";
import InputNewPassword from "./InputNewPasswordContainer";
import Copyright from "../../components/Copyrigth";

import { inicializeFetch } from "../../redux/actions/userRecoveryPassword.action";
import { connect } from "react-redux";
import { PasswordRecoveryUserState } from "../../redux/reducers/userRecoveryPassword.reducer";






const config = getConfig();

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(" + config.URL + "/images/CARD_BACKGROUND.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  }
}));
interface PasswordRecoveryData {
  email: string;
};

interface Props {

  userData: PasswordRecoveryUserState;
  
}

function PasswordRecovery(props: Props) { 
  const classes = useStyles();
  const {  userData } = props; 

  const steps = [
    {path:"/login",label:"Ingresar Email"}, 
    {label:"Ingresar Código"},
    {label:"Modificar Contraseña"}
  ];

  function getStepContent(step: number) {

    switch (step) {
      case 0:
        return <InputEmail />;
      case 1:
        return <InputCode />;
      case 2:
        return <InputNewPassword />;
      default:
      //throw new Error('Unknown step');
    }
  }
  return (

    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Recuperar Contraseña
          </Typography>
          <Stepper activeStep={userData.activeStep} >
            {steps.map((step) => (

              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {getStepContent(userData.activeStep || 0)}
          </React.Fragment>
            
          <Box mt={5}>
              <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>

  );
}




const mapStateToProps = (state: any) => {
    return {
      userData: state.userDataPasswordRecovery
    };
  };
  
const mapDispatchToProps = (dispatch: any) => {
    return {
        
        inicializeFetch: () => {
          return dispatch(inicializeFetch());
        }
    };
};



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordRecovery);
