// SHOPS
export const FETCHING_SHOPS = "FETCHING_SHOPS";
export const FETCHING_SHOPS_SUCCESS = "FETCHING_SHOPS_SUCCESS";
export const FETCHING_SHOPS_FAIL = "FETCHING_SHOPS_FAIL";
export const CLEAR_SHOPS = "CLEAR_SHOPS";

export const POST_SHOP = "POST_SHOP";
export const POST_SHOP_SUCCESS = "POST_SHOP_SUCCESS";
export const POST_SHOP_FAIL = "POST_SHOP_FAIL";

export const DELETE_SHOP = "DELETE_SHOP";
export const DELETE_SHOP_SUCCESS = "DELETE_SHOP_SUCCESS";
export const DELETE_SHOP_FAIL = "DELETE_SHOP_FAIL";

export const PAUSED_SHOP = "PAUSED_SHOP";
export const PAUSED_SHOP_SUCCESS = "PAUSED_SHOP_SUCCESS";
export const PAUSED_SHOP_FAIL = "PAUSED_SHOP_FAIL";

// USER
export const INICIALIZE = "INICIALIZE";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const LOGOUT_USER = "LOGOUT_USER";
export const ACCEPT_TERMS = "ACCEPT_TERMS";
export const ACCEPT_TERMS_SUCCESS = "ACCEPT_TERMS_SUCCESS";
export const ACCEPT_TERMS_FAIL = "ACCEPT_TERMS_FAIL";
export const PASSWORD_MODIFY = "PASSWORD_MODIFY";
export const PASSWORD_MODIFY_SUCCESS = "PASSWORD_MODIFY_SUCCESS";
export const PASSWORD_MODIFY_FAIL = "PASSWORD_MODIFY_FAIL";
export const PASSWORD_RECOVERY = "PASSWORD_RECOVERY";
export const PASSWORD_RECOVERY_SUCCESS = "PASSWORD_RECOVERY_SUCCESS";
export const PASSWORD_RECOVERY_FAIL = "PASSWORD_RECOVERY_FAIL";
export const PASSWORD_RECOVERY_INPUT_CODE = "PASSWORD_RECOVERY_INPUT_CODE";
export const PASSWORD_RECOVERY_INPUT_CODE_SUCCESS = "PASSWORD_RECOVERY_INPUT_CODE_SUCCESS";
export const PASSWORD_RECOVERY_INPUT_CODE_FAIL = "PASSWORD_RECOVERY_INPUT_CODE_FAIL";
export const PASSWORD_RECOVERY_NEW_PASSWORD = "PASSWORD_RECOVERY_NEW_PASSWORD";
export const PASSWORD_RECOVERY_NEW_PASSWORD_SUCCESS = "PASSWORD_RECOVERY_NEW_PASSWORD_SUCCESS";
export const PASSWORD_RECOVERY_NEW_PASSWORD_FAIL = "PASSWORD_RECOVERY_NEW_PASSWORD_FAIL";

// CATEGORIES
export const FETCHING_CATEGORIES = "FETCHING_CATEGORIES";
export const FETCHING_CATEGORIES_SUCCESS = "FETCHING_CATEGORIES_SUCCESS";
export const FETCHING_CATEGORIES_FAIL = "FETCHING_CATEGORIES_FAIL";
export const FETCHING_CATEGORIES_LISTADO_INITIAL = "FETCHING_CATEGORIES_LISTADO_INITIAL";
export const FETCHING_CATEGORIES_LISTADO = "FETCHING_CATEGORIES_LISTADO";
export const FETCHING_CATEGORIES_LISTADO_SUCCESS = "FETCHING_CATEGORIES_LISTADO_SUCCESS";
export const FETCHING_CATEGORIES_LISTADO_FAIL = "FETCHING_CATEGORIES_LISTADO_FAIL";
export const POST_CATEGORY = "POST_CATEGORY";
export const POST_CATEGORY_SUCCESS = "POST_CATEGORY_SUCCESS";
export const POST_CATEGORY_FAIL = "POST_CATEGORY_FAIL";
export const DELETE_CATEGORY_LISTADO = "DELETE_CATEGORY_LISTADO";
export const DELETE_CATEGORY_LISTADO_SUCCESS = "DELETE_CATEGORY_LISTADO_SUCCESS";
export const DELETE_CATEGORY_LISTADO_FAIL = "DELETE_CATEGORY_LISTADO_FAIL";

// GIFT TYPE
export const FETCHING_GIFT_TYPE = "FETCHING_GIFT_TYPE";
export const FETCHING_GIFT_TYPE_SUCCESS = "FETCHING_GIFT_TYPE_SUCCESS";
export const FETCHING_GIFT_TYPE_FAIL = "FETCHING_GIFT_TYPE_FAIL";
export const FETCH_GIFT_TYPE_LISTADO_INITIAL = "FETCH_GIFT_TYPE_LISTADO_INITIAL";
export const FETCH_GIFT_TYPE_LISTADO = "FETCH_GIFT_TYPE_LISTADO";
export const FETCH_GIFT_TYPE_LISTADO_SUCCESS = "FETCH_GIFT_TYPE_LISTADO_SUCCESS";
export const FETCH_GIFT_TYPE_LISTADO_FAIL = "FETCH_GIFT_TYPE_LISTADO_FAIL";
export const POST_GIFT_TYPE = "POST_GIFT_TYPE";
export const POST_GIFT_TYPE_SUCCESS = "POST_GIFT_TYPE_SUCCESS";
export const POST_GIFT_TYPE_FAIL = "POST_GIFT_TYPE_FAIL";
export const DELETE_GIFT_TYPE_LISTADO = "DELETE_GIFT_TYPE_LISTADO";
export const DELETE_GIFT_TYPE_LISTADO_SUCCESS = "DELETE_GIFT_TYPE_LISTADO_SUCCESS";
export const DELETE_GIFT_TYPE_LISTADO_FAIL = "DELETE_GIFT_TYPE_LISTADO_FAIL";

// GIFT CARD
export const FETCHING_GIFT_CARD = "FETCHING_GIFT_CARD";
export const FETCHING_GIFT_CARD_SUCCESS = "FETCHING_GIFT_CARD_SUCCESS";
export const FETCHING_GIFT_CARD_FAIL = "FETCHING_GIFT_CARD_FAIL";
export const FETCH_GIFT_CARD_LISTADO_INITIAL = "FETCH_GIFT_CARD_LISTADO_INITIAL";
export const FETCH_GIFT_CARD_LISTADO = "FETCH_GIFT_CARD_LISTADO";
export const FETCH_GIFT_CARD_LISTADO_SUCCESS = "FETCH_GIFT_CARD_LISTADO_SUCCESS";
export const FETCH_GIFT_CARD_LISTADO_FAIL = "FETCH_GIFT_CARD_LISTADO_FAIL";
export const POST_GIFT_CARD = "POST_GIFT_CARD";
export const POST_GIFT_CARD_SUCCESS = "POST_GIFT_CARD_SUCCESS";
export const POST_GIFT_CARD_FAIL = "POST_GIFT_CARD_FAIL";
export const DELETE_GIFT_CARD_LISTADO = "DELETE_GIFT_CARD_LISTADO";
export const DELETE_GIFT_CARD_LISTADO_SUCCESS = "DELETE_GIFT_CARD_LISTADO_SUCCESS";
export const DELETE_GIFT_CARD_LISTADO_FAIL = "DELETE_GIFT_CARD_LISTADO_FAIL";

// SHOPS FILTERS
export const FETCH_SHOPS = "FETCH_SHOPS";
export const FETCH_SHOPS_SUCCESS = "FETCH_SHOPS_SUCCESS";
export const FETCH_SHOPS_FAIL = "FETCH_SHOPS_FAIL";
export const FETCH_SHOPS_LISTADO = "FETCH_SHOPS_LISTADO";
export const FETCH_SHOPS_LISTADO_SUCCESS = "FETCH_SHOPS_LISTADO_SUCCESS";
export const FETCH_SHOPS_LISTADO_FAIL = "FETCH_SHOPS_LISTADO_FAIL";
export const FETCH_SHOPS_MARKETPLACE_AUTH = "FETCH_SHOPS_MARKETPLACE_AUTH";
export const FETCH_SHOPS_MARKETPLACE_AUTH_SUCCESS = "FETCH_SHOPS_MARKETPLACE_AUTH_SUCCESS";
export const FETCH_SHOPS_MARKETPLACE_AUTH_FAIL = "FETCH_SHOPS_MARKETPLACE_AUTH_FAIL";
// STATIC CONTENT
export const FETCHING_HELP_HTML = "FETCHING_HELP_HTML";
export const FETCHING_HELP_HTML_SUCCESS = "FETCHING_HELP_HTML_SUCCESS";
export const FETCHING_HELP_HTML_FAIL = "FETCHING_HELP_HTML_FAIL";

//NAVIGATION
export const UPDATE_TITLE = "UPDATE_TITLE";
export const USER_ALERT_CLOSE = "USER_ALERT_CLOSE";
//VOUCHER
export const FETCHING_VOUCHER = "FETCHING_VOUCHER";
export const FETCHING_VOUCHER_SUCCESS = "FETCHING_VOUCHER_SUCCESS";
export const FETCHING_VOUCHER_FAIL = "FETCHING_VOUCHER_FAIL";

export const UPDATE_VOUCHER = "UPDATE_VOUCHER";
export const UPDATE_VOUCHER_SUCCESS = "UPDATE_VOUCHER_SUCCESS";
export const UPDATE_VOUCHER_FAIL = "UPDATE_VOUCHER_FAIL";
export const HANDLE_STEP_VOUCHER_BACK = "HANDLE_STEP_VOUCHER_BACK";

//SALES
export const FETCHING_SALES = "FETCHING_SALES";
export const FETCHING_SALES_SUCCESS = "FETCHING_SALES_SUCCESS";
export const FETCHING_SALES_FAIL = "FETCHING_SALES_FAIL";
export const POST_SALES = "POST_SALES";
export const POST_SALES_SUCCESS = "POST_SALES_SUCCESS";
export const POST_SALES_FAIL = "POST_SALES_FAIL";