import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ImageCropContainer from "./ImageCropContainer";
import CloseIcon from "@material-ui/icons/Close";
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSave: (croppedUrls: any[]) => void;
  imagesSize: number;
  sizeLimit: number;
  maxFiles: number;
  ratio: number;
  title: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImageDialog(props: Props) {
  const {
    open,
    handleClose,
    handleSave,
    imagesSize,
    sizeLimit,
    maxFiles,
    ratio,
    title
  } = props;
  const [croppedURLs, setCroppedURLs] = useState<any>([]);
  const classes = useStyles();

  useEffect(() => {
    if (open === true) {
      setCroppedURLs([]);
    }
  }, [open]);

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => handleSave(croppedURLs)}
              disabled={
                !croppedURLs || (croppedURLs && croppedURLs.length === 0)
              }
            >
              Agregar
            </Button>
          </Toolbar>
        </AppBar>
        <ImageCropContainer
          aspectRatio={ratio}
          setCroppedURLs={setCroppedURLs}
          croppedURLs={croppedURLs}
          imagesSize={imagesSize}
          sizeLimit={sizeLimit}
          maxFiles={maxFiles}

        />
      </Dialog>
    </div>
  );
}
