import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Copyright from "../../components/Copyrigth";
import { connect } from "react-redux";
import { updateTitle } from "../../redux/actions/navigation.action";
import {
  setNewPassword
} from "../../services/profile.service";
import BoomerButton from "../../components/BoomerButton";
import { TextField } from "@material-ui/core";
import { validatePassword } from "../../components/utils/formValidation";

const pageTitle = "Validar cuenta";

const useStyles = makeStyles(theme => ({
  layout: {
    width: "auto",

    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  titleContainer: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "30px"
  },
  logo: {
    width: "20%"
  },
  buttonContainer: {
    margin: "60px"
  },
  formContainer: {
    margin: "30px"
  },
  recoveryTitle: {
    fontSize: "18px",
    textAlign: "center"
  },
  error: {
    fontSize: "12px",
    color: "red"
  },
  errorResponse: {
    color: "red"
  },
  messageOk: {
    fontSize: "20px",
    textAlign: "center",
    color: "#69944f"
  },
  detail: {
    textAlign: "center",
    marginTop: "30px"
  }
}));

interface Props {
  updateTitlePage: (title: string) => void;
}

function UserPasswordRecovery(props: Props) {
  const classes = useStyles();
  const { updateTitlePage } = props;
  const [errorMessage, setErrorMessage] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");
  const isValidPassword = validatePassword(password);
  const isValidPasswordConfirm = password === passwordConfirm;

  const [passwordGenerated, setPasswordGenerated] = React.useState(false);

  React.useEffect(() => {
    updateTitlePage(pageTitle);
  // eslint-disable-next-line
  }, []);

  const sendNewPassword = () => {
    const params: any = new URLSearchParams(window.location.search);
    const id = params.get("id");
    const hash = params.get("hash");
    setErrorMessage("");
    setNewPassword({ id, hash, password })
      .then(response => {
        if (response.data && response.data.generated) {
          setPasswordGenerated(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch(e => {
        console.log(e);
        setErrorMessage(
          "Ocurrio un error al intentar generar la contraseña, vuelva a intentar más tarde."
        );
      });
  };

  const goToApp = () => {
    window.location.href = "boomer:///";
  };

  const buttonDisabled =
    !password ||
    !passwordConfirm ||
    !isValidPassword ||
    !isValidPasswordConfirm;

  return (
    <React.Fragment>
      <CssBaseline />

      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <div className={classes.titleContainer}>
            <img src="/ICONO.png" className={classes.logo} alt="" />
          </div>
          {!passwordGenerated && (
            <div className={classes.recoveryTitle}>Recuperar Contraseña</div>
          )}
          {!passwordGenerated && (
            <div className={classes.formContainer}>
              <div>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Contraseña"
                  type="password"
                  onChange={(e: any) => setPassword(e.target.value)}
                />
                {password && !isValidPassword && (
                  <div className={classes.error}>
                    La contraseña debe contener al menos 1 numero, 1 minuscula,
                    1 mayuscula y 6 caracteres
                  </div>
                )}
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Repetir Contraseña"
                  type="password"
                  onChange={(e: any) => setPasswordConfirm(e.target.value)}
                />
                {passwordConfirm && !isValidPasswordConfirm && (
                  <div className={classes.error}>La contraseña no coincide</div>
                )}
              </div>

              <div className={classes.buttonContainer}>
                <BoomerButton
                  text="Enviar"
                  onClick={sendNewPassword}
                  disabled={buttonDisabled}
                />
              </div>
              <div className={classes.errorResponse}>{errorMessage}</div>
            </div>
          )}

          {passwordGenerated && (
            <div>
              <div className={classes.messageOk}>
                La contraseña fue cambiada correctamente.
              </div>
              <div className={classes.detail}>
                Ya podes ingresar con la nueva contraseña a la aplicación.
              </div>
              <div className={classes.buttonContainer}>
                <BoomerButton text="Volver a Boomer" onClick={goToApp} />
              </div>
            </div>
          )}
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTitlePage: (title: string) => {
      dispatch(updateTitle(title));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(UserPasswordRecovery);
