import React from "react";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import getConfig from "../../config";

import LoginForm from "../../components/Forms/LoginForm";
import Copyright from "../../components/Copyrigth";

import { loginUser } from "../../redux/actions/user.action";
import { connect } from "react-redux";
import { UserState } from "../../redux/reducers/user.reducer";

import { updateTitle } from "../../redux/actions/navigation.action";
import {FormLoginData} from "../../redux/interfaces";


const pageTitle = "Ingreso";

const config = getConfig();

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(" + config.URL + "/images/CARD_BACKGROUND.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  }
}));

interface LoginScreenProps {
    loginUser: (email: string,password: string) => void;
    userData: UserState;
    updateTitlePage: (title: string) => void;
}

function LoginContainer(props: LoginScreenProps) { 
  const classes = useStyles();
  const { loginUser,userData,updateTitlePage } = props; 
  React.useEffect(() => {
    updateTitlePage(pageTitle);
  });
  return (
    <>
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Ingreso Comercios
          </Typography>
            <LoginForm onSubmitForm={(data: FormLoginData) => loginUser(data.email, data.password)} isFetching={userData.isFetching} passwordField={true}>
              <Grid item xs>
                <Link to={"/password-recovery"} style={{ textDecoration: "none", display: "flex-start" }}>
                  ¿Olvidó su contraseña?
                  </Link>
              </Grid>
            </LoginForm>
            <Box mt={5}>
              <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
    </>
  );
}




const mapStateToProps = (state: any) => {
    return {
      userData: state.userData
    };
  };
  
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateTitlePage: (title: string) => {

          dispatch(updateTitle(title));
        },
        loginUser: (email: string,password: string) => {
            return dispatch(loginUser(email, password));
        }
    };
};



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginContainer);
