import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Copyright from "../../components/Copyrigth";
import { connect } from "react-redux";
import { updateTitle } from "../../redux/actions/navigation.action";
import { validateAccount } from "../../services/profile.service";
import BoomerButton from "../../components/BoomerButton";

const pageTitle = "Validar cuenta";

const useStyles = makeStyles(theme => ({
  layout: {
    width: "auto",

    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  titleContainer: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "30px"
  },
  logo: {
    width: "30%"
  },
  title: {
    fontSize: "20px",
    marginTop: "30px",
    marginBottom: "50px"
  },
  boomerText: {
    color: "#ff3453",
    fontWeight: "bold"
  },
  icon: {
    fontSize: "40px",
    color: "#008000",
    margin: "0 auto",
    display: "flex",
    justifyContent: "flex-end"
  },
  validationOk: {
    textAlign: "center",
    fontSize: "20px",
    color: "#69944f"
  },
  validationError: {
    textAlign: "center",
    fontSize: "18px",
    color: "red"
  },
  buttonContainer: {
    margin: "60px"
  },
  centered: {
    textAlign: "center",
    marginTop: "10px",
    color: "#444444"
  }
}));

interface Props {
  updateTitlePage: (title: string) => void;
}

function RegisterValidate(props: Props) {
  const classes = useStyles();
  const { updateTitlePage } = props;
  const [validation, setValidation] = React.useState();
  React.useEffect(() => {
    updateTitlePage(pageTitle);
    const params: any = new URLSearchParams(window.location.search);
    const id = params.get("id");
    const hash = params.get("hash");
    validateAccount({ id, hash })
      .then(response => {
        setValidation(response.data);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  // eslint-disable-next-line
  }, []);

  const goToApp = () => {
    window.location.href = "boomer:///";
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <div className={classes.titleContainer}>
            <img src="/ICONO.png" className={classes.logo} alt="" />
          </div>
          {validation && validation.validated && <div>
            <div className={classes.validationOk}>
              {validation && validation.message ? validation.message : ""}
            </div>
          </div>}

          {validation && !validation.validated && <div>
            <div className={classes.validationError}>
              {validation && validation.message ? validation.message : ""}
            </div>
            <div className={classes.centered}>Vuelva a intentar más tarde</div>
          </div>}

          <div className={classes.buttonContainer}>
            <BoomerButton text="Volver a Boomer" onClick={goToApp} />
          </div>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTitlePage: (title: string) => {
      dispatch(updateTitle(title));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(RegisterValidate);
