import { IShopAccount,FormShop,ShopMarketPlaceAuthorization,IResponsePagination ,RequestOptions, Shop} from "../redux/interfaces";
import * as axios from "axios";
import instance from "../axios";

export const account = (): Promise<axios.AxiosResponse<IShopAccount>> => {
    return instance.get<IShopAccount>("shops/account");
};
export const marketPlaceAuthorization = (): Promise<axios.AxiosResponse<ShopMarketPlaceAuthorization>> => {
    return instance.post<ShopMarketPlaceAuthorization>("shops/marketplace-authorization/");
};
export const all = (options?: RequestOptions): Promise<axios.AxiosResponse<IResponsePagination>> => {
    const params = Object.entries(options || {}).map(e => e.join("=")).join("&");
    return instance.get("shops/admin?" +params);
};

export const fetchShopById = (id: string): Promise<axios.AxiosResponse<Shop>> => {
    return instance.get("shops/admin/" +id);
};


export const create = (data: FormShop): Promise<axios.AxiosResponse<FormShop>> => {
    return instance.post<FormShop>("shops", data);
};

export const deleted  = (id: string): Promise<axios.AxiosResponse<FormShop>> => {
    return instance.delete<FormShop>("shops/"+id);
};
export const paused  = (id: string,pausedShop: boolean): Promise<axios.AxiosResponse<FormShop>> => {
    return instance.put<FormShop>("shops/paused/"+id+"?paused="+!pausedShop);
};