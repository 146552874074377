import {
  FETCH_SHOPS,
  FETCH_SHOPS_SUCCESS,
  FETCH_SHOPS_FAIL,
  FETCH_SHOPS_MARKETPLACE_AUTH,
  FETCH_SHOPS_MARKETPLACE_AUTH_SUCCESS,
  FETCH_SHOPS_MARKETPLACE_AUTH_FAIL,
  FETCH_SHOPS_LISTADO,
  FETCH_SHOPS_LISTADO_SUCCESS,
  FETCH_SHOPS_LISTADO_FAIL,
  POST_SHOP,
  POST_SHOP_SUCCESS,
  POST_SHOP_FAIL,
  DELETE_SHOP,
  DELETE_SHOP_SUCCESS,
  DELETE_SHOP_FAIL
} from "../constants";
//import { IUser } from "../interfaces";
import { RequestOptions,FormShop } from "../interfaces";
import {account,marketPlaceAuthorization,all,create,deleted,paused} from "../../services/shops.service";

/**
 * GET - All
 */
export const fetchShops = (options?: RequestOptions) => {
  return (dispatch: any) => {

    dispatch({ type: FETCH_SHOPS_LISTADO });

    all(options)
      .then((response) => {
        if ( response.data.data.length>=0) {
                    
          dispatch({ type: FETCH_SHOPS_LISTADO_SUCCESS, data: response.data });
          
      } else {
          dispatch({ type: FETCH_SHOPS_LISTADO_FAIL, data: response.data });
      }


      }).catch(error => {

        dispatch({ type: FETCH_SHOPS_LISTADO_FAIL, error });
      });

  };
};
/**
 * GET - Account info
 */
export const getAccount = () => {
  return (dispatch: any) => {

    dispatch({ type: FETCH_SHOPS });

    account()
      .then((shop) => {
        if (shop.data.error) {
          dispatch({ type: FETCH_SHOPS_FAIL, data: shop.data });
        } else {
          dispatch({ type: FETCH_SHOPS_SUCCESS, data: shop.data });
        }

      }).catch(error => {

        dispatch({ type: FETCH_SHOPS_FAIL, error });
      });

  };
};
/**
 * GET - Mercado Pago url marketplace
 */
export const postMarketPlaceAuthorization = () => {
  return (dispatch: any) => {

    dispatch({ type: FETCH_SHOPS_MARKETPLACE_AUTH });

    marketPlaceAuthorization()
      .then((shop) => {
        if (shop.data.error) {
          dispatch({ type: FETCH_SHOPS_MARKETPLACE_AUTH_FAIL, data: shop.data });
        } else {
          dispatch({ type: FETCH_SHOPS_MARKETPLACE_AUTH_SUCCESS, data: shop.data });
        }

      }).catch(error => {

        dispatch({ type: FETCH_SHOPS_MARKETPLACE_AUTH_FAIL, error });
      });

  };
};
/**
 * POST - Crear shop
 */
export const postShop = (shopData: FormShop,cb: any) => {
  return (dispatch: any) => {

    dispatch({ type: POST_SHOP });

    create(shopData)
      .then((shop) => {
        if (shop.data.error) {
          dispatch({ type: POST_SHOP_FAIL, data: shop.data });
        } else {
          dispatch({ type: POST_SHOP_SUCCESS, data: shop.data });
          dispatch(fetchShops());
          cb();
        }

      }).catch(error => {

        dispatch({ type: POST_SHOP_FAIL, error });
      });

  };
};
/**
 * DELETE - ELIMINAR shop
 */
export const deleteShop = (id: string,cb: any) => {
  return (dispatch: any) => {

    dispatch({ type: DELETE_SHOP });

    deleted(id)
      .then((shop) => {
        if (shop.data.error) {
          dispatch({ type: DELETE_SHOP_FAIL, data: shop.data });
        } else {
          dispatch({ type: DELETE_SHOP_SUCCESS, data: shop.data });
          cb();
        }

      }).catch(error => {

        dispatch({ type: DELETE_SHOP_FAIL, error });
      });

  };
};
/**
 * DELETE - ELIMINAR shop
 */
export const pausedShop = (id: string,pausedShop: boolean,cb: any) => {
  return (dispatch: any) => {

    dispatch({ type: DELETE_SHOP });

    paused(id,pausedShop)
      .then((shop) => {
        if (shop.data.error) {
          dispatch({ type: DELETE_SHOP_FAIL, data: shop.data });
        } else {
          console.log("Success pause");
          dispatch({ type: DELETE_SHOP_SUCCESS, data: shop.data });
          cb();
        }

      }).catch(error => {

        dispatch({ type: DELETE_SHOP_FAIL, error });
      });

  };
};