import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";


import { connect } from "react-redux";
import { VoucherState } from "../../redux/reducers/voucher.reducer";
import { fetchVoucher } from "../../redux/actions/voucher.action";

const useStyles = makeStyles((theme) => ({

  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

interface FormVoucher{
  code: string;
}
interface VoucherProps {
  fetchVoucher: (code: string) => void;
  voucherData: VoucherState;
}
const VoucherInputContainer = (props: VoucherProps) => {
  const { register, handleSubmit,errors  } = useForm<FormVoucher>({ mode: "onChange" });
  const classes = useStyles();   
  const {fetchVoucher} = props;
  const onVoucherSubmit = (data: {code: string}) => {

    fetchVoucher(data.code);
        
  };
  return (
    <React.Fragment>
      
      <form  onSubmit={handleSubmit(onVoucherSubmit)} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField 
            required 
            id="code" 
            label="Código de Boomer" 
            name="code"
            fullWidth autoComplete="cc-code" 
            inputRef={register({  required:true })}
            error={errors.code?true:false}
            helperText={errors.code && errors.code.message} />
          </Grid>
          
        </Grid>
        <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
              >
                  Continuar
            </Button>
        </div>
        </form>
    </React.Fragment>
  );
};


const mapStateToProps = (state: any) => {
    
  return {
   voucherData: state.voucherData
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
      fetchVoucher: (code: string)=>{

          dispatch(fetchVoucher(code));
      }
  };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VoucherInputContainer);