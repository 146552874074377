import { logoutUser } from "../../redux/actions/user.action";
import { connect } from "react-redux";
import { UserState } from "../../redux/reducers/user.reducer";






interface LoginScreenProps {
    logoutUser: () => void;
  userData: UserState;
}

function LogoutContainer(props: LoginScreenProps) { 
  props.logoutUser(); 
  return null;
}


const mapStateToProps = (state: any) => {
    return {
      userData: state.userData
    };
  };
  
const mapDispatchToProps = (dispatch: any) => {
    return {
      logoutUser: () => {
            return dispatch(logoutUser());
        }
    };
};



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LogoutContainer);
