import {
  FETCH_SHOPS,
  FETCH_SHOPS_SUCCESS,
  FETCH_SHOPS_FAIL,
  FETCH_SHOPS_MARKETPLACE_AUTH,
  FETCH_SHOPS_MARKETPLACE_AUTH_SUCCESS,
  FETCH_SHOPS_MARKETPLACE_AUTH_FAIL,
  LOGOUT_USER
  } from "../constants";
  import { IAction, IShopAccount } from "../interfaces";
  
  export interface ShopsState {
    ShopAccount: IShopAccount;
    isFetching: boolean;
    error: boolean;
    isFetch: boolean;
  }
  
  const emptyUser: IShopAccount = {
    name:"",
    phone:"",
    frontImage:"",
    mercadopago:true,
    endPointMPAuth:""
  };
  
const initialState: ShopsState = {
    ShopAccount: emptyUser,
    isFetching: false,
    error: false,
    isFetch:false
  };
  
  export default function shopsReducer(
    state = initialState,
    action: IAction
  ): ShopsState {
    switch (action.type) {
      case LOGOUT_USER:
        return initialState;  
      case FETCH_SHOPS:

        return {
          ...state,
          isFetching: true,
          error: false,
          isFetch: false
        };
      case FETCH_SHOPS_SUCCESS:

        return {
          ...state,
          ShopAccount:{
            name:action.data.name,
            phone:action.data.phone,
            frontImage:action.data.frontImage,
            mercadopago:action.data.mercadopago
          },
          isFetching: false,
          error: false,
          isFetch: true
        };
      case FETCH_SHOPS_FAIL:

        return {
          ...state,
          ShopAccount: emptyUser,
          isFetching: false,
          error: true,
          isFetch:false
        };
      case FETCH_SHOPS_MARKETPLACE_AUTH:

        return {
          ...state,
          isFetching: true,
          error: false,
          isFetch: false
        };
      case FETCH_SHOPS_MARKETPLACE_AUTH_SUCCESS:

        return {
          ...state,
          ShopAccount:{
            ...state.ShopAccount,
            endPointMPAuth:action.data.callbackUrl
          },
          isFetching: false,
          error: false,
          isFetch: true
        };
      case FETCH_SHOPS_MARKETPLACE_AUTH_FAIL:

        return {
          ...state,
          ShopAccount: emptyUser,
          isFetching: false,
          error: true,
          isFetch:false
        };
      default:
        return state;
    }
  }
  