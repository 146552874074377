import axios from "axios";
import getConfig from "./config";
const instance = axios.create({
  baseURL: getConfig().apiEndpoint + "/"
});

instance.interceptors.request.use(
  function(config) {
    const reduxState = JSON.parse(localStorage.getItem("reduxState") || "{}");
    // Do something before request is sent
    if (
      "userData" in reduxState &&
      "user" in reduxState.userData &&
      "token" in reduxState.userData.user
    ) {
      const token = reduxState.userData.user.token;

      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
export default instance;
