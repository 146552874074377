import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Route, Switch, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { UserState } from "../../redux/reducers/user.reducer";
import Sales from "../SalesContainer/SalesContainer";
import ShopsContainers from "../Admin/ShopABMContainer/ShopsContainers";
import NewShopContainer from "../Admin/ShopABMContainer/NewShopContainer";

import Main from "./MainContainer";
import Logout from "../LoginContainer/LogoutContainer";
import Page404 from "../Page404";
import EditShopContainers from "../Admin/ShopABMContainer/EditShopContainers";

import GiftTypeContainer from "../Admin/GiftTypeABMContainer/GiftTypeContainer";
import NewGiftTypeContainer from "../Admin/GiftTypeABMContainer/NewGiftTypeContainer";
import EditGiftTypeContainer from "../Admin/GiftTypeABMContainer/EditGiftTypeContainer";
import CategoriesContainer from "../Admin/CategoryABMContainer/CategoriesContainer";
import NewCategoryContainer from "../Admin/CategoryABMContainer/NewCategoryContainer";
import EditCategoryContainer from "../Admin/CategoryABMContainer/EditCategoryContainer";
import GiftCardContainer from "../Admin/GiftCardABMContainer/GiftCardContainer";
import NewGiftCardContainer from "../Admin/GiftCardABMContainer/NewGiftCardContainer";
import EditGiftCardContainer from "../Admin/GiftCardABMContainer/EditGiftCardContainer";

import NewSalesContainer from "../SalesContainer/NewSalesContainer";

interface MenuScreenProps {
  userData: UserState;
}
interface Props {
  Component: React.FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
  // any other props that come into the component
}
function AdminRouteContainer(props: MenuScreenProps) {
  const stateUser = props.userData;

  const PrivateRoute = ({ Component, ...rest }: Props) => (
    <Route
      {...rest}
      render={props =>
        !stateUser.isLogged ? (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        ) : !stateUser.user.firstLogin || true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/account",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );

  const LogOutRoute = ({ ...rest }: { path: string; exact?: boolean }) => (
    <Route
      {...rest}
      render={props =>
        !stateUser.isLogged ? (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        ) : (
          <Route component={Logout} path={"/logout"} exact={true} />
        )
      }
    />
  );

  return (
    <>
      <Main>
        <Switch>
          <PrivateRoute exact Component={Sales} path={"/sales"} />
          <PrivateRoute exact Component={Sales} path={"/main"} />
          <PrivateRoute exact Component={ShopsContainers} path={"/shops"} />
          <PrivateRoute exact Component={NewShopContainer} path={"/shop"} />
          <PrivateRoute
            exact
            Component={EditShopContainers}
            path={"/shop/:id"}
          />
          <PrivateRoute
            exact
            Component={NewSalesContainer}
            path={"/boomer-generate/:id"}
          />
          <PrivateRoute
            exact
            Component={GiftTypeContainer}
            path={"/gift-types"}
          />
          <PrivateRoute
            exact
            Component={NewGiftTypeContainer}
            path={"/gift-type"}
          />
          <PrivateRoute
            exact
            Component={EditGiftTypeContainer}
            path={"/gift-type/:id"}
          />
          <PrivateRoute
            exact
            Component={GiftCardContainer}
            path={"/gift-cards"}
          />
          <PrivateRoute
            exact
            Component={NewGiftCardContainer}
            path={"/gift-card"}
          />
          <PrivateRoute
            exact
            Component={EditGiftCardContainer}
            path={"/gift-card/:id"}
          />
          <PrivateRoute
            exact
            Component={CategoriesContainer}
            path={"/categories"}
          />
          <PrivateRoute
            exact
            Component={NewCategoryContainer}
            path={"/category"}
          />
          <PrivateRoute
            exact
            Component={EditCategoryContainer}
            path={"/category/:id"}
          />
          <LogOutRoute path={"/logout"} exact />
          <Route component={Page404} />
        </Switch>
      </Main>
    </>
  );
}
const mapStateToProps = (state: any) => {
  return {
    userData: state.userData
  };
};
export default connect(mapStateToProps)(AdminRouteContainer);
