import {
  UPDATE_TITLE,
  FETCHING_VOUCHER_FAIL,
  UPDATE_VOUCHER_SUCCESS,
  UPDATE_VOUCHER_FAIL,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  USER_ALERT_CLOSE,
  ACCEPT_TERMS_SUCCESS,
  ACCEPT_TERMS_FAIL,
  PASSWORD_RECOVERY_SUCCESS,
  PASSWORD_RECOVERY_FAIL,
  PASSWORD_RECOVERY_INPUT_CODE_SUCCESS,
  PASSWORD_RECOVERY_INPUT_CODE_FAIL,
  PASSWORD_RECOVERY_NEW_PASSWORD_SUCCESS,
  PASSWORD_RECOVERY_NEW_PASSWORD_FAIL,
  PASSWORD_MODIFY_SUCCESS,
  PASSWORD_MODIFY_FAIL,
  POST_SHOP_SUCCESS,
  POST_SHOP_FAIL,
  DELETE_SHOP_SUCCESS,
  DELETE_SHOP_FAIL,
  DELETE_GIFT_TYPE_LISTADO_SUCCESS,
    DELETE_GIFT_TYPE_LISTADO_FAIL,
    POST_GIFT_TYPE_SUCCESS,
    POST_GIFT_TYPE_FAIL,
    POST_GIFT_CARD_SUCCESS,
    DELETE_GIFT_CARD_LISTADO_SUCCESS,
    DELETE_GIFT_CARD_LISTADO_FAIL,
    POST_GIFT_CARD_FAIL,
    POST_SALES_SUCCESS,
    POST_SALES_FAIL
  } from "../constants";
import { IAction, Route, UserAlert } from "../interfaces";
  
  export interface NavigationState {
    route: Route;
    userAlert: UserAlert;

  }

  const initialState: NavigationState= {
    route:{
      title: "Inicio"
    },
    userAlert: {
      open: false,
      error: false,
      message: "",
      color: "success",
      duration: 3000
    }
  };
  
  export default function navigationReducer(
    state = initialState,
    action: IAction
  ): NavigationState {

    switch (action.type) {
      case UPDATE_TITLE:
       return {
          ...state,
          route: action.data

        };
      case LOGIN_USER_SUCCESS:

        return {
          ...state,
          userAlert: {
            open: true,
            error: false,
            message: "Bienvenido/a " + action.data.name,
            color: "success",
            duration: 3000
          }
        };
      case LOGIN_USER_FAIL:

        return {
          ...state,
          userAlert: {
            open: true,
            error: true,
            message: "Email y/o contraseña incorrecta",
            color: "error",
            duration: 3000
          }
        };
      case USER_ALERT_CLOSE:
        return {
          ...state,
          userAlert: initialState.userAlert
        };
      case UPDATE_VOUCHER_SUCCESS:
      case ACCEPT_TERMS_SUCCESS:
      case PASSWORD_RECOVERY_SUCCESS:
      case PASSWORD_RECOVERY_INPUT_CODE_SUCCESS:
      case PASSWORD_RECOVERY_NEW_PASSWORD_SUCCESS:
      case PASSWORD_MODIFY_SUCCESS:
      case POST_SHOP_SUCCESS:
      case DELETE_SHOP_SUCCESS:
        case POST_GIFT_TYPE_SUCCESS:
      case DELETE_GIFT_TYPE_LISTADO_SUCCESS:
      case POST_GIFT_CARD_SUCCESS:
      case DELETE_GIFT_CARD_LISTADO_SUCCESS:
      case POST_SALES_SUCCESS:
        return {
          ...state,
          userAlert: {
            open: true,
            error: false,
            message: action.data.message || "Operación realizada con éxito",
            color: "success",
            duration: 3000
          }
        };
      case UPDATE_VOUCHER_FAIL:
      case FETCHING_VOUCHER_FAIL:
      case ACCEPT_TERMS_FAIL:
      case PASSWORD_RECOVERY_FAIL:
      case PASSWORD_RECOVERY_INPUT_CODE_FAIL:
      case PASSWORD_RECOVERY_NEW_PASSWORD_FAIL:
      case PASSWORD_MODIFY_FAIL:
      case POST_SHOP_FAIL:
      case DELETE_SHOP_FAIL:
      case DELETE_GIFT_TYPE_LISTADO_FAIL:
      case POST_GIFT_TYPE_FAIL:
      case DELETE_GIFT_CARD_LISTADO_FAIL:
      case POST_GIFT_CARD_FAIL:
      case POST_SALES_FAIL:
        return {
          ...state,
          userAlert: {
            open: true,
            error: true,
            message: (action.data?action.data.message || "Ha ocurrido un error":"Ha ocurrido un error"),
            color: "error",
            duration: 3000
          }
        };
      
      
      default:
        return state;
    }
  }
  