import React from "react";
import ColorPicker from "material-ui-rc-color-picker";
import "material-ui-rc-color-picker/assets/index.css";
import { makeStyles } from "@material-ui/styles";
import Input from "./Input";

interface Props {
  name: string;
  label: string;
  inputRef: any;
  errors: any;
  defaultValue?: string;
  onChange: (color: string) => void;
}

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative"
  },
  input: {
    //   paddingLeft: "40px"
  },
  disable: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    opacity: 0
  },
  picker: {
    position: "absolute",
    top: "33px",
    left: "88px",
    cursor: "pointer",
    zIndex: 2,
    backgroundColor: "white"
  }
}));

export default function InputColorPicker(props: Props) {
  const { name, label, inputRef, errors, onChange, defaultValue } = props;

  const classes = useStyles();

  const onChangePicker = (color: any) => {
    onChange(color.color);
  };

  return (
    <div className={classes.container}>
      <div className={classes.disable} />
      <ColorPicker
        className={classes.picker}
        name={name}
        enableAlpha={false}
        onChange={onChangePicker}
        mode="RGB"
        color={defaultValue}
        inputRef={inputRef}
      />

      <Input
        name={name}
        label={label}
        inputRef={inputRef}
        error={errors[name] ? true : false}
        helperText={errors[name] && errors[name].message}
        fullWidth={true}
        tabIndex={-1}
      />
    </div>
  );
}
