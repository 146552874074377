import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import LoginForm from "../../components/Forms/LoginForm";

import { passwordRecovery } from "../../redux/actions/userRecoveryPassword.action";
import { connect } from "react-redux";
import { PasswordRecoveryUserState } from "../../redux/reducers/userRecoveryPassword.reducer";


interface PasswordRecoveryData {
    email: string;
};

interface Props {
    passwordRecovery: (email: string) => void;
    userData: PasswordRecoveryUserState;
}

function InputEmail(props: Props) {
    
    const { passwordRecovery, userData } = props;


    return (
        <LoginForm onSubmitForm={(data: PasswordRecoveryData) => passwordRecovery(data.email)} isFetching={userData.isFetching} passwordField={false} buttonText="Recuperar Contraseña">
            <Grid item xs>
                <Link to={"/login"} style={{ textDecoration: "none", display: "flex-start" }}>
                    Tengo usuario y contraseña
                </Link>
            </Grid>
        </LoginForm>
    );
}

const mapStateToProps = (state: any) => {
    return {
        userData: state.userDataPasswordRecovery
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        passwordRecovery: (email: string) => {
            return dispatch(passwordRecovery(email));
        }
    };
};



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InputEmail);
