import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Route,Switch,Redirect} from "react-router-dom";


import { connect } from "react-redux";
import { UserState } from "../../redux/reducers/user.reducer";
import VoucherChange from "../VoucherChangeContainer";
import AccountContainer from "../AccountContainer";
import Sales from "../SalesContainer/SalesContainer";

import Main from "./MainContainer";
import Logout from "../LoginContainer/LogoutContainer";
import TermsContainer from "../TermsContainer";
import ThankyoupageOK from "../Thankyoupage/ThankyoupageOK";
import ThankyoupageError from "../Thankyoupage/ThankyoupageError";
import Page404 from "../Page404";
interface MenuScreenProps {
    userData: UserState;
}
interface Props {
    Component: React.FC<RouteComponentProps>;
    path: string;
	exact?: boolean;
    // any other props that come into the component
}
function ShopRouteContainer(props: MenuScreenProps) {
    
    const stateUser = props.userData;
    let mercadoPago=false;
    if(stateUser.user.rol==="shop"){
         mercadoPago=(stateUser.user.mercadoPago?true:false);
    }else{
         mercadoPago=true;
    }

    const PrivateRoute = ({ Component, ...rest }: Props) => (
        <Route
            {...rest} render={props => (
            !stateUser.isLogged ? (
                    
                <Redirect to={{
                    pathname: "/login",
                    state: { from: props.location }
                }} />
            ) : (
                        
                stateUser.user.termsAccepted && !stateUser.user.firstLogin && mercadoPago ? (
                    <Component {...props} />
                ) : (
                    !stateUser.user.termsAccepted  ? (
                        <Redirect to={{
                            pathname: "/terms-conditions",
                            state: { from: props.location }
                        }} />
                    ) : (
                        <Redirect to={{
                            pathname: "/account",
                            state: { from: props.location }
                        }} />
                    )
                )
            )
        )} />
    ); 
    const AccountRoute = ({ Component, ...rest }: Props) => (
        <Route
            {...rest} render={props => (
            !stateUser.isLogged ? (
                    
                <Redirect to={{
                    pathname: "/login",
                    state: { from: props.location }
                }} />
            ) : (
                        
                stateUser.user.termsAccepted ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{
                        pathname: "/terms-conditions",
                        state: { from: props.location }
                    }} />
                )
            )
        )} />
    ); 
    const LogOutRoute = ({ ...rest }: {
        path: string;
        exact?: boolean;}) => (
        <Route
            {...rest} render={props => (
                !stateUser.isLogged ? (

                    <Redirect to={{
                        pathname: "/login",
                        state: { from: props.location }
                    }} />
                ) : (

                        <Route component={Logout} path={"/logout"} exact={true} />
                            
                    )
            )} />
    ); 
    
  return (
    <>  
        
            <Main >
                <Switch>
                    
                    <PrivateRoute exact Component={Sales} path={"/sales"}  />
                    <PrivateRoute exact Component={VoucherChange} path={"/main"}  />
                    <PrivateRoute Component={VoucherChange} path={"/change"} exact />
                    <Route component={ThankyoupageOK} path={"/thankyoupage"} exact />
                    <Route component={ThankyoupageError} path={"/thankyoupage-error"} exact/>
                    <AccountRoute Component={AccountContainer} path={"/account"} exact />
                    <LogOutRoute path={"/logout"} exact />
                    <Route component={TermsContainer} path={"/terms-conditions"} exact />
                    <Route component={Page404}   />
                </Switch>
            </Main>
    </>
    
  );
}
const mapStateToProps = (state: any) => {
    return {
        userData: state.userData
    };
};
export default connect(
    mapStateToProps
)(ShopRouteContainer);
