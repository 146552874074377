import {
  FETCHING_CATEGORIES_LISTADO,
  FETCHING_CATEGORIES_LISTADO_INITIAL,
  FETCHING_CATEGORIES_LISTADO_SUCCESS,
  FETCHING_CATEGORIES_LISTADO_FAIL,
  LOGOUT_USER
} from "../constants";
import { IAction, ICategories, Pagination } from "../interfaces";

export interface CategoryFetchState {
  categories: ICategories[];
  pagination?: Pagination;
  isFetching: boolean;
  isLoaded: boolean;
  error: boolean;
}

const initialState: CategoryFetchState = {
  categories: [],
  isFetching: false,
  isLoaded: false,
  error: false
};

export default function categoryFetchReducer(
  state = initialState,
  action: IAction
): CategoryFetchState {
  switch (action.type) {
    case LOGOUT_USER:
      return initialState;
    case FETCHING_CATEGORIES_LISTADO_INITIAL:
      return initialState;
    case FETCHING_CATEGORIES_LISTADO:
      return {
        ...state,
        isFetching: true
      };
    case FETCHING_CATEGORIES_LISTADO_SUCCESS:
      return {
        ...state,
        categories: action.data.data,
        pagination: action.data.pagination,
        isFetching: false,
        isLoaded: true,
        error: false
      };
    case FETCHING_CATEGORIES_LISTADO_FAIL:
      return {
        ...state,
        categories: [],
        isFetching: false,
        isLoaded: true,
        error: true
      };
    default:
      return state;
  }
}
