import React from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  container: {
    background:
      "linear-gradient(30deg, rgba(255,90,25,1) 0%, rgba(255,30,115,1) 100%)",
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  image: {
    background: "transparent",
    width: '100%'
  }
}));

interface Props {
  width: string;
  height: string;
}
function BoomerIcon(props: Props) {
  const classes = useStyles();
  const { width, height } = props;

  return (
    <div className={classes.container} style={{ width, height }}>
      <img src="/images/LOGO_BOOMER_WHITE.png" className={classes.image} alt="" />
    </div>
  );
}

export default BoomerIcon;
