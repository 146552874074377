import React, { useEffect } from "react";
import {useHistory} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Copyright from "../../../components/Copyrigth";
import NewShopForm from "../../../components/Forms/NewShop/NewShopForm";
import { connect } from "react-redux";
import { updateTitle } from "../../../redux/actions/navigation.action";
import { postShop } from "../../../redux/actions/shops.action";
import { FormShop } from "../../../redux/interfaces";

const pageTitle = "Nuevo Comercio";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  layout: {
    width: "auto",

    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "70%",
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  title: {
    color: "#f65521",
    fontWeight: "bold"
  }
}));

interface Props {
  updateTitlePage: (title: string) => void;
  postShop: (data: FormShop,cb: any) => void;
}

function NewShopContainer(props: Props) {
  const { updateTitlePage, postShop } = props;
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    updateTitlePage(pageTitle);
  });

  const postFormShop = (data: FormShop) => {
    
    postShop(data,()=>{history.push("/shops");});
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography
            className={classes.title}
            component="h1"
            variant="h4"
            align="center"
          >
            Nuevo Comercio
          </Typography>
          <NewShopForm
            onSubmitForm={postFormShop}
            isFetching={false}
            buttonText={"Guardar"}
          />
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTitlePage: (title: string) => {
      dispatch(updateTitle(title));
    },
    postShop(data: FormShop,cb: any) {
      dispatch(postShop(data,cb));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(NewShopContainer);
