import {
    FETCHING_CATEGORIES,
    FETCHING_CATEGORIES_SUCCESS,
    FETCHING_CATEGORIES_FAIL,
    LOGOUT_USER
} from "../constants";
import { IAction, ICategories, Pagination } from "../interfaces";

export interface CategoriesState {
    categories: ICategories[];
    pagination?: Pagination;
    isFetching: boolean;
    isLoaded: boolean;
    error: boolean;
}

const initialState: CategoriesState = {
    categories: [],
    isFetching: false,
    isLoaded:false,
    error: false
};



export default function categoriesReducer(
    state = initialState,
    action: IAction
): CategoriesState {
    switch (action.type) {
        case LOGOUT_USER:
            return initialState;
        case FETCHING_CATEGORIES:

            return {
                ...state,
                isFetching: true
            };
        case FETCHING_CATEGORIES_SUCCESS:

            return {
                ...state,
                categories: action.data,
                isFetching: false,
                isLoaded:true,
                error: false
            };
        case FETCHING_CATEGORIES_FAIL:
            return {
                ...state,
                categories: [],
                isFetching: false,
                isLoaded: true,
                error: true
            };
        default:
            return state;
    }
}
