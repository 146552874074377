import React from "react";
import {Link} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import ListIcon from "@material-ui/icons/List";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
export default function ShopMenuItems() {
    return (
        <>
            <ListItem button component={Link} to="/sales">
                <ListItemIcon>
                    <ListIcon></ListIcon>
                </ListItemIcon>
                <ListItemText primary="Listado de ventas" />

            </ListItem>
            <ListItem button component={Link} to="/change">
                <ListItemIcon>
                    <CardGiftcardIcon></CardGiftcardIcon>
                </ListItemIcon>
                <ListItemText primary="Canjear Boomer" />
            
            </ListItem>
            <ListItem button component={Link} to="/account">
                <ListItemIcon>
                    <AccountCircleIcon></AccountCircleIcon>
                </ListItemIcon>
                <ListItemText primary="Cuenta" />
            
            </ListItem>
            <ListItem button component={Link} to="/logout">
                <ListItemIcon>
                    <ExitToAppIcon></ExitToAppIcon>
                </ListItemIcon>
                <ListItemText primary="Salir" />
            
            </ListItem>
            
        </>  
    );
}