import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAIL,
    LOGOUT_USER,
    ACCEPT_TERMS,
    ACCEPT_TERMS_SUCCESS,
    ACCEPT_TERMS_FAIL,
    INICIALIZE,
    PASSWORD_MODIFY,
    PASSWORD_MODIFY_SUCCESS,
    PASSWORD_MODIFY_FAIL
  } from "../constants";
  import { IAction, IUser } from "../interfaces";
  
  export interface UserState {
    user: IUser;
    isFetching: boolean;
    error: boolean;
    isLogged: boolean;
    isFetch: boolean;
  }
  
  const emptyUser: IUser = {
    name: "",
    surname: "",
    email: "",
    termsAccepted:false,
    firstLogin:true,
    rol:"shop",
    mercadoPago:false
  };
  
const initialState: UserState = {
    user: emptyUser,
    isFetching: false,
    error: false,
    isLogged: false,
    isFetch:false
  };
  
  export default function userReducer(
    state = initialState,
    action: IAction
  ): UserState {
    switch (action.type) {
      case INICIALIZE:
        return {
          ...state,
          isFetching: false,
          isLogged: false,
          error: false,
          isFetch: false
        };
      case LOGIN_USER:
        return {
          ...state,
          user: emptyUser,
          isFetching: true,
          isLogged: false
        };
      case LOGIN_USER_SUCCESS:
        console.log(action.data);
        return {
          ...state,
          user: action.data,
          isFetching: false,
          isLogged: true,
          error: false
        };
      case LOGIN_USER_FAIL:
        return {
          ...state,
          user: emptyUser,
          isFetching: false,
          isLogged: false,
          error: true
        };
      case LOGOUT_USER:
        return {
          ...state,
          user: emptyUser,
          isFetching: false,
          isLogged: false,
          error: false
        };
      case ACCEPT_TERMS:
        return {
          ...state,
          isFetching: true
        };
      case ACCEPT_TERMS_SUCCESS:
        
        return {
          ...state,
          user: {
            ...state.user,
            termsAccepted: action.data.termsAccepted
          },
          isFetching: false
        };
      case ACCEPT_TERMS_FAIL:
        return {
          ...state,
          user: {
            ...state.user,
            termsAccepted: false
          },
          isFetching: false,
          error: true
        };
        case PASSWORD_MODIFY:
          return {
            ...state,
            isFetching: true,
            error: false,
            isFetch: false
          };
        case PASSWORD_MODIFY_SUCCESS:
          return {
            ...state,
            user: {
              ...state.user,
              firstLogin: false
            },
            isFetching: false,
            error: false,
            isFetch: true
          };
        case PASSWORD_MODIFY_FAIL:
          return {
            ...state,
            isFetching: false,
            error: true,
            isFetch:false
          };
      default:
        return state;
    }
  }
  