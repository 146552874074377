import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT_USER,
  ACCEPT_TERMS,
  ACCEPT_TERMS_SUCCESS,
  ACCEPT_TERMS_FAIL,
  PASSWORD_MODIFY,
  PASSWORD_MODIFY_SUCCESS,
  PASSWORD_MODIFY_FAIL
} from "../constants";
import { IUser } from "../interfaces";
import { all, updateTermsService,passwordUserModify} from "../../services/user.service";

const initialLoginState = () => {
  return { type: LOGIN_USER };
};

const loginSuccess = (data: IUser) => {

  return { type: LOGIN_USER_SUCCESS, data };
};

const loginFail = (error: string) => {
  return { type: LOGIN_USER_FAIL, error };
};

const logOut = () => {
  return { type: LOGOUT_USER };
};


/**
 * POST - Login de usuario
 */
export const loginUser = (email: string,password: string) => {
  return (dispatch: any) => {
    
    dispatch(initialLoginState());
    
     all(email,password)
    .then((user)=>{
      if(!user.data.token){
        dispatch({ type: LOGIN_USER_FAIL, data:{} });
      }else{
        dispatch(loginSuccess(user.data));
      }
      
    }).catch(error => {

      dispatch(loginFail(error));
    });
    
  };
};

export const logoutUser = () => {
  return (dispatch: any) => {
    
    dispatch(logOut());
  };
};
/**
 * PUT - Aceptar terminos y condiciones
 */
export const updateTerms = () => {
  return (dispatch: any) => {

    dispatch({ type: ACCEPT_TERMS });

    updateTermsService()
    .then((user) => {
      if (user.data.error) {
        dispatch({ type: ACCEPT_TERMS_FAIL, data: {} });
      } else {
        dispatch({ type: ACCEPT_TERMS_SUCCESS, data: user.data });
      }

    }).catch(error => {

      dispatch({ type: ACCEPT_TERMS_FAIL, error });
    });
    
    
  };
};
/**
 * POST - Password Modify
 */
export const passwordModify = (password: string) => {
  return (dispatch: any) => {

    dispatch({ type: PASSWORD_MODIFY });

    passwordUserModify(password)
      .then((user) => {
        if (user.data.error) {
          dispatch({ type: PASSWORD_MODIFY_FAIL, data: user.data });
        } else {
          dispatch({ type: PASSWORD_MODIFY_SUCCESS, data: user.data });
        }

      }).catch(error => {

        dispatch({ type: PASSWORD_MODIFY_FAIL, error });
      });

  };
};



