import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";

import { VoucherState } from "../redux/reducers/voucher.reducer";
import getConfig from "../config";


const config = getConfig();

const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.up("sm")]: {
            
            margin: "0 auto"
        }
        
    },
    giftCard: {
        [theme.breakpoints.up("sm")]: {
            width: "90%",
            margin: "0 auto",
            height: 250
        },
        width: "100%",
        height: 200,
        backgroundImage: "url(" + config.URL + "/images/BOOMER_BACKGROUND.png)",
        display: "flex",
        flexDirection: "column",

        padding: "15px"

    },
    logo: {
        [theme.breakpoints.up("sm")]: {
            width: 130,
            height: 100
        },
        width: 100,
        height: 70
    },
    price: {
        [theme.breakpoints.up("sm")]: {
            fontSize: 40
        },
        fontSize: 30,
        color: "white",
        fontWeight: "bold"
    },
    code: {
        [theme.breakpoints.up("sm")]: {
            fontSize: 35
        },
        fontSize: 15,
        color: "white",
        fontWeight: "bold",
        marginBottom: 10,
        marginLeft: 10,
        letterSpacing: 4
    },
    receiver: {
        [theme.breakpoints.up("sm")]: {
            fontSize: 20
        },
        fontSize: 15,
        color: "white",
        fontWeight: "bold",
        marginBottom: 10,
        marginLeft: 10
    }
}));

interface Props {
    voucherData: VoucherState;
}

export default function BoomerCard(props: Props) {
    const classes = useStyles();

    const voucher = props.voucherData.voucher;
    const receiver = voucher.receiver;
    const voucherCode = (code: string) => {
        const element = code.match(/.{1,4}/g);
        if (element) {
            return element.join(" ") || "0000-0000-0000-0000";
        }
        return "0000-0000-0000-0000";

    };
    return (
       
        <Grid container >
            
            <Card className={classes.giftCard}>
                <Grid item container xs={12}>
                    <Grid item container xs={8}>
                        <CardMedia
                            component="img"
                            className={classes.logo}
                            image={config.URL + "/images/LOGO_BOOMER_WHITE.png"}
                        />
                    </Grid>
                    <Grid item container xs={4}>
                        <Typography className={classes.price} >
                            $ {voucher.amount.toString()}
                        </Typography>
                    </Grid>
                </Grid>

                <Typography className={classes.code}>
                    {voucherCode(voucher.code)}
                </Typography>
                <Typography className={classes.receiver}>
                    {receiver.name} {receiver.surname}
                </Typography>

            </Card>
        </Grid>
                
    );
}

