import {
    FETCHING_VOUCHER,
    FETCHING_VOUCHER_SUCCESS,
    FETCHING_VOUCHER_FAIL,
    UPDATE_VOUCHER,
    UPDATE_VOUCHER_SUCCESS,
    UPDATE_VOUCHER_FAIL,
    HANDLE_STEP_VOUCHER_BACK,
    LOGOUT_USER
  } from "../constants";
  import { IAction, IVoucher } from "../interfaces";
  
  export interface VoucherState {
    voucher: IVoucher;
    activeStep: number;
    isFetching: boolean;
    error: boolean;
    isFetched: boolean;
  }
  
  const emptyVoucher: IVoucher = {
    _id: "",
    code: "",
    status: "create",
    expirationDateTo: new Date(),
    amount: 0,
    receiver: {
      email:"",
      name:"",
      surname:"",
      phone:""
    }
  };
  
const initialState: VoucherState = {
    voucher: emptyVoucher,
    activeStep:0,
    isFetching: false,
    error: false,
    isFetched:false
  };
  
  export default function voucherReducer(
    state = initialState,
    action: IAction
  ): VoucherState {
    switch (action.type) {
      case LOGOUT_USER:
            return initialState;
      case FETCHING_VOUCHER:
        return {
          ...state,
          voucher: emptyVoucher,
          activeStep:0,
          isFetching: true,
          isFetched: false
        };
      case FETCHING_VOUCHER_SUCCESS:
        return {
          ...state,
          voucher: action.data,
          activeStep:1,
          isFetching: false,
          isFetched: true,
          error: false
        };
      case FETCHING_VOUCHER_FAIL:
        return {
          ...state,
          voucher: emptyVoucher,
          isFetching: false,
          isFetched: false,
          error: true
        };
      case UPDATE_VOUCHER:
        return {
          ...state,
          voucher: emptyVoucher,
          isFetching: false,
          isFetched: false,
          error: false
        };
      
      case UPDATE_VOUCHER_SUCCESS:
        return {
          ...state,
          voucher: {
            ...state.voucher,
            status: action.data.status
          },
          activeStep: 0,
          isFetching: false,
          isFetched: true,
          error: false
        };
      case UPDATE_VOUCHER_FAIL:
        return {
          ...state,
          activeStep: 1,
          isFetching: false,
          error: true
        };
      case HANDLE_STEP_VOUCHER_BACK:

        return {
          ...state,
          activeStep: 0,
          isFetching: false,
          isFetched: false,
          error: false
        };
      default:
        return state;
    }
  }
  