import React, { useEffect } from "react";
import Listado from "../../../components/Listado/index";
import { ListadoColumnas,Filter } from "../../../components/Listado/interfaces";

import { connect } from "react-redux";
import { GiftTypeFetchState } from "../../../redux/reducers/giftTypeFetch.reducer";
import { fetch,deleted } from "../../../redux/actions/giftType.action";
import { RequestOptions } from "../../../redux/interfaces";
import { updateTitle } from "../../../redux/actions/navigation.action";

const pageTitle = "Listado Tipos";




interface Props {
    giftTypes: GiftTypeFetchState;
    fetch: (options?: RequestOptions) => void;
    updateTitlePage: (title: string) => void;
    deleted: (id: string,cb: any) => void;
    
}

function GiftTypeContainer(props: Props) {
    const { giftTypes, fetch, updateTitlePage,deleted } = props;

    const deleteElement = (id: string)=>{
        
        deleted(id,fetch);
    };
    
    const filters: Filter[] = [
        { name: "name", label: "Nombre", type: "text" },
        { name: "inHome", label: "En Home", type: "select", options: [{ value: "true", label: "SI" },{ value: "false", label: "NO" }]},
    ];
    const columns: ListadoColumnas = {
        actions:{
            add:{ name: "add", label: "Nuevo", minWidth: 10,link:"/gift-type/" },
            edit:{ name: "edit", label: "Editar", minWidth: 10,link:"/gift-type/" },
            delete:{ name: "delete", label: "Eliminar", minWidth: 10,click: deleteElement },
        },
        columns:[
            { name: "name", label: "Nombre", minWidth: 90 },    
            { name: "inHome", label: "En Home", minWidth: 90 },
            { name: "icon", label: "Icono", minWidth: 90 },
    
        ]
    };
        
    useEffect(() => {
        !giftTypes.isLoaded && !giftTypes.isFetching && fetch();
        updateTitlePage(pageTitle); 
    });
    
    return (
        <>
            {giftTypes.isLoaded &&
                <Listado listadoColumnas={columns} rows={giftTypes.giftTypes} pagination={giftTypes.pagination} filters={filters} fetch={fetch}/>
            }
        </>
        
    );
}

const mapStateToProps = (state: any) => {
    console.log();
    return {
        giftTypes: state.giftTypeFetchData
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateTitlePage: (title: string) => {

            dispatch(updateTitle(title));
        },
        fetch: (options?: RequestOptions) => {
            dispatch(fetch(options));
        },
        deleted:(id: string,cb: any)=>{
            dispatch(deleted(id,cb));
        }
    };
};



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GiftTypeContainer);
