import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: "20px",
    paddingBottom: "12px"
  },
  imageContainer: {
    margin: "10px",
    minWidth: "300px"
  },
  title: {
    fontSize: "12px",
    fontWeight: "bold",
    marginBottom: "5px"
  }
}));

interface Props {
  aspectRatio: number;
  onCroppedUrlChanged: (cropperdURL: any) => void;
  file: any;
  index: number;
  croppedURLs: any[];
}

export default function ImageCrop(props: Props) {
  const { aspectRatio, onCroppedUrlChanged, file, index, croppedURLs } = props;
  const classes = useStyles();
  const [src, setSrc] = useState<any>(null);
  const [imageRef, setImageRef] = useState();
  const [croppedImageUrl, setCroppedImageUrl] = useState();
  const [firstTime, setFirstTime] = useState(true);
  const ratio = aspectRatio || 10 / 6;

  const [crop, setCrop] = useState<ReactCrop.Crop>({
    unit: "%",
    width: 100,
    height: 100,
    aspect: ratio
  });

  useEffect(() => {
    const hasToCrop =
      firstTime &&
      (index === 0 || (index > 0 && croppedURLs && croppedURLs[index - 1]));
    if (hasToCrop && src && imageRef) {
      const inverseRatio = 1 / ratio;
      const iWidth = imageRef.width;
      const iHeight = imageRef.height;

      const crop: ReactCrop.Crop = {
        unit: "px",
        aspect: ratio,
        x: 0,
        y: 0
      };

      if (iHeight * ratio > iWidth) {
        // fit to width
        crop.width = iWidth;
        crop.height = Math.trunc(iWidth * inverseRatio);
      } else {
        // fit to height
        crop.height = iHeight;
        crop.width = Math.trunc(iHeight * ratio);
      }

      setCrop(crop);
      onCropComplete(crop);
      setFirstTime(false);
    }
    // eslint-disable-next-line
  }, [src, imageRef, croppedURLs]);
  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setSrc(reader.result);
      });
      reader.readAsDataURL(file);
    }
  }, [file]);

  const onImageLoaded = (image: any) => {
    setImageRef(image);
  };

  const onCropComplete = (crop: any) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop: any, percentCrop: any) => {
    setCrop(crop);
  };

  const makeClientCrop = async (crop: any) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        "newFile.jpeg"
      );
      setCroppedImageUrl(croppedImageUrl);
      onCroppedUrlChanged(croppedImageUrl);
    }
  };

  const getCroppedImg = (image: any, crop: any, fileName: any) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);
    const ctx = canvas.getContext("2d");

    ctx &&
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob: any) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(blob.fileUrl);
        blob.fileUrl = window.URL.createObjectURL(blob);
        resolve(blob.fileUrl);
      }, "image/jpeg");
    });
  };

  return (
    <div className={classes.container}>
      {src && (
        <div className={classes.imageContainer}>
          <div className={classes.title}>Recortar Imagen</div>
          <div>
            <ReactCrop
              src={src}
              crop={crop}
              ruleOfThirds
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={onCropChange}
              maxHeight={250}
              maxWidth={300}
              style={{ maxWidth: "300px" }}
            />
          </div>
        </div>
      )}
      {croppedImageUrl && (
        <div className={classes.imageContainer}>
          <div className={classes.title}>Previsualización</div>
          <img
            alt="Crop"
            style={{
              width: Math.trunc(250 * aspectRatio) + "px",
              maxWidth: Math.trunc(250 * aspectRatio) + "px",
              height: "250px",
              maxHeight: "250px"
            }}
            src={croppedImageUrl}
          />
        </div>
      )}
    </div>
  );
}
