import  { useEffect } from "react";
import { connect } from "react-redux";

import { fetchCategories } from "../redux/actions/categories.action";
import { fetchGiftType } from "../redux/actions/giftType.action";
import { UserState } from "../redux/reducers/user.reducer";
import { CategoriesState } from "../redux/reducers/categories.reducer";
import { GiftTypeState } from "../redux/reducers/giftType.reducer";

interface Props {
  userData: UserState;
  categoriesData: CategoriesState;
  giftTypeData: GiftTypeState;
  fetchCategories: () => void;
  fetchGiftType: () => void;
}

function LoadMasterData(props: Props) {
  const { userData,categoriesData,giftTypeData,fetchCategories,fetchGiftType } = props;
 
  useEffect(
    () => {
      !categoriesData.isLoaded && !categoriesData.isFetching && fetchCategories();
      !giftTypeData.isLoaded && !giftTypeData.isFetching && fetchGiftType();
      
    }, [userData,categoriesData,giftTypeData,fetchCategories,fetchGiftType]);
  

  return null;
}


const mapStateToProps = (state: any) => {
  return {
    userData: state.userData,
    categoriesData:state.categoriesData,
    giftTypeData:state.giftTypeData
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchCategories: () => {

      dispatch(fetchCategories());
    },
    fetchGiftType: () => {

      dispatch(fetchGiftType());
    }
  };
};




export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadMasterData);
