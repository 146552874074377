import React, { useState,useEffect } from "react";
import Listado from "../../components/Listado/index";
import { ListadoColumnas,Filter } from "../../components/Listado/interfaces";

import { connect } from "react-redux";
import { UserState } from "../../redux/reducers/user.reducer";
import { fetchSales } from "../../redux/actions/sales.action";
import { updateTitle } from "../../redux/actions/navigation.action";
import { paymentStatusOptions,paymentStatusFilterAdminOptions, voucherStatusFilterOptions,voucherStatusFilterAdminOptions,shippingStatusFilterAdminOptions,paymentMethodFilterOptions} from "../../constants/status";

const pageTitle = "Listado Ventas";

const columnsShop: ListadoColumnas = {
   
    columns:[
        { name: "saleDate", label: "Fecha Vta", minWidth: 90 },
        { name: "receiver_name", label: "Nombre", minWidth: 170 },
        { name: "receiver_surname", label: "Apellido", minWidth: 170 },
        { name: "giftCard_amount", label: "Monto", minWidth: 120, format: (number) => "$ " + parseFloat(number.toString()).toFixed(2) },
        { name: "payment_status_label", label: "Estado del pago", minWidth: 120 },
        { name: "voucher_status_label", label: "Estado del boomer", minWidth: 120 },
        { name: "voucher_code", label: "Boomer", minWidth: 150 },
        { name: "voucher_expirationDateTo", label: "Fecha Vencimiento", minWidth: 90 },
        { name: "voucher_changeDate", label: "Fecha Canje", minWidth: 90 },
        { name: "payment_method_label", label: "Metodo Pago", minWidth: 90 }
    ]
};
const columnsAdmin: ListadoColumnas = {
   
    columns:[
        { name: "saleDate", label: "Fecha Vta", minWidth: 90 },
        { name: "receiver_name", label: "Nombre", minWidth: 170 },
        { name: "receiver_surname", label: "Apellido", minWidth: 170 },
        { name: "giftCard_amount", label: "Monto", minWidth: 120, format: (number) => "$ " + parseFloat(number.toString()).toFixed(2) },
        { name: "payment_status_label", label: "Estado del pago", minWidth: 120 },
        { name: "payment_method_label", label: "Metodo Pago", minWidth: 90 },
        { name: "voucher_status_label", label: "Estado del boomer", minWidth: 120 },
        { name: "voucher_code", label: "Boomer", minWidth: 150 },
        { name: "voucher_expirationDateTo", label: "Fecha Vencimiento", minWidth: 90 },
        { name: "voucher_changeDate", label: "Fecha Canje", minWidth: 90 },
        { name: "shop_name", label: "Comercio", minWidth: 90 },
        { name: "shippingDate_date", label: "F. Envio", minWidth: 90 },
        { name: "shippingDate_status", label: "Estado Envío", minWidth: 90 },
        { name: "shippingDate_status_hour", label: "Hora de envio", minWidth: 90 }

        
    ]
};
const filtersShop: Filter[] = [
    { name: "code", label: "Boomer", type: "text" },
    /*{ name: "amount_from", label: "Monto desde", type: "text" },
    { name: "amount_to", label: "Monto Hasta", type: "text" },*/
    { name: "name", label: "Nombre", type: "text" },
    { name: "surname", label: "Apellido", type: "text" },
    { name: "payment_status", label: "Estado del pago", type: "select", options: paymentStatusOptions},
    { name: "voucher_status", label: "Estado del boomer", type: "select", options: voucherStatusFilterOptions },
    { name: "payment_method", label: "Metodo Pago", type: "select", options: paymentMethodFilterOptions },
];
const filtersAdmin: Filter[] = [
    { name: "code", label: "Boomer", type: "text" },
    { name: "amount_from", label: "Monto desde", type: "text" },
    { name: "amount_to", label: "Monto Hasta", type: "text" },
    { name: "name", label: "Nombre", type: "text" },
    { name: "surname", label: "Apellido", type: "text" },
    { name: "payment_status", label: "Estado del pago", type: "select", options: paymentStatusFilterAdminOptions},
    { name: "voucher_status", label: "Estado del boomer", type: "select", options: voucherStatusFilterAdminOptions },
    { name: "shippingDate_status", label: "Estado del envio", type: "select", options: shippingStatusFilterAdminOptions },
    { name: "payment_method", label: "Metodo Pago", type: "select", options: paymentMethodFilterOptions },
    { name: "shop", label: "Comercio", type: "text" }
];
let columns = columnsShop;
let filters = filtersShop;

interface SalesProps {
    updateTitlePage: (title: string) => void;
    userData: UserState;
    
}

function SalesContainer(props: SalesProps) {
    const {  updateTitlePage, userData } = props;
    const [isLoaded,setLoad] = useState(false);
    const [isFetching,setFetching] = useState(false);
    const [sales,setSales] = useState([{}]);
    const [pagination,setPagination] = useState({});
    
    if(["admin"].indexOf(userData.user.rol)!==-1){
        columns = columnsAdmin;
        filters=filtersAdmin;
    }
    
    useEffect(() => {
        //!sales.isLoaded && !sales.isFetching && fetchSales();
        !isLoaded && 
        !isFetching &&
        fetchSales(setFetching)
        .then(data=>{

            setSales(data.data.data);
            setPagination(data.data.pagination);
            setLoad(true);
        });
        //console.log("SalesResponse",salesResponse);
        updateTitlePage(pageTitle);
    },[isLoaded, isFetching, updateTitlePage]);
    const fetchSalesPage = (options?: any)=>{

        fetchSales(setFetching,options)
        .then(data=>{

            setSales(data.data.data);
            setPagination(data.data.pagination);
            setLoad(true);
        });
    };
    return (
        <>
            {isLoaded &&
                <Listado listadoColumnas={columns} rows={sales} pagination={pagination} filters={filters} fetch={fetchSalesPage}/>
            }
        </>
        
    );
}

const mapStateToProps = (state: any) => {

    return {
        userData:state.userData
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateTitlePage: (title: string) => {

            dispatch(updateTitle(title));
        }
    };
};



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SalesContainer);
