import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { FormSale } from "../../../redux/interfaces";
import ButtonWithLoading from "../../ButtonWithLoading";
import Input from "../../FormsElements/Input";
import { fetchAll } from "../../../services/giftCard.service";
import FormSelect from "../../FormsElements/FormSelect";

import TextField from "@material-ui/core/TextField";

import { Controller } from "react-hook-form";
import { phoneValidate } from "../../utils/formValidation";
const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

interface Props {
  onSubmitForm: (data: FormSale) => void;
  isFetching: boolean;
  buttonText?: string;
}
interface OptHours{
  label: string;
  value: string;
  default?: boolean;
}
function NewSaleForm(props: Props) {
  const classes = useStyles();
  const [boomer, setBoomer] = useState(true);
  const [giftType, setGiftType] = React.useState<OptHours[]>();
  const [shippingDate, setShippingDate] = React.useState(false);
  //const [hours, setHours] = React.useState<OptHours[]>([]);
  const hours: OptHours[]=[];
  for(let i=0;i<24;i++){
    //setHours([...hours,{value:(i+1).toString(),label:(i+1).toString()}]);
    hours.push({value:(i+1).toString(),label:(i+1).toString()});
  }
  const {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    setError,
    clearErrors,
    control
  } = useForm<FormSale>({ mode: "onChange" });
  
  setValue("shippingDate_hour","1");
  const { onSubmitForm, isFetching, buttonText } = props;
  useEffect(() => {
    if (!giftType) {
      fetchAll().then(response => {
        if (response && response.data) {
          const options = response.data.map((datos: any)=>{
            return {
              value:datos._id,
              label:datos.amount
            };
          });
          setGiftType(options);
          setValue("giftCardId", (options && options[0]?options[0].value:""));
        }
      });
    }
  }, [giftType,setValue]);
  useEffect(() => {
    if (boomer) {
      setValue("user_name", "Boomer");
      setValue("user_surname", "Boomer");
      setValue("user_email", "info@boomer.com.ar");
    }else{
      setValue("user_name", "");
      setValue("user_surname", "");
      setValue("user_email", "");
    }
    /* eslint-disable */
  }, [boomer]);

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(onSubmitForm)}
      noValidate
    >
     {giftType &&
        <FormSelect
          select={{
            name: "giftCardId",
            label: "Boomer",
            multiple: false,
            options: giftType,
            defaultOption: false,
            fullWidth: true,
            defaultValue:(giftType && giftType[0]?giftType[0].value:"")
          }}
          controller={{
            control: control,
            errors: errors,
            setValue: setValue,
            setError: setError,
            clearErrors: clearErrors,
            errorsRule: {
              type: "required",
              message: "Debe seleccionar al menos un tipo"
            }
          }}
        />
     }
     
     <FormControlLabel
        control={
          <Switch
            inputRef={register}
            name="boomer"
            checked={boomer}
            onChange={() => setBoomer(!boomer)}
          />
        }
        label="Boomer Emisor"
      />
      {!boomer && 
          <>
          <Input
            name="user_name"
            label="Nombre Emisor"
            type="text"
            inputRef={register({ required: true })}
            disabled={boomer}
            error={errors.user_name ? true : false}
            helperText={errors.user_name && errors.user_name.message}
            fullWidth={true}
          />
          <Input
            name="user_surname"
            label="Apellido emisor"
            type="text"
            inputRef={register({ required: true })}
            disabled={boomer}
            error={errors.user_surname ? true : false}
            helperText={errors.user_surname && errors.user_surname.message}
            fullWidth={true}
          />
          <Input
            name="user_email"
            label="Email emisor"
            type="text"
            inputRef={register({ required: true })}
            disabled={boomer}
            error={errors.user_email ? true : false}
            helperText={errors.user_email && errors.user_email.message}
            fullWidth={true}
          />
          </>
      }
      <Input
        name="receiver_name"
        label="Nombre destinatario"
        type="text"
        inputRef={register({ required: true })}
        error={errors.receiver_name ? true : false}
        helperText={errors.receiver_name && errors.receiver_name.message}
        fullWidth={true}
      />
      <Input
        name="receiver_surname"
        label="Apellido destinatario"
        type="text"
        inputRef={register({ required: true })}
        error={errors.receiver_surname ? true : false}
        helperText={errors.receiver_surname && errors.receiver_surname.message}
        fullWidth={true}
      />
      <Input
        name="receiver_phone"
        label="Teléfono Destinatario"
        type="text"
        inputRef={register({ required: true, pattern: phoneValidate()  })}
        error={errors.receiver_phone ? true : false}
        helperText={errors.receiver_phone && errors.receiver_phone.message}
        fullWidth={true}
      />
      <div>Agregar teléfono sin +549</div>
      <Input
        name="dedication"
        label="Dedicatoria"
        type="text"
        required={false}
        inputRef={register()}
        error={errors.dedication ? true : false}
        helperText={errors.dedication && errors.dedication.message}
        fullWidth={true}
      />
      <FormControlLabel
        control={
          <Switch
            inputRef={register}
            name="shippingDate"
            checked={shippingDate}
            onChange={() => setShippingDate(!shippingDate)}
          />
        }
        label="Fecha de envío"
      />
      {shippingDate &&
      <>
        <br/> 
        <Controller
          render={(props) =>
            <TextField
              {...props}
              type="date"
              label="Fecha de envío"
            />
          }
          name="shippingDate_date"
          control={control}
          
        >
        </Controller>
        <FormSelect
          select={{
            name: "shippingDate_hour",
            label: "Hora de envío",
            multiple: false,
            options: hours,
            defaultOption: false,
            fullWidth: true,
            defaultValue:"1"
          }}
          controller={{
            control: control,
            errors: errors,
            setValue: setValue,
            setError: setError,
            clearErrors: clearErrors,
            errorsRule: {
              type: "required",
              message: "Debe seleccionar al menos un horario"
            }
          }}
        />
      </>
      }
      {!shippingDate &&
      <>
        <div>El boomer será enviado inmediatamente</div>
      </>
      }
      <ButtonWithLoading
        color="primary"
        disabled={isFetching || !formState.isValid}
        loading={isFetching}
        text={buttonText || "Ingresar"}
      />
    </form>
  );
}

const mapStateToProps = (state: any) => {
  return null;
};

export default connect(mapStateToProps)(NewSaleForm);
