import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { useForm } from "react-hook-form";
import { passwordRecoveryValidCode } from "../../redux/actions/userRecoveryPassword.action";
import { connect } from "react-redux";
import { PasswordRecoveryUserState } from "../../redux/reducers/userRecoveryPassword.reducer";



const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
interface InputCodeData{

    code: string;
}

interface Props {
    passwordRecoveryValidCode: (email: string,code: string) => void;
    userData: PasswordRecoveryUserState;
}

function InputCode(props: Props) {
    const classes = useStyles();
    const { passwordRecoveryValidCode, userData } = props;
    const { register, handleSubmit, errors, formState } = useForm({ mode: "onChange" });

    const onSubmit = handleSubmit(({ code }) => {
        console.log(code);
        passwordRecoveryValidCode(userData.user.email,code);
      });
    
    
    return (
        <form className={classes.form} onSubmit={onSubmit} noValidate>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="code"
                label="Código"
                placeholder="Ingresá el código enviado por email"
                name="code"
                autoComplete="code"
                autoFocus
                inputRef={register({ required: true,minLength:1,maxLength:6 })}
                error={errors.code ? true : false}
                helperText={errors.code && errors.code.message}
            />
            <ButtonWithLoading
                color="primary"
                disabled={userData.isFetching || !formState.isValid}
                loading={userData.isFetching}
                text={"Validar Código"}
            />
            <Grid item xs>
                <Link to={"/login"} style={{ textDecoration: "none", display: "flex-start" }}>
                    Tengo usuario y contraseña
                </Link>
            </Grid>
        </form>
    );
}

const mapStateToProps = (state: any) => {
    return {
        userData: state.userDataPasswordRecovery
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        passwordRecoveryValidCode: (email: string,code: string) => {
            return dispatch(passwordRecoveryValidCode(email,code));
        }
    };
};



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InputCode);
