import {
  FETCHING_VOUCHER,
  FETCHING_VOUCHER_SUCCESS,
  FETCHING_VOUCHER_FAIL,
  UPDATE_VOUCHER_SUCCESS,
  UPDATE_VOUCHER_FAIL,
  HANDLE_STEP_VOUCHER_BACK
} from "../constants";

import * as voucherService from "../../services/voucher.service";




export const fetchVoucher = (code: string) => {
  return (dispatch: any) => {
    dispatch({ type: FETCHING_VOUCHER });
    voucherService
    .ByCode(code)
    .then(response=>{
      if (!response.data.error) {
        const voucher = response.data;
        dispatch({ type: FETCHING_VOUCHER_SUCCESS, data: voucher });
      } else {
        dispatch({ type: FETCHING_VOUCHER_FAIL, data: response.data });
      }
      
    })
    .catch(error=>{
      dispatch({ type: FETCHING_VOUCHER_FAIL, error });
    });
     
    
  };
};


export const changeVoucher = (id: string, code: string) => {
  return (dispatch: any) => {
    voucherService
      .change(id, code)
      .then(response => {
        if (!response.data.error) {
          dispatch({ type: UPDATE_VOUCHER_SUCCESS, data: response.data });
        } else {
          dispatch({ type: UPDATE_VOUCHER_FAIL, data: response.data });
        }
        
      })
      .catch(error => {

        dispatch({ type: UPDATE_VOUCHER_FAIL, error });
      });
  };
};
export const handleStepBack = () => {
  return (dispatch: any) => {
    
    dispatch({ type: HANDLE_STEP_VOUCHER_BACK, data: {} });
  };

};
