import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Copyright from "../../components/Copyrigth";

import { connect } from "react-redux";
import { updateTitle } from "../../redux/actions/navigation.action";
import { isMobile } from "../../utils/utils";
import BoomerButton from "../../components/BoomerButton";
import BoomerIcon from "../../components/BoomerIcon";

const pageTitle = "Descarga voucher";

const useStyles = makeStyles(theme => ({
  layout: {
    width: "auto",

    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  titleContainer: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "30px"
  },
  logo: {
    width: "20%"
  },
  title: {
    fontSize: "20px",
    marginTop: "30px",
    marginBottom: "50px"
  },
  boomerText: {
    color: "#ff3453",
    fontWeight: "bold"
  },
  boomerTitle: {
    color: "#ff3453",
    fontWeight: "bold",
    fontSize: "40px",
  },
  googlePlayLogo: {
    height: "80px",
    minHeight: "80px",
    cursor: "pointer"
  },
  appstoreLogo: {
    height: "54px",
    minHeight: "54px",
    cursor: "pointer"
  },
  storesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    flexFlow: "row wrap"
  },
  info: {
    marginTop: '50px',
    marginBottom: '30px',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  separator: {
    height: '1px',
    borderBottom: '1px solid #e4e4e4',
    marginBottom: '30px',
    marginTop: '30px'
  },
  note: {
    fontSize: '12px',
    color: 'gray',
    marginTop: '3px'
  },
  subTitle: {
    textAlign: 'center',
    fontWeight: 'bold'
  },
  subInfo: {
    textAlign: 'center'
  },
  link: {
    cursor: "pointer",
    color: "#ff3452"
  }
}));

interface Props {
  updateTitlePage: (title: string) => void;
}
function VoucherContainer(props: Props) {
  const params: any = new URLSearchParams(window.location.search);
  const id = params.get("id");
  const a = params.get("a");
  const voucherLink = `https://api.boomer.com.ar/voucher/${id}?a=${a}`

  const classes = useStyles();
  const { updateTitlePage } = props;
  React.useEffect(() => {
    updateTitlePage(pageTitle);
  });
  const isMobileDevice = isMobile();

  const goToPlaystore = () => {
    if (isMobileDevice) {
      window.location.href = "market://details?id=com.boomer.boomervoucher";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.boomer.boomervoucher";
    }
  };
  const goToAppstore = () => {
    window.location.href = "https://apps.apple.com/ar/app/boomer/id1535342701";
  };

  const downloadVoucher = () => {
    window.location.href = voucherLink;
  }

  const disableButton = !id || !a;

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <div className={classes.titleContainer}>
            <BoomerIcon height="150px" width="150px" />
            <div className={classes.info}>
              Recibiste un Boomer, podrás descargar el voucher desde el siguiente link:
            </div>
            <BoomerButton
              text="Descargar Voucher"
              onClick={downloadVoucher}
              disabled={disableButton}
            />
            <div className={classes.note}>* Si tenés Android, una vez que descargues el voucher lo podrás ver en Google Drive o encontrar en la carpeta de descargas.</div>
          </div>
          <div className={classes.separator}></div>
          <div className={classes.subTitle}>¿Tenés consultas, sugerencias o dudas?</div>
          <div className={classes.subInfo}>Mandanos un email a info@boomer.com.ar</div>
          <div className={classes.subInfo}>Para mas información ingresá <a className={classes.link} href="https://www.boomer.com.ar/support">aquí</a>.</div>
          <div className={classes.separator}></div>
          <div className={classes.subTitle}>¿Querés hacer o recibir un regalo?</div>
          <div className={classes.subInfo}>Descargá la App y está atento que si la tenés descargada, tenés chance de recibir Boomer promocionales.</div>
          <div className={classes.storesContainer}>
            <img
              className={classes.googlePlayLogo}
              src="/images/google-play-badge.png"
              onClick={goToPlaystore}
              alt=""
            />
            <img
              className={classes.appstoreLogo}
              src="/images/app-store-badge.svg"
              onClick={goToAppstore}
              alt=""
            />
          </div>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTitlePage: (title: string) => {
      dispatch(updateTitle(title));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(VoucherContainer);
