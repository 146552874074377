import React from "react";
import { createStyles, makeStyles, Theme  } from "@material-ui/core/styles";
import { Filter } from "./interfaces";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormSelect from "../FormsElements/FormSelect";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 150,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);
interface Props {
    filter: Filter;
    onHandleChange: (key: string,value: string|number) => void;
}

export default function ListadoFilters(props: Props) {
    const classes = useStyles();
    const { filter, onHandleChange } = props;
    switch (filter.type) {
        case "text":
            return (<FormControl className={classes.formControl}><TextField id={filter.name} label={filter.label} onChange={(e) => (filter.callback?filter.callback(e.target.value):onHandleChange(filter.name,e.target.value))} /></FormControl>);
        case "select":
            if (!filter.options) return null;
            return (
                <FormSelect select={{ name: filter.name, label: filter.label, options: filter.options }} onHandleChange={onHandleChange}/> 
                
                
            );
        default:
            return null;
    }
    
}